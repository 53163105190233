/**
 * File name: [Metrics.jsx]
 * Description: [Component that runs in the "StartupScreen.jsx" component to display analytics about "Startup", which is retrieved from the server ]
 *
 */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import projectImages from "../style/projectImages";
import axios from "axios";

const Metrics = ({ handleMetricsClose, id }) => {
  const token = JSON.parse(localStorage.getItem("userToken"));

  // Parameter number of page views
  const [views, setViews] = useState(0);
  // Number of distributions from the "ShareStartup.jsx" component
  const [shares, setShares] = useState(0);
  // The amount of time the component is in the "onLoad" state
  const [timeOnPage, setTimeOnPage] = useState(0);
  // Number of clicks on application links
  const [appClicks, setAppClicks] = useState(0);
  // Number of clicks on the linkedin account link
  const [linkedinClicked, setLinkedinClicked] = useState(0);
  // Shows the city from which the most visitors come
  const [topCity, setTopCity] = useState(null);

  // Function that downloads analytical information about "Startup" from the server
  useEffect(() => {
    const fetchStartupViews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/metrics/get-info/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setViews(response.data.views);
        setShares(response.data.shares);
        setTimeOnPage(response.data.timeOnPage);
        setAppClicks(response.data.appClicks);
        setLinkedinClicked(response.data.linkedinClicked);
        setTopCity(response.data.topCity);
      } catch (error) {
        console.error("Error fetching startup views:", error);
      }
    };
    fetchStartupViews();
  }, [id]);

  // Converting microseconds to minutes
  const timeInMinutes = (timeOnPage / 1000 / 60).toFixed(2);

  return (
    <>
      <div className="modal__wrapper">
        <div className="inner__modal">
          <div className="modal__box metrics">
            <div className="head__" style={{ flexDirection: "unset" }}>
              <p>
                <span>
                  <img src={projectImages.barChatBlack} alt="foot" />
                </span>{" "}
                Metrics
              </p>
              <Link onClick={handleMetricsClose}>
                <img src={projectImages.closeSmall} alt="closesmall" />
              </Link>
            </div>
            <div className="content__">
              <div className="metrics__wrapper">
                <div className="elem__metric">
                  <p>Simple Metrics</p>
                  <div className="row__grid">
                    <div className="row__">
                      <span>Views</span>
                      <p>{views}</p>
                    </div>
                    <div className="row__">
                      <span>Shares</span>
                      <p>{shares}</p>
                    </div>
                    <div className="row__">
                      <span>Rating</span>
                      <p>Top 95%</p>
                    </div>
                    <div className="row__">
                      <span>Time Spent on Page</span>
                      <p>{timeInMinutes} Minutes</p>
                    </div>
                  </div>
                </div>
                <div className="elem__metric">
                  <p></p>
                  <div className="row__grid no__space">
                    <div className="row__">
                      <span>App Clicks</span>
                      <p>{appClicks}</p>
                    </div>
                    <div className="row__">
                      <span>Website Click</span>
                      <p>119</p>
                    </div>
                    <div className="row__">
                      <span>LinkedIn Clicked</span>
                      <p>{linkedinClicked}</p>
                    </div>
                    <div className="row__">
                      <span>Content Copied</span>
                      <p>10%</p>
                    </div>
                  </div>
                </div>
                <div className="elem__metric">
                  <p>Advanced Metrics</p>
                  <div className="row__grid">
                    <div className="row__">
                      <span>Stickiness</span>
                      <p>67%</p>
                    </div>
                    <div className="row__">
                      <span>Data Room View</span>
                      <p>34%</p>
                    </div>
                    <div className="row__">
                      <span>Odds</span>
                      <p>Low</p>
                    </div>
                    <div className="row__">
                      <span>Top City</span>
                      <p>{topCity}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Metrics;
