import React from "react";
import Navbar from "../components/Navbar";

const NotFound = () => {
  return (
    <div style={{ zoom: "90%" }}>
      <Navbar />
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-6xl font-bold text-gray-800">404</h1>
        <p className="text-xl text-gray-600 mt-4">Page Not Found</p>
        <p className="text-md text-gray-500 mt-2">
          The page you're looking for does not seem to exist.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
