/**
 * File name: [Filters.jsx]
 * Description: [A group of elements that allows you to filter "Startups". Parameters are exchanged through props-sustem from the "Home.jsx" component]
 *
 */

import React, { useState } from "react";
import { ValueSlider } from "./ValueSlider";
import projectImages from "../style/projectImages";

// Props from Home.jsx
const Filters = ({
  // New keywords
  competitorListArray,
  industryListArray,
  tagListArray,
  selectedTags,
  handleTagToggle,
  selectedIndustries,
  handleIndustryToggle,
  selectedCompetitors,
  handleCompetitorsToggle,
  handleChange,
  rasingValue,
  selectedCheckbox,
  handleCheckboxChange,
  handleDataRoomToggle,
  handleProfitToggle,
}) => {
  const [showCompetitors, setShowCompetitors] = useState(false);
  const [showIndustries, setShowIndustries] = useState(false);
  const [showTags, setShowTags] = useState(false);

  // <<================ INDUSTRUES ================>> START
  // const industries = [...suggestedIndustrysList, ...industryListArray];

  const industryCheckboxes = industryListArray.map((industry) => (
    <div className="elem__checkbox" key={industry}>
      <label className="container__check">
        <input
          type="checkbox"
          checked={selectedIndustries.includes(industry)}
          onChange={(e) => {
            handleIndustryToggle(industry);
          }}
        />
        <span className="checkmark"></span>
        {industry}
      </label>
    </div>
  ));
  // <<=================================================>> END
  ////////////////////////////////////////////////////////
  // <<================ TAGS ================>> START
  // const tags = [...suggestedTagsList, ...tagListArray];

  const tagCheckboxes = tagListArray.map((tag) => (
    <div className="elem__checkbox" key={tag}>
      <label className="container__check">
        <input
          type="checkbox"
          checked={selectedTags.includes(tag)}
          onChange={(e) => {
            handleTagToggle(tag);
          }}
        />
        <span className="checkmark"></span>
        {tag}
      </label>
    </div>
  ));
  // <<=================================================>> END
  ////////////////////////////////////////////////////////
  // <<================ COMPETITORS ================>> START
  // const competitors = [...suggestedCompetitorList, ...competitorListArray];

  const competitorCheckboxes = competitorListArray.map((competitor) => (
    <div className="elem__checkbox" key={competitor}>
      <label className="container__check">
        <input
          type="checkbox"
          checked={selectedCompetitors.includes(competitor)}
          onChange={(e) => {
            handleCompetitorsToggle(competitor);
            // toggleShow(e, setShowCompetitors);
          }}
        />
        <span className="checkmark"></span>
        {competitor}
      </label>
    </div>
  ));
  // <<=================================================================>> END
  ////////////////////////////////////////////////////////////////////////
  // <<=====Opening / closing the filter component on small screens=====>> START
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
    const companiesSide = document.querySelector(".companies__side");
    const newLeftValue = isOpen ? "-290px" : "0px";
    companiesSide.style.left = newLeftValue;
  };
  // <<=================================================================>> END

  // Function for switching states
  const toggleShow = (e, stateSetter) => {
    e.preventDefault();
    stateSetter((prevState) => !prevState);
  };

  return (
    <div className="companies__side">
      {/* <a href="#" onClick={handleClick}>
        <span></span>
        <span></span>
        <span></span>
      </a> */}
      <form action="">
        <div className="main__checkboxes">
          <div className="elem__checkbox">
            <label className="container__check">
              {/* <input type="checkbox" /> */}
              <span
                style={{ backgroundColor: "grey" }}
                className="checkmark"
              ></span>
              Top Companies
            </label>
          </div>
          <div className="elem__checkbox">
            <label className="container__check">
              <input onChange={handleProfitToggle} type="checkbox" />
              <span className="checkmark"></span>
              Not for Profit
            </label>
          </div>
          <div className="elem__checkbox">
            <label className="container__check">
              <input onChange={handleDataRoomToggle} type="checkbox" />
              <span className="checkmark"></span>
              With Dataroom
            </label>
          </div>
        </div>
        <div className="wrapper__filter">
          <p>Fundraising Stage</p>
          <div className="content__filter">
            <div className="double__filter">
              <div className="elem__checkbox">
                <label className="container__check">
                  <input
                    type="checkbox"
                    value="Pre-seed"
                    checked={selectedCheckbox === "Pre-seed"}
                    onChange={handleCheckboxChange}
                  />
                  <span className="checkmark"></span>
                  Pre-seed
                </label>
              </div>

              <div className="elem__checkbox">
                <label className="container__check">
                  <input
                    type="checkbox"
                    value="Series A"
                    checked={selectedCheckbox === "Series A"}
                    onChange={handleCheckboxChange}
                  />
                  <span className="checkmark"></span>
                  Series A
                </label>
              </div>

              <div className="elem__checkbox">
                <label className="container__check">
                  <input
                    type="checkbox"
                    value="Seed"
                    checked={selectedCheckbox === "Seed"}
                    onChange={handleCheckboxChange}
                  />
                  <span className="checkmark"></span>
                  Seed
                </label>
              </div>

              <div className="elem__checkbox">
                <label className="container__check">
                  <input
                    type="checkbox"
                    value="Series B+"
                    checked={selectedCheckbox === "Series B+"}
                    onChange={handleCheckboxChange}
                  />
                  <span className="checkmark"></span>
                  <>Series B+</>
                </label>
              </div>

              <div className="elem__checkbox">
                <label className="container__check">
                  <input
                    type="checkbox"
                    value="Pre-Series A"
                    checked={selectedCheckbox === "Pre-Series A"}
                    onChange={handleCheckboxChange}
                  />
                  <span className="checkmark"></span>
                  <>Pre-Series A</>
                </label>
              </div>

              <div className="elem__checkbox">
                <label className="container__check">
                  <input
                    type="checkbox"
                    value="Series C+"
                    checked={selectedCheckbox === "Series C+"}
                    onChange={handleCheckboxChange}
                  />
                  <span className="checkmark"></span>
                  <>Series C+</>
                </label>
              </div>

              <div className="elem__checkbox">
                <label className="container__check">
                  <input
                    type="checkbox"
                    value="Bridge Round"
                    checked={selectedCheckbox === "Bridge Round"}
                    onChange={handleCheckboxChange}
                  />
                  <span className="checkmark"></span>
                  <>Bridge Round</>
                </label>
              </div>
            </div>
          </div>
        </div>
        <ValueSlider handleChange={handleChange} rasingValue={rasingValue} />
        {/* Industry */}
        <div className="wrapper__filter">
          <p>Industry</p>
          <div className="content__filter">
            {/* The first 6 tags are always shown*/}
            <div className="double__filter">
              {industryCheckboxes.slice(0, 6)}
            </div>

            {showIndustries ? (
              <div className="double__filter" style={{ marginTop: 13 }}>
                {industryCheckboxes.slice(6)}
              </div>
            ) : null}
            {/* BUTTON */}
            <button
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: 10,
                marginBottom: 5,
              }}
              onClick={(e) => toggleShow(e, setShowIndustries)}
            >
              <div style={{ fontWeight: 600 }}>
                {showIndustries ? "less industries" : "more industries"}{" "}
              </div>
              {showIndustries ? (
                <img
                  style={{ width: 30, height: 30 }}
                  src={projectImages.arrowUP}
                  alt="Plug_1"
                />
              ) : (
                <img
                  style={{ width: 21, height: 21, marginTop: 4, marginLeft: 4 }}
                  src={projectImages.arrowDown}
                  alt="Plug_2"
                />
              )}
            </button>
          </div>
        </div>
        {/* Tags */}
        <div className="wrapper__filter">
          <p>Tags</p>
          <div className="content__filter">
            {/* The first 6 tags are always shown*/}
            <div className="double__filter">{tagCheckboxes.slice(0, 6)}</div>
            {/* Residual tags are shown if showTags === true */}
            {showTags ? (
              <div className="double__filter" style={{ marginTop: 13 }}>
                {tagCheckboxes.slice(6)}
              </div>
            ) : null}
            {/* BUTTON */}
            <button
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: 10,
                marginBottom: 5,
              }}
              onClick={(e) => toggleShow(e, setShowTags)}
            >
              <div style={{ fontWeight: 600 }}>
                {showTags ? "less tags" : "more tags"}
              </div>
              {showTags ? (
                <img
                  style={{ width: 30, height: 30 }}
                  src={projectImages.arrowUP}
                  alt="Plug_3"
                />
              ) : (
                <img
                  style={{ width: 21, height: 21, marginTop: 4, marginLeft: 4 }}
                  src={projectImages.arrowDown}
                  alt="Plug_4"
                />
              )}
            </button>
          </div>
        </div>
        {/* Competitors */}
        <div className="wrapper__filter">
          <p>Competitors</p>
          <div className="content__filter">
            {/* The first 6 tags are always shown*/}
            <div className="double__filter">
              {competitorCheckboxes.slice(0, 6)}
            </div>

            {showCompetitors ? (
              <div className="double__filter" style={{ marginTop: 13 }}>
                {competitorCheckboxes.slice(6)}
              </div>
            ) : null}
            {/* BUTTON */}
            <button
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: 10,
                marginBottom: 5,
              }}
              onClick={(e) => toggleShow(e, setShowCompetitors)}
            >
              <div style={{ fontWeight: 600 }}>
                {showCompetitors ? "less competitors" : "more competitors"}
              </div>
              {showCompetitors ? (
                <img
                  style={{ width: 30, height: 30 }}
                  src={projectImages.arrowUP}
                  alt="Plug_5"
                />
              ) : (
                <img
                  style={{ width: 21, height: 21, marginTop: 4, marginLeft: 4 }}
                  src={projectImages.arrowDown}
                  alt="Plug_6"
                />
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Filters;
