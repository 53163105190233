import React from "react";
import "./ButtonChatDropDown.css";
import projectImages from "../style/projectImages";

const ButtonChatDropDown = ({ handleChatDroppDown }) => {
  return (
    <div
      style={{
        position: "absolute",
        width: 60,
        height: 60,
        zIndex: 99,
        bottom: 30,
        right: 30,
      }}
    >
      <button
        onClick={() => handleChatDroppDown()}
        style={{
          width: 60,
          height: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          backgroundColor: "white",
          boxShadow: "1px 2px 9px -1px rgba(66, 68, 90, 1)",
          borderRadius: 50,
        }}
      >
        <img
          style={{ width: 30, height: 30 }}
          src={projectImages.contactImg}
          alt="closesmall"
        />
      </button>
    </div>
  );
};

export default ButtonChatDropDown;
