import React from "react";
import projectImages from "../../style/projectImages";

// The FilesListComponent is responsible for rendering a list of files.
// It takes several props that define its behavior and appearance.
const FilesListComponent = ({
  fileList, // An array of file names to display.
  displayMenu, // Function to display context menu on right click.
  setIsFolderSelected, // Function to set whether a folder is selected.
  handleSaveNewFileName, // Function to handle the action of saving a new file name.
  editingBaseName, // The base name that is currently being edited.
  editingFile, // The file that is currently being edited.
  setEditingFile, // Function to set the file being edited.
  getFileExtension, // Function to extract and return file extension.
  handleNameChange, // Function to handle changes to the file's base name.
  calculateWidth, // Function to calculate the width of the input field based on content.
  downloadAndViewFile, // Function
  imageOrFileName, // State
}) => {
  // Function to check if a file is an image
  const isImageFile = (fileName) => {
    return /\.(jpg|jpeg|png)$/i.test(fileName);
  };

  return (
    <>
      {fileList.map((fileName) => {
        // Map through each file name in the fileList array.
        return (
          <div
            className="file__item"
            key={fileName} // Unique key for each item for React's rendering algorithm.   editingFile
            onClick={() => {
              if (!editingFile) {
                displayMenu(null, fileName);
                downloadAndViewFile(fileName);
              }
            }}
            onContextMenu={(e) => {
              // Custom right-click menu handler.
              displayMenu(e, fileName);
              setIsFolderSelected(false); // Deselect folder when a file item is right-clicked.
            }}
          >
            {/* Adjust height dynamically if a folder is being edited */}
            <div style={{ height: editingFile === fileName ? 0 : 23 }} />

            {/* Conditional rendering if a folder is being edited */}
            {editingFile === fileName && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {/* Button to confirm the renaming of a folder */}
                <button
                  style={{ marginRight: 5 }}
                  onClick={() => handleSaveNewFileName(editingBaseName)}
                >
                  <img
                    alt="confirm"
                    style={{ width: 13, height: 13 }}
                    src={projectImages.check_blue}
                  />
                </button>
                {/* Button to cancel editing */}
                <button
                  style={{ marginRight: 10 }}
                  onClick={() => setEditingFile(false)}
                >
                  <img
                    alt="cancel"
                    style={{ width: 20, height: 20 }}
                    src={projectImages.deleteButton}
                  />
                </button>
              </div>
            )}

            {/* Button to select or open a folder */}
            <button style={{ width: "100%" }}>
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ maxWidth: 100, maxHeight: 100 }}
                  className="filesStyle"
                  src={
                    isImageFile(fileName)
                      ? projectImages.dt_file_image
                      : projectImages.docs
                  }
                  alt="file icon"
                />
              </span>
              <div>
                {/* Input field to edit the folder's name */}
                {editingFile === fileName ? (
                  <div>
                    <input
                      type="text"
                      value={editingBaseName}
                      onChange={handleNameChange} // Handle changes to the input field.
                      style={{
                        width: calculateWidth(editingBaseName.length) + "px", // Calculate and set input field width.
                        marginTop: 10,
                      }}
                      className="form-input px-2 py-1 border border-gray-300"
                      onKeyDown={(e) => {
                        // Handle the Enter key press event.
                        if (e.key === "Enter") {
                          handleSaveNewFileName(editingBaseName);
                        }
                      }}
                    />
                  </div>
                ) : (
                  // Display folder name
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: 6,
                      wordBreak: "break-word",
                    }}
                  >
                    {fileName}
                  </p>
                )}
              </div>
            </button>
          </div>
        );
      })}
    </>
  );
};

export default FilesListComponent;
