/**
 * File name: [ToogleMenu.jsx]
 * Description: [Pop-up component used in the component "Navbar.jsx" . Shows the authorized user and the "Startups" available for user]
 *
 */

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormattedName } from "../hooks/useFormattedName";
import { clearUser } from "../store/slices/userSlice";
import projectImages from "../style/projectImages";
import "../style/css/style.css";
import "./ToogleMenu.css";
import { clearStartups } from "../store/slices/startupsSlice";
import { clearRecords } from "../store/slices/notificationsSlice";
import { useChatCustomContext } from "../context/ChatProvider";

const ToogleMenu = ({ setEditMode }) => {
  const { client } = useChatCustomContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Loading "Startups" from localstore (Redux puts information in advance during authorization)
  const startupsString = localStorage.getItem("startups");
  const startupsArray = startupsString
    ? JSON.parse(startupsString).startups
    : [];

  // Get with Redux store favorites startup
  const favoriteStartups = useSelector((state) => state.favoriteStartups.data);

  // Loading user data from the Redux Store
  const {
    name: userName,
    email: userEmail,
    picture: userPhoto,
    isAdmin: isAdmin,
    _id: userID,
    additionalEmails: additionalEmails,
  } = useSelector((state) => state.user.data || {});

  // User logg out function
  const handleLogout = async () => {
    // Perform actions to log out of the account
    dispatch(clearUser());
    dispatch(clearStartups());
    dispatch(clearRecords());

    await client.disconnect();
    // Clear user data from local storage
    localStorage.clear();
    // Reload the page
    navigate(`/`);
  };

  // Using a custom hook to format the name
  const formattedName = useFormattedName(
    userName === " " ? userEmail.replace(/\.(\w+)@gmail.com/, " $1") : userName
  );

  return (
    <div
      style={{
        borderRadius: 20,
        zIndex: 10,
        boxShadow: "0 5px 77px rgba(0, 0, 0, 0.23)",
      }}
      className="ToogleMenu__container absolute right-12 top-16 mt-0 py-2  bg-white border border-gray-200 "
    >
      <div
        style={{ lineHeight: "1.2rem" }}
        className="flex items-center px-4 py-2"
      >
        <img
          src={
            userPhoto && userPhoto !== "" ? userPhoto : projectImages.userImg
          }
          onError={(e) => (e.currentTarget.src = projectImages.userImg)} // Sets a backup image if the main one is not loaded
          alt="userimage"
          className="w-12 h-12 rounded-full mr-2"
        />
        <div className="flex flex-col">
          <span
            style={{ fontSize: 22, fontWeight: 600 }}
            className="font-bold text-black"
          >
            {formattedName}
          </span>
          <span style={{ fontSize: 14 }} className="text-gray-500 ">
            {userEmail}
          </span>
        </div>
      </div>
      {/* !<----LINE----> */}
      <div className="w-full flex justify-center mt-2 mb-2">
        <div
          style={{
            width: "90%",
            backgroundColor: "grey",
            height: 0.5,
            marginBottom: 10,
          }}
          className="h-px"
        ></div>
      </div>
      {/* 1 */}
      {startupsArray &&
        startupsArray
          .filter((startup) =>
            isAdmin
              ? favoriteStartups.includes(startup._id)
              : startup.coFounder.includes(userEmail) ||
                startup.viewer.includes(userEmail) ||
                startup.createdBy === userID ||
                additionalEmails?.some(
                  (email) =>
                    startup.coFounder.includes(email) ||
                    startup.viewer.includes(email)
                )
          )
          .map((startup) => {
            const truncatedDescription =
              startup.title.length > 20
                ? startup.title.substring(0, 15) + "..." // Adding "..." at the end of cut text
                : startup.title;

            // Transition parameter
            let transitionParameter;

            if (startup.urlAddress !== "") {
              transitionParameter = startup.urlAddress;
            } else {
              transitionParameter = startup._id;
            }

            return (
              <Link
                key={startup._id}
                style={{ paddingTop: 0, paddingBottom: 0 }}
                onClick={() => setEditMode && setEditMode(false)}
                to={`/startups/${transitionParameter}`}
                className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                <div
                  className="flex items-center px-4"
                  style={{ paddingLeft: 3 }}
                >
                  <div
                    className="w-45 h-45 rounded-full shadow-md flex justify-center items-center flex-shrink-0"
                    style={{
                      width: 45,
                      height: 45,
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {/* Checking if we have a photo */}
                    {startup.imageLogo ? (
                      <img
                        style={{ width: 45, height: 45 }}
                        src={`${startup.imageLogo}`}
                        alt="Tresor Track"
                        className="w-6 h-6 rounded-full"
                      />
                    ) : (
                      <img
                        style={{ width: 35, height: 35, marginTop: 5 }}
                        src={projectImages.bigLogoImg}
                        alt="bigLogoImg1"
                      />
                    )}
                  </div>

                  <div className="flex flex-col items-start ml-5 mt-1">
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: "1rem",
                        textAlign: "left",
                        maxWidth: "89%",
                        overflowWrap: "break-word",
                        resize: "none",
                      }}
                    >
                      {truncatedDescription}
                    </p>
                    <span
                      style={{ fontSize: 14, margin: 0 }}
                      className="text-gray-500"
                    >
                      {process.env.REACT_APP_CLIENT_URL}
                    </span>
                  </div>
                </div>
                {/* Checking whether a startup is published or not  */}
                {startup.isPublished ? (
                  <div style={{ height: 13.5 }} />
                ) : (
                  <div
                    style={{
                      fontSize: 9,
                      display: "flex",
                      position: "relative",
                      justifyContent: "end",
                      fontWeight: 700,
                      color: "red",
                    }}
                  >
                    not published
                  </div>
                )}
              </Link>
            );
          })}
      {/* 2 */}
      {isAdmin ? null : (
        <Link
          to="/addCompany"
          className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
          style={{ paddingTop: 0 }}
        >
          <div className="flex items-center px-4" style={{ paddingLeft: 3 }}>
            <div
              className="w-45 h-45 rounded-full shadow-md flex justify-center items-center"
              style={{
                width: 45,
                height: 45,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            >
              <img
                src={projectImages.addCompanyImg}
                alt="Tresor Track"
                className="w-6 h-6 rounded-full     "
              />
            </div>

            <div className="flex flex-col items-start ml-5 mt-1">
              <p
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "1rem",
                }}
              >
                Add a Company
              </p>
              <span
                style={{ fontSize: 14, margin: 0 }}
                className="text-gray-500 "
              >
                Website
              </span>
            </div>
          </div>
        </Link>
      )}
      {/* 4 */}
      <Link
        onClick={handleLogout}
        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
        style={{ paddingTop: 5 }}
      >
        <div className="flex items-center px-4" style={{ paddingLeft: 3 }}>
          <div
            className="w-45 h-45 rounded-full shadow-md flex justify-center items-center"
            style={{
              width: 45,
              height: 45,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
            }}
          >
            <img
              style={{ marginLeft: 5 }}
              src={projectImages.logOutImg}
              alt="Tresor Track"
              className="w-6 h-6 "
            />
          </div>

          <div className="flex flex-col items-start ml-5 mt-1">
            <p
              style={{
                fontWeight: 600,
                fontSize: 16,
                lineHeight: "1rem",
              }}
            >
              Log out
            </p>
          </div>
        </div>
      </Link>
      {/* 5 */}
      <div className="block px-4 py-2 text-gray-800 ">
        <div
          style={{ justifyContent: "center", marginTop: 5 }}
          className="flex "
        >
          <Link
            to="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col"
          >
            <span className="text-black text-xs ">Privacy Policy</span>
          </Link>
          <span className="mx-1 text-gray-500 text-xs">•</span>
          <Link
            to="/terms"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col"
          >
            <span className="text-black text-xs">Terms of Service</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ToogleMenu;
