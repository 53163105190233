/**
 * Custom hook that formats a user's name into the desired format.
 *
 * @param {string} userName - The user's name.
 * @returns {string} The formatted name.
 */

export const useFormattedName = (userName) => {
  // Splitting the user's name into separate words
  const nameParts = userName ? userName.split(" ") : [];
  // Destructuring the first name and last name from the name parts
  const [firstName, lastName] = nameParts;
  // Constructing the desired name format based on the number of name parts
  const formattedName =
    nameParts.length > 1 ? `${firstName} ${lastName[0]}` : userName;
  return formattedName;
};
