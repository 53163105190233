/**
 * Hook: usePageTimer
 *
 * Used to measure the time spent by the user on a page and send metrics to the server.
 *
 * Parameters:
 *   - id: page identifier (if not provided, execution is halted)
 *
 * Behavior:
 *   - On component mount:
 *     - Starts a timer and stores the start time in sessionStorage.
 *     - Sets a beforeunload event handler that is triggered before the page is unloaded.
 *   - On component unmount:
 *     - Removes the beforeunload event handler.
 *   - On beforeunload event:
 *     - Calculates the total time spent on the page.
 *     - Sends the metrics to the server using an HTTP POST request.
 *
 * Dependencies:
 *   - id: a variable that affects the behavior of the hook. If id changes, the timer will be restarted.
 *
 */

import { useEffect } from "react";
import axios from "axios";

const usePageTimer = (id) => {
  const token = JSON.parse(localStorage.getItem("userToken"));

  const view = 1;
  useEffect(() => {
    // Start the timer
    const startTimer = () => {
      sessionStorage.setItem("startTime", new Date().getTime());
    };
    // Calculate the time spent on the page
    const calculateTimeOnPage = () => {
      const startTime = sessionStorage.getItem("startTime");
      const currentTime = new Date().getTime();
      const timeOnPage = currentTime - startTime;
      return timeOnPage;
    };
    // Send metrics to the server
    const saveMetrics = async (timeOnPage) => {
      if (!id) {
        // If id is null, stop the execution
        return;
      }
      try {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/metrics/send-info`,
          {
            id,
            timeOnPage,
            view,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.error("Error saving metrics:", error);
      }
    };
    // beforeunload event handler
    const handlePageUnload = () => {
      const timeOnPage = calculateTimeOnPage();
      saveMetrics(timeOnPage);
    };
    // Start the timer and add the beforeunload event handler
    startTimer();
    window.addEventListener("beforeunload", handlePageUnload);
    // Remove the beforeunload event handler on component unmount
    return () => {
      window.removeEventListener("beforeunload", handlePageUnload);
    };
  }, [id]);
};

export default usePageTimer;
