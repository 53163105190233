/**
 * File name: [Loader.jsx]
 * Description: [Rotating circle element to display the loading process]
 *
 */

import React from "react";

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
