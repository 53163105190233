import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotifications } from "../store/thunk/fetchNotifications";
import axios from "axios";
import ReactToastify from "../hooks/useReactToastify";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import projectImages from "../style/projectImages";

const NotificationPage = () => {
  const token = JSON.parse(localStorage.getItem("userToken"));

  const dispatch = useDispatch();
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);

  const userEmail = useSelector((state) => state.user.data.email);
  const { accessRecords } = useSelector(
    (state) => state.notificationsSlice || {}
  );

  // Function to change notification filter
  const [activeFilter, setActiveFilter] = useState("all");
  const changeFilter = (filter) => {
    setActiveFilter(filter);
  };

  // Function to handle filtering notifications
  const filteredNotifications =
    activeFilter === "File opening"
      ? accessRecords?.filter((record) => !record.isDataRoomOpen)
      : activeFilter === "Data room access"
      ? accessRecords?.filter((record) => record.isDataRoomOpen)
      : accessRecords;

  // Function to format date strings
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  // Function that switches the notification status from UNREAD to READ
  // It may be better to add a check to see if the user has unread notifications
  // so that this feature doesn't work every time the page renders
  useEffect(() => {
    const handleToggleReadStatus = async () => {
      setIsLoadingSpinner(true);
      try {
        const data = {
          userEmail: userEmail,
        };
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/notifications/markAsDone`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch(fetchNotifications({ userEmail, dispatch }));
      } catch (error) {
        ReactToastify("warn", "Error during the status update process");
      } finally {
        setIsLoadingSpinner(false);
      }
    };
    handleToggleReadStatus();
  }, []);

  // Function to handle DELETING a notification
  const handleDeleteAllNotifications = async () => {
    setIsLoadingSpinner(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/notifications/delete/${userEmail},`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(fetchNotifications({ userEmail, dispatch }));
      ReactToastify("success", "Notifications successfully deleted");
    } catch (error) {
      ReactToastify("warn", "Error in the process of deleting a notification");
    } finally {
      setIsLoadingSpinner(false);
    }
  };

  const handleDeleteNotification = async (_id) => {
    setIsLoadingSpinner(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/notifications/delete/${userEmail}/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(fetchNotifications({ userEmail, dispatch }));
      ReactToastify("success", "Notification successfully deleted");
    } catch (error) {
      ReactToastify("warn", "Error in the process of deleting a notification");
    } finally {
      setIsLoadingSpinner(false);
    }
  };

  return (
    <>
      {isLoadingSpinner && <Loader />}
      <div style={{ height: "100vh" }}>
        <Navbar />
        <div className="flex flex-col md:flex-row">
          {/* Filter panel */}
          <div className="w-full md:w-1/4 p-4 space-y-2">
            <h2 className="text-lg text-gray-700 font-semibold mb-2">
              Filtering options
            </h2>
            <button
              onClick={() => changeFilter("all")}
              className={`block w-full text-left px-4 py-2 rounded-md ${
                activeFilter === "all"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
            >
              All
            </button>
            <button
              onClick={() => changeFilter("File opening")}
              className={`block w-full text-left px-4 py-2 rounded-md ${
                activeFilter === "File opening"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
            >
              File opening
            </button>
            <button
              onClick={() => changeFilter("Data room access")}
              className={`block w-full text-left px-4 py-2 rounded-md ${
                activeFilter === "Data room access"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
            >
              Data room access
            </button>
            <button
              onClick={() => handleDeleteAllNotifications()}
              className={`block w-full text-left px-4 py-2 rounded-md bg-red-500 hover:bg-red-700 text-white`}
              style={{ marginTop: 30 }}
            >
              Delete all notifications
            </button>
          </div>

          {/* List of notifications */}
          <div className="w-full md:w-3/4 p-4 space-y-4 ">
            {filteredNotifications?.length > 0 ? (
              <>
                {filteredNotifications?.map((record) => (
                  <div
                    key={record._id}
                    className={`p-4 border rounded-md shadow-sm ${
                      record.read ? "bg-gray-200" : "bg-white"
                    }`}
                  >
                    <div className="flex flex-col md:flex-row justify-between items-center">
                      <div>
                        {/* Title of the notification */}
                        <h4 className="text-lg font-semibold">
                          {record.isDataRoomOpen
                            ? "Data room opening detected"
                            : "New file opening detected"}
                        </h4>
                        {/* Name of the startup */}
                        {record.startupName && (
                          <p className="text-gray-800 font-medium">
                            Startup: {record.startupName}
                          </p>
                        )}
                        {/* Details of the notification */}
                        <p className="text-gray-600">
                          {record.isDataRoomOpen ? "Data room" : "File"} was
                          opened by the user {record.accessedBy} at{" "}
                          {formatDate(record.accessedAt)}.{" "}
                          {record.isDataRoomOpen ? null : (
                            <>File name: {record.fileName}.</>
                          )}
                        </p>
                      </div>
                      {/* Delete button */}
                      <button
                        onClick={() => handleDeleteNotification(record._id)}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                      >
                        <img
                          style={{ maxWidth: 20, height: 20 }}
                          src={projectImages.delete1}
                          alt="Delete"
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="text-center p-4">
                <h2 className="text-lg text-gray-700 font-semibold">
                  You have no notifications.
                </h2>
                <p className="text-gray-600">
                  All your notifications will appear here.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationPage;
