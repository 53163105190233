/**
 * File name: [SelectPhoto.jsx]
 * Description: [Component that is called in the components "AddCompany.jsx", "StartupScreen.jsx" when selecting images. Required to adjust the type of photos]
 *
 */

import React, { useState, useEffect, useMemo } from "react";
import Cropper from "react-easy-crop";
import projectImages from "../style/projectImages";
import {
  mainBody,
  mainBodyModal,
  modalButton,
  modalCircle,
  upContainer,
} from "../style/setStyles";

const SelectPhoto = ({ imageLogo, onClose, onImageSelect, imageType }) => {
  // Saving the state for the crop area, scaling, and aspect ratio
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(null);
  const [isUnmounted, setIsUnmounted] = useState(false);
  // Processing the end of trimming
  const onCropComplete = (_, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  // Handle changing the aspect ratio
  const onAspectRatioChange = (event) => {
    const selectedRatio = parseFloat(event.target.value);
    setAspectRatio(selectedRatio);
  };
  // Zoom in
  const onZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.1);
  };
  // Zoom out
  const onZoomOut = () => {
    if (zoom > 1.03) {
      setZoom((prevZoom) => prevZoom - 0.1);
    }
  };
  // Get the URL of a cropped photo
  const croppedImageUrl = useMemo(() => {
    if (imageLogo) {
      return URL.createObjectURL(imageLogo);
    }
    return null;
  }, [imageLogo]);
  // Effect for setting the "isUnmounted" flag when unmounting a component
  useEffect(() => {
    return () => {
      setIsUnmounted(true);
    };
  }, []);
  // Getting a Blob object of a cropped photo
  const getCroppedImageBlob = async (image, croppedAreaPixels) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const imageEl = new Image();
    imageEl.src = URL.createObjectURL(image);

    return new Promise((resolve, reject) => {
      imageEl.onload = () => {
        if (isUnmounted) return;

        const { width, height } = croppedAreaPixels;

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(
          imageEl,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          width,
          height
        );

        canvas.toBlob(resolve, "image/png");
      };

      imageEl.onerror = reject;
    });
  };
  // Handling of clicking on the "Done" button
  const onDone = async () => {
    try {
      const croppedImageBlob = await getCroppedImageBlob(
        imageLogo,
        croppedArea
      );
      const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
      if (imageType === "logo") {
        onImageSelect(croppedImageBlob, "logo");
      } else if (imageType === "main") {
        onImageSelect(croppedImageBlob, "main");
      } else if (imageType === "phone") {
        onImageSelect(croppedImageBlob, "phone");
      } else if (imageType === "device") {
        onImageSelect(croppedImageBlob, "device");
      }
    } catch (error) {
      console.error("Error while cropping the image", error);
    }
  };

  useEffect(() => {
    if (imageType === "logo") {
      setAspectRatio(1 / 1);
    } else if (imageType === "main") {
      setAspectRatio(11 / 9);
    } else if (imageType === "phone") {
      setAspectRatio(2 / 4);
    } else if (imageType === "device") {
      setAspectRatio(2 / 1);
    }
  }, [imageType]);

  return (
    <div className="modal__wrapper">
      {/*<-- Main body modal --> */}
      <div style={mainBodyModal}>
        {/*<-- Main body  --> */}
        <div style={mainBody}>
          {/*<-- MAIN BODY  --> */}
          <div style={upContainer}>
            <div>Crop the photo</div>
            {/*<-- CLose button  --> */}
            <button
              style={{ width: 20, height: 20, marginRight: 20 }}
              onClick={onClose}
            >
              <div style={modalCircle}>
                <div style={{ marginBottom: 2 }}>x</div>
              </div>
            </button>
          </div>
          {/*<-- Cropper  --> */}
          <Cropper
            image={croppedImageUrl}
            crop={crop}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            style={{
              containerStyle: {
                position: "relative",
                width: "100%",
                height: "300px",
                backgroundColor: "black",
              },
            }}
          />
          {/*<-- Down container  --> */}
          <div
            style={{
              width: "100%",
              height: 60,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 20,
              }}
            >
              <button style={modalButton} onClick={onZoomIn}>
                <img
                  style={{ width: 15, height: 15 }}
                  src={projectImages.glassPlus}
                  alt="glassPlus"
                />
              </button>
              <button style={modalButton} onClick={onZoomOut}>
                <img
                  style={{ width: 15, height: 15 }}
                  src={projectImages.glassMinus}
                  alt="glassMinus"
                />
              </button>
            </div>
            <button
              style={{
                width: 80,
                height: 30,
                marginRight: 20,
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
              }}
              className="btn"
              onClick={onDone}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPhoto;
