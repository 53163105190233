/**
 * File name: [Navbar.jsx]
 * Description: [The main header of the site, which includes the components "GoogleMenu.jsx", "Search.jsx". Interaction with the "Filter" component for filtering]
 *
 */

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormattedName } from "../hooks/useFormattedName";
import { useChatCustomContext } from "../context/ChatProvider";
import { fetchStartups } from "../store/thunk/fetchStartups";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchBarDropDown from "./SearchBarDropDown";
import projectImages from "../style/projectImages";
import Notification from "./Notification";
import ToogleMenu from "./ToogleMenu";
import "../style/css/style.css";
import "./Navbar.css";
import ReactToastify from "../hooks/useReactToastify";
import AlertPopup from "./AlertPopup";
import useEscKeyListener from "../hooks/useEscKeyListener";

// Props , which transmits a signal to activate the function
const Navbar = ({
  handlePublishSubmit,
  handleDeleteClick,
  setIsDropdownOpen,
  startupScreenRef,
  handleEditClick,
  handleSaveClick,
  isStartupScreen,
  isDropdownOpen,
  isHomeScreen,
  handleSubmit,
  isDataLoaded,
  accessStatus,
  SettingMenu,
  setEditMode,
  isPublished,
  isFavorite,
  isChatPage,
  editMode,
  title,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // List unread messages for icon
  const { unreadMessages, unreadChannels } = useChatCustomContext();
  // State Notification
  const [showNotification, setShowNotification] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);

  const { accessRecords } = useSelector(
    (state) => state.notificationsSlice || {}
  );
  const filteredRecordsCount = accessRecords?.filter(
    (record) => !record.read
  )?.length;

  // Getting user data from the Redux store
  const {
    name: userName,
    picture: userPhoto,
    email: userEmail,
    isAdmin,
  } = useSelector((state) => state.user.data || {});

  //////////////////////////////////////////////////////////////////////////////////////////////// START
  // Check if the user is authorized in the system

  const userData = useSelector((state) => state.user.data || {});
  // Check if the userData object is empty
  const isEmptyUserData = Object.keys(userData).length !== 0;
  //////////////////////////////////////////////////////////////////////////////////////////////// END

  // Initial state of toogle menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navbarRef = useRef(null);

  //////////////////////////////////////////////////////////////////////////////////////////////// START
  // Required to determine if the user clicked outside the zone of the "ToogleMenu.jsx" element

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  // Changing the state of the toogle menu
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  // Using a custom hook to format the name
  const formattedName = useFormattedName(
    userName === " " ? userEmail.replace(/\.(\w+)@gmail.com/, " $1") : userName
  );

  // Showing / hiding an element "SettingMenu.jsx"
  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  //////////////////////////////////////////////////////////////////////////////////////////////// END

  //////////////////////////////////////////////////////////////////////////////////////////////// START
  // <<================= Opening of BurgerMenu =================>> START

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(
    localStorage.getItem("isMobileMenuOpen") === "true"
  );

  useEffect(() => {
    // Handle the resize event to update the mobile menu state
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setIsMobileMenuOpen(true);
      } else {
        setIsMobileMenuOpen(false);
      }
    };

    // Add the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Save the isMobileMenuOpen state to localStorage when it changes
    localStorage.setItem("isMobileMenuOpen", isMobileMenuOpen);
  }, [isMobileMenuOpen]);
  //////////////////////////////////////////////////////////////////////////////////////////////// END

  //////////////////////////////////////////////////////////////////////////////////////////////// START
  // <<============= Opening of DropDown BurgerMenu ============>> START

  const [isOpen, setIsOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setIsOpen(!isOpen);
    if (isMenuOpen) {
      toggleMenu();
    }
  };
  //////////////////////////////////////////////////////////////////////////////////////////////// END

  // Save startup form
  const handleNavbarSaveClick = () => {
    // Calling the handleSaveClick function, passing an event
    handleSaveClick(true, false);
  };

  // Change Notification state
  const handleChangeStateNotification = () => {
    setShowNotification((prevState) => !prevState);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////// START
  // <<==== Getting a filter value by name and then using the function from the Redux store ====>>

  // State of the search value
  const [searchQuery, setSearchQuery] = useState("");

  // Note the data from the search
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    dispatch(
      fetchStartups({
        filteringByName: searchQuery,
      })
    );
  }, [searchQuery, dispatch]);
  //////////////////////////////////////////////////////////////////////////////////////////////// END

  const handleLinkClick = () => {
    if (location.pathname === "/chat") {
      // If we are already on /chat, reload the page
      navigate(0);
    }
  };

  const handleOpenAlertPopup = () => {
    setOpenAlertPopup((prevState) => !prevState);
    setIsDropdownOpen((prevState) => !prevState);
  };

  // Use hook
  useEscKeyListener(setIsMenuOpen);
  useEscKeyListener(setShowNotification);

  return (
    <header>
      <div
        style={{ zoom: "90%" }}
        className={`container ${isHomeScreen ? "container__navbar" : ""}`}
      >
        <div className="outer__header">
          <div className="header__left">
            <Link to="/home">One Metric</Link>
          </div>
          {/* <========== Search bar ==========> */}
          {isEmptyUserData && (
            <div className="search__wrapper " style={{ left: 280 }}>
              <form style={{ display: "flex" }} action="">
                <input
                  type="text"
                  placeholder="Search for a company"
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                />
                <div
                  style={{
                    width: 22,
                    height: 22,
                    position: "relative",
                    right: 40,
                    top: 12,
                  }}
                >
                  {/* <img src={projectImages.enter} alt="enter" /> */}
                </div>
              </form>
            </div>
          )}
          {/* Search bar dropdown menu */}
          <div>
            {searchQuery && (
              <SearchBarDropDown
                isHomeScreen={isHomeScreen}
                setSearchQuery={setSearchQuery}
              />
            )}
          </div>

          <div className="header__right">
            <div className="sign__Ini" ref={navbarRef}>
              {userName ? (
                <>
                  {/*<<============IS THIS A MOBILE VIEW?============>>*/}
                  {isMobileMenuOpen ? (
                    <div
                      style={{ width: "100%", marginRight: 15 }}
                      className={`burger-menu ${isOpen ? "open" : ""}`}
                    >
                      <div
                        className={`burger-icon ${isOpen ? "active" : ""}`}
                        onClick={() => {
                          toggleBurgerMenu();
                          // if (isMenuOpen) {
                          //   toggleMenu();
                          // }
                        }}
                      >
                        <span
                          className={`bar bar1 ${isOpen ? "bar1-active" : ""}`}
                        ></span>
                        <span
                          className={`bar bar2 ${isOpen ? "bar2-active" : ""}`}
                        ></span>
                        <span
                          className={`bar bar3 ${isOpen ? "bar3-active" : ""}`}
                        ></span>
                      </div>
                      {/*<<============ BURGER__MENU MODAL WINDOW ============>>*/}
                      {isOpen && (
                        <div className="modal-overlay">
                          <div className="modal">
                            <div className="header__buttons header__mod_2">
                              {isStartupScreen ? (
                                <>
                                  {/* SAVE */}
                                  <div
                                    className="navbar__icons"
                                    style={{
                                      marginLeft:
                                        isFavorite || !isFavorite ? 30 : null,
                                    }}
                                  >
                                    {/* {isAdmin ? ( */}
                                    <>
                                      {/*<<======== ADDED TO FAVORITES ? ========>>*/}
                                      <Link
                                        to={"/home"}
                                        style={{
                                          left: -38,
                                          top: 2,
                                          position: "relative",
                                        }}
                                      >
                                        <span style={{ width: 25, height: 25 }}>
                                          <img
                                            src={projectImages.heartEmpty}
                                            alt="heart"
                                          />
                                        </span>
                                      </Link>
                                    </>
                                    {/* ) : ( */}
                                    <>
                                      {editMode && !isAdmin ? (
                                        <Link
                                          id="saveButton"
                                          onClick={
                                            isPublished
                                              ? handleSubmit(
                                                  handleNavbarSaveClick
                                                )
                                              : handleNavbarSaveClick
                                          }
                                        >
                                          <span
                                            style={{
                                              position: "relative",
                                              width: 29,
                                              height: 29,
                                              top: -31,
                                            }}
                                          >
                                            <img
                                              src={projectImages.check}
                                              alt="foot"
                                            />
                                          </span>
                                        </Link>
                                      ) : (
                                        <Link
                                          onClick={() => {
                                            accessStatus === "Full access"
                                              ? handleEditClick()
                                              : ReactToastify(
                                                  "warn",
                                                  "You do not have permission"
                                                );
                                          }}
                                        >
                                          <span
                                            style={{
                                              width: 25,
                                              height: 25,
                                              position: "relative",
                                              top: -29,
                                            }}
                                          >
                                            <img
                                              src={projectImages.pen}
                                              alt="foot"
                                            />
                                          </span>
                                        </Link>
                                      )}
                                    </>
                                    {/* )} */}
                                  </div>
                                  {/* DELETE */}
                                  <div
                                    className="navbar__icons"
                                    ref={startupScreenRef}
                                    style={{
                                      display: isAdmin ? "none" : "block",
                                    }}
                                  >
                                    {isAdmin ? (
                                      <></>
                                    ) : (
                                      <Link>
                                        <span>
                                          <button
                                            style={{
                                              maxWidth: 22,
                                              maxHeight: 22,
                                              marginTop: 0,
                                            }}
                                            className="dropdown-toggle"
                                            onClick={handleDropdownToggle}
                                          >
                                            <img
                                              src={
                                                isDropdownOpen
                                                  ? projectImages.settingBlack
                                                  : projectImages.settingWhite
                                              }
                                              alt="foot"
                                            />
                                          </button>

                                          {isDropdownOpen && (
                                            <SettingMenu
                                              handlePublishSubmit={
                                                handlePublishSubmit
                                              }
                                              handleDeleteClick={
                                                handleDeleteClick
                                              }
                                              isPublished={isPublished}
                                              isDropdownOpen={isDropdownOpen}
                                              handleOpenAlertPopup={
                                                handleOpenAlertPopup
                                              }
                                            />
                                          )}
                                        </span>
                                      </Link>
                                    )}
                                  </div>
                                </>
                              ) : null}

                              <div className="notifications__button navbar__icons">
                                {showNotification && (
                                  <Notification
                                    unreadMessages={unreadMessages}
                                    unreadChannels={unreadChannels}
                                    handleChangeStateNotification={
                                      handleChangeStateNotification
                                    }
                                  />
                                )}
                                <button
                                  onClick={() =>
                                    handleChangeStateNotification()
                                  }
                                >
                                  <img
                                    style={{ width: 26, height: 26 }}
                                    src={
                                      showNotification
                                        ? projectImages.notificationBlack
                                        : projectImages.notificationWhite
                                    }
                                    alt="bell"
                                  />
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: 42,
                                      marginLeft: 11,
                                      width: 18,
                                      height: 18,
                                      backgroundColor:
                                        unreadMessages === 0 ? "white" : "red",
                                      border:
                                        unreadMessages === 0
                                          ? "1px solid black"
                                          : "1px solid white",
                                      borderRadius: 50,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 700,
                                        maxHeight: 18,
                                        maxWidth: 18,
                                      }}
                                    >
                                      {unreadMessages}
                                    </span>
                                  </div>
                                </button>
                              </div>
                              <div className="contact__button navbar__icons">
                                <Link
                                  to="/chat"
                                  className="contact__button"
                                  onClick={() => handleLinkClick()}
                                >
                                  <img
                                    style={{
                                      width: isChatPage ? 23 : 25,
                                      height: isChatPage ? 23 : 25,
                                      marginTop: isChatPage ? 3 : 3,
                                    }}
                                    src={
                                      isChatPage
                                        ? projectImages.comment
                                        : projectImages.commentWhite
                                    }
                                    alt="contact"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="header__buttons">
                      {/*<<============ THIS IS STARTUPSCREEN ? ============>>*/}
                      {isStartupScreen && isDataLoaded ? (
                        <>
                          {/*<<======== SAVE ========>>*/}
                          <div className="navbar__icons">
                            {/* {isAdmin ? ( */}
                            <>
                              {/*<<======== ADDED TO FAVORITES ? ========>>*/}
                              <Link
                                to={"/home"}
                                style={{
                                  left: -50,
                                  top: 2,
                                  position: "relative",
                                }}
                              >
                                <span style={{ width: 25, height: 25 }}>
                                  <img
                                    src={projectImages.heartEmpty}
                                    alt="heart"
                                  />
                                </span>
                              </Link>
                            </>
                            {/*<<======== EDIT MODIFICATION ? ========>>*/}
                            {editMode ? (
                              <Link
                                id="saveButton"
                                onClick={
                                  isPublished
                                    ? handleSubmit(handleNavbarSaveClick)
                                    : handleNavbarSaveClick
                                }
                              >
                                <span
                                  style={{
                                    width: 29,
                                    height: 29,
                                    top: -30,
                                    position: "relative",
                                  }}
                                >
                                  <img src={projectImages.check} alt="foot" />
                                </span>
                              </Link>
                            ) : (
                              <Link
                                onClick={() => {
                                  accessStatus === "Full access"
                                    ? handleEditClick()
                                    : ReactToastify(
                                        "warn",
                                        "You do not have permission"
                                      );
                                }}
                              >
                                <span
                                  style={{
                                    width: 25,
                                    height: 25,
                                    top: -31,
                                    position: "relative",
                                  }}
                                >
                                  <img src={projectImages.pen} alt="foot" />
                                </span>
                              </Link>
                            )}
                          </div>
                          {/*<<======== DELETE ========>>*/}
                          <div
                            className="navbar__icons"
                            style={{ display: isAdmin ? "none" : "block" }}
                            ref={startupScreenRef}
                          >
                            <Link>
                              <span>
                                <button
                                  style={{
                                    maxWidth: 22,
                                    maxHeight: 22,
                                    marginTop: 0,
                                  }}
                                  className="dropdown-toggle"
                                  onClick={() => {
                                    accessStatus === "Full access"
                                      ? handleDropdownToggle()
                                      : ReactToastify(
                                          "warn",
                                          "You do not have permission"
                                        );
                                  }}
                                >
                                  <img
                                    src={
                                      isDropdownOpen
                                        ? projectImages.settingBlack
                                        : projectImages.settingWhite
                                    }
                                    alt="foot"
                                  />
                                </button>

                                {isDropdownOpen && (
                                  <SettingMenu
                                    handlePublishSubmit={handlePublishSubmit}
                                    handleDeleteClick={handleDeleteClick}
                                    isPublished={isPublished}
                                    handleOpenAlertPopup={handleOpenAlertPopup}
                                  />
                                )}
                              </span>
                            </Link>
                          </div>
                        </>
                      ) : null}
                      <div className="notifications__button navbar__icons">
                        {showNotification && (
                          <Notification
                            accessRecords={accessRecords}
                            unreadMessages={unreadMessages}
                            unreadChannels={unreadChannels}
                            handleChangeStateNotification={
                              handleChangeStateNotification
                            }
                          />
                        )}
                        <button
                          onClick={() => {
                            handleChangeStateNotification();
                            if (isMenuOpen) {
                              toggleMenu();
                            }
                          }}
                        >
                          <img
                            style={{ width: 26, height: 26 }}
                            src={
                              showNotification
                                ? projectImages.notificationBlack
                                : projectImages.notificationWhite
                            }
                            alt="bell"
                          />
                          <div
                            className="navbar__unreadCount-container"
                            style={{
                              position: "absolute",
                              marginLeft: 11,
                              width: 18,
                              height: 18,
                              backgroundColor:
                                (unreadMessages ?? 0) +
                                  (filteredRecordsCount ?? 0) ===
                                0
                                  ? "white"
                                  : "red",
                              border:
                                (unreadMessages ?? 0) +
                                  (filteredRecordsCount ?? 0) ===
                                0
                                  ? "1px solid black"
                                  : "1px solid white",
                              borderRadius: 50,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span
                              style={{
                                fontSize: 12,
                                fontWeight: 700,
                                maxHeight: 18,
                                maxWidth: 18,
                              }}
                            >
                              {(unreadMessages ?? 0) +
                                (filteredRecordsCount ?? 0)}
                            </span>
                          </div>
                        </button>
                      </div>
                      <div className="contact__button navbar__icons">
                        <Link
                          to="/chat"
                          className="contact__button"
                          onClick={() => handleLinkClick()}
                        >
                          <img
                            style={{
                              width: isChatPage ? 23 : 25,
                              height: isChatPage ? 23 : 25,
                              marginTop: isChatPage ? 3 : 3,
                            }}
                            src={
                              isChatPage
                                ? projectImages.comment
                                : projectImages.commentWhite
                            }
                            alt="contact"
                          />
                        </Link>
                      </div>
                    </div>
                  )}
                  <button
                    className="profile__icon"
                    aria-expanded={isMenuOpen}
                    aria-haspopup="true"
                    onClick={() => {
                      toggleMenu();
                      if (showNotification) {
                        handleChangeStateNotification();
                      }
                      if (isOpen) {
                        toggleBurgerMenu();
                      }
                    }}
                  >
                    <span className="navbat__formattedName">
                      {formattedName}
                    </span>
                    <img
                      src={
                        userPhoto && userPhoto !== ""
                          ? userPhoto
                          : projectImages.userImg
                      }
                      onError={(e) =>
                        (e.currentTarget.src = projectImages.userImg)
                      } // Sets a backup image if the main one is not loaded
                      className="w-11 h-11 rounded-full navbar__userImage"
                      alt="User Data"
                    />
                  </button>
                </>
              ) : (
                <Link to="/">
                  Sign On{" "}
                  <div className="profile__icon">
                    <img src={projectImages.profileiconImg} alt="profileicon" />
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
        {/* search__bar-for-mobile */}
        {isEmptyUserData && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="search__bar-for-mobile">
              <form
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                action=""
              >
                <input
                  type="text"
                  style={{ marginLeft: 20 }}
                  placeholder="Search for a company"
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                />
                <div className="navbar__enterButton">
                  {/* <img src={projectImages.enter} alt="enter" /> */}
                </div>
              </form>
            </div>
          </div>
        )}

        {isMenuOpen && userName && <ToogleMenu setEditMode={setEditMode} />}
        {openAlertPopup && (
          <AlertPopup
            handleOpenAlertPopup={handleOpenAlertPopup}
            handleDeleteClick={handleDeleteClick}
            title={title}
          />
        )}
      </div>
    </header>
  );
};

export default Navbar;
