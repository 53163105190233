/**
 * Custom hook
 *
 * When the hook is activated, a function is launched in the Redux store,
 * transferring user data to get a list of "Startups" owned by the user
 *
 */

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchStartups } from "../store/thunk/fetchStartups";

const UseFetchStartups = () => {
  const dispatch = useDispatch();

  const { email, _id, isAdmin, additionalEmails } = useSelector(
    (state) => state.user.data || {}
  );

  useEffect(() => {
    dispatch(
      fetchStartups({
        _id: _id,
        isAdmin: isAdmin,
        userEmail: email,
        additionalEmails: additionalEmails,
      })
    );
  }, [dispatch, _id, isAdmin, email, additionalEmails]);
};

export default UseFetchStartups;
