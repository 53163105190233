/**
 * File name: [SettingMenu.jsx]
 * Description: ["DropDown" element responsible for displaying the functionality of the "Setting" button]
 *
 */

import React, { useState } from "react";
import projectImages from "../style/projectImages";
import AlertPopup from "./AlertPopup";

// Props from "StartupScreen.jsx"
const SettingMenu = ({
  isPublished,
  handlePublishSubmit,
  handleOpenAlertPopup,
}) => {
  return (
    <>
      <div
        style={{
          width: 300,
          height: 120,
          borderRadius: 20,
          zIndex: 10,
          marginTop: 10,
          boxShadow: "0 5px 77px rgba(0, 0, 0, 0.23)",
        }}
        className="absolute bg-white"
      >
        {/* <=== List of function buttons ===>*/}
        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            margin: 0,
            marginTop: 20,
          }}
        >
          {/* 1 */}
          <li
            onClick={handlePublishSubmit}
            className="setting__button"
            style={{ marginLeft: 0 }}
          >
            <img
              style={{ width: 16, height: 22, marginLeft: 23 }}
              src={isPublished ? projectImages.upload1 : projectImages.publish}
              alt="foot"
            />
            <button style={{ marginLeft: 12, marginTop: 2, fontWeight: 600 }}>
              {isPublished ? <>Unpublish</> : <>Publish</>}
            </button>
          </li>
          {/* 2 */}
          <li
            onClick={handleOpenAlertPopup}
            className="setting__button"
            style={{ marginLeft: 0 }}
          >
            <img
              style={{
                width: 22,
                height: 22,
                marginLeft: 20,
              }}
              src={projectImages.delete1}
              alt="foot"
            />
            <button
              style={{
                marginLeft: 10,
                marginTop: 2,
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              Delete Startup
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SettingMenu;
