import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setLoading, setStartups, setError } from "../slices/startupsSlice";

export const fetchStartups = createAsyncThunk(
  "startups/fetchStartups",
  async (
    {
      _id,
      isAdmin,
      filteringByName,
      tags,
      industrys,
      competitors,
      raisingValue,
      currentStage,
      userEmail,
      selectedByDataRoom,
      selectedProfit,
      additionalEmails,
    },
    { dispatch }
  ) => {
    const token = JSON.parse(localStorage.getItem("userToken"));

    if (!token || _id === undefined) {
      return;
    }

    try {
      dispatch(setLoading(true));
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/startups`,
        {
          params: {
            userId: _id, // Use _id as a request parameter
            isAdmin: isAdmin,
            filteringByName: filteringByName,
            tags: tags,
            industrys: industrys,
            competitors: competitors,
            raisingValue: raisingValue,
            currentStage: currentStage,
            userEmail,
            selectedByDataRoom,
            selectedProfit,
            additionalEmails,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const startupsData = response?.data;

      dispatch(setStartups(startupsData));
      dispatch(setLoading(false));

      // Save startup data to localStorage after successful download
      localStorage.setItem("startups", JSON.stringify(startupsData));

      return startupsData;
    } catch (error) {
      dispatch(setError(error.message));
      dispatch(setLoading(false));
    }
  }
);
