import React from "react";
import "./ShowResults.css";

// Props with ChatMainFile.jsx
const ShowResults = ({
  handleSelectChannel,
  filterChannels,
  setShowResults,
  setSearchText,
  newHeight,
  isMobileView,
  isChatPage,
  showResults,
}) => {
  return (
    <div
      className={`${isChatPage ? "search-results__MCP" : "search-results"}`}
      style={{
        height: newHeight ? 420 : null,
        width: isMobileView ? "95%" : null,
        paddingLeft: isMobileView ? 9 : null,
        right: showResults ? 17 : null,
        height: isMobileView ? "72vh" : null,
      }}
    >
      <ul className="channel-list" style={{ paddingLeft: 3 }}>
        {filterChannels().map((channel) => (
          <li
            key={channel.id}
            onClick={() => {
              handleSelectChannel(channel);
              setShowResults(false);
              setSearchText("");
            }}
          >
            <div
              className={`channel-item ${
                channel.state.unreadCount > 0 ? "unread" : "read"
              }`}
            >
              <div className="channel-avatar">
                <img
                  className="avatar-image"
                  src={channel.data.image}
                  alt={channel.data.name}
                />
              </div>
              <div className="channel-details">
                <div className="channel-name">{channel.data.name}</div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ShowResults;
