import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {
  clearUser,
  initializeUser,
  selectIsUserAuthenticated,
} from "./store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import axios from "axios";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import StartupScreen from "./pages/StartupScreen";
import LoginScreen from "./pages/LoginScreen";
import AddCompany from "./pages/AddCompany";
import NotFound from "./pages/NotFound";
import Loader from "./components/Loader";
import Terms from "./pages/Terms";
import Home from "./pages/Home";
import "./App.css";
import "./style/css/style.css";
import Distributor from "./pages/Distributor";
import Chat from "./pages/Chat";
import NotificationPage from "./pages/NotificationPage";
import { clearStartups } from "./store/slices/startupsSlice";
import { clearRecords } from "./store/slices/notificationsSlice";
import RedirectManager from "./pages/RedirectManager";

function App() {
  const dispatch = useDispatch();
  const isUserAuthenticated = useSelector(selectIsUserAuthenticated);
  const [Admin, setNewAdmin] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Get user data from local storage
    const userData = localStorage.getItem("userData");
    // Set the initial state of the user when the page loads
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      setNewAdmin(parsedUserData.isAdmin);
      dispatch(initializeUser(parsedUserData));
    }
    setIsInitialized(true);
  }, [dispatch]);

  // Catching errors 400
  useEffect(() => {
    const handleAxiosError = (error) => {
      if (error.response && error.response.status === 400) {
        // Redirect the user to the "NotFound" page in case of error 400
        window.location.href = "/not-found";
      }
    };

    // Capture errors for all axios requests
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          dispatch(clearUser());
          dispatch(clearStartups());
          dispatch(clearRecords());
          // Clear user data from local storage
          localStorage.clear();
          // Reload the page
          window.location.href = "/?session=expired";
        }
        handleAxiosError(error);
        return Promise.reject(error);
      }
    );
  }, [dispatch]); // Apply the effect only once when mounting a component

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        {isInitialized ? (
          <Routes>
            <Route
              path="/"
              element={
                isUserAuthenticated ? (
                  Admin ? (
                    <Navigate to="/home" />
                  ) : (
                    <Distributor />
                  )
                ) : (
                  <LoginScreen />
                )
              }
            />
            <Route path="/linkedin" element={<LinkedInCallback />} />
            <Route
              path="/home"
              element={isUserAuthenticated ? <Home /> : <Navigate to="/" />}
            />
            <Route
              // !!! Here you should specify "searchBody", but for some reason it works only through "urlAddress"
              path="/startups/:urlAddress"
              element={
                isUserAuthenticated ? <StartupScreen /> : <Navigate to="/" />
              }
            />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/addCompany"
              element={
                isUserAuthenticated ? <AddCompany /> : <Navigate to="/" />
              }
            />
            <Route
              path="/chat"
              element={isUserAuthenticated ? <Chat /> : <Navigate to="/" />}
            />
            <Route path="*" element={<NotFound />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="/redirect-handler" element={<RedirectManager />} />
            <Route path="/notification-page" element={<NotificationPage />} />
          </Routes>
        ) : (
          <Loader />
        )}
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
