import { useEffect } from "react";

/**
 * A custom React hook that adds an event listener for the ESC key.
 * It can accept either a regular callback function or a setState function from useState.
 *
 * @param {Function} callbackOrSetState - A callback function to be executed when the ESC key is pressed,
 * or a setState function to set a boolean state to false.
 */

const useEscKeyListener = (callbackOrSetState) => {
  useEffect(() => {
    // Function to handle the ESC key press event
    const handleEsc = (event) => {
      // Check if the pressed key is ESC (key code 27)
      if (event.keyCode === 27) {
        // Check if the passed argument is a function
        if (typeof callbackOrSetState === "function") {
          // If it's a function, execute it
          callbackOrSetState();
        } else {
          // If it's not a function, assume it's a setState function and set the state to false
          callbackOrSetState(false);
        }
      }
    };
    // Add the event listener to the window object
    window.addEventListener("keydown", handleEsc);
    // Cleanup function to remove the event listener when the component unmounts or dependencies change
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [callbackOrSetState]); // Dependency array including the passed argument to re-run the effect if it changes
};

export default useEscKeyListener;
