import React, { useState } from "react";
import "./MessageList.css";
import PhotoModal from "./PhotoModal";

const MessageList = ({
  chatContainerRef,
  selectedChannel,
  isMobileView,
  myId,
  isChatPage,
}) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  // Function to open a photo in a large size
  const openPhoto = (imageUrl) => {
    setSelectedPhoto(imageUrl);
  };

  // Function for closing the modal
  const closePhoto = () => {
    setSelectedPhoto(null);
  };

  return (
    <div
      ref={chatContainerRef}
      className={`${
        isChatPage ? "message-list-container__MCP " : "message-list-container"
      }`}
    >
      {selectedChannel.state.messages.map((message) => (
        <div
          key={message.id}
          style={{
            display: "flex",
            justifyContent:
              message.user.id === myId ? "flex-end" : "flex-start",
          }}
        >
          {message.user.id !== myId ? (
            <>
              <img
                src={message.user.image}
                alt={message.user.name}
                className="user-image"
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start ",
                  flexDirection: "column",
                }}
              >
                {message.attachments.map((attachment, index) => (
                  <div key={attachment?.image_url}>
                    <img
                      onClick={() => openPhoto(attachment.image_url)}
                      style={{
                        height: 300,
                        marginBottom: 10,
                        marginRight: 10,
                      }}
                      key={index}
                      src={attachment.image_url}
                      alt="Attachment"
                    />
                    {/* Display modals with a photo if selectedPhoto is true */}
                    {selectedPhoto && (
                      <PhotoModal
                        imageUrl={selectedPhoto}
                        onClose={closePhoto}
                      />
                    )}
                  </div>
                ))}

                {message.text === "" ? null : (
                  <div
                    style={{
                      background: "#f5f5f5",
                      borderRadius: "0px 40px 40px 30px",
                      padding: "8px",
                      maxWidth: 200,
                      marginBottom: 4,
                      boxShadow: "0px 2px 6px -2px rgba(66, 68, 90, 1)",
                      wordWrap: "break-word",
                      fontSize: 13,
                      textAlign: "start",
                    }}
                  >
                    {message.text}
                  </div>
                )}
                <div
                  style={{
                    fontSize: 8,
                    marginBottom: 10,
                    paddingRight: 10,
                  }}
                >
                  {message.created_at?.toLocaleString()}
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end ",
                  flexDirection: "column",
                }}
              >
                {message.attachments.map((attachment, index) => (
                  <div key={attachment?.image_url}>
                    <img
                      onClick={() => openPhoto(attachment.image_url)}
                      key={index}
                      src={attachment.image_url}
                      alt="Attachment"
                      style={{
                        height: 300,
                        marginBottom: 10,
                        marginRight: 10,
                      }}
                    />
                    {/* Display modals with a photo if selectedPhoto is true */}
                    {selectedPhoto && (
                      <PhotoModal
                        imageUrl={selectedPhoto}
                        onClose={closePhoto}
                      />
                    )}
                  </div>
                ))}
                {message.text === "" ? null : (
                  <div
                    style={{
                      background: "#f5f5f5",
                      borderRadius: "44px 0px 30px 40px",
                      padding: "8px",
                      maxWidth: 200,
                      marginBottom: 4,
                      boxShadow: "0px 2px 6px -2px rgba(66, 68, 90, 1)",
                      wordWrap: "break-word",
                      marginRight: 7,
                      fontSize: 13,
                      textAlign: "end",
                    }}
                  >
                    {message.text}
                  </div>
                )}
                <div
                  style={{
                    fontSize: 8,
                    marginBottom: 10,
                    paddingRight: 10,
                  }}
                >
                  {message.created_at?.toLocaleString()}
                </div>
              </div>

              <img
                src={message.user.image}
                alt={message.user.name}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  marginTop: 4,
                }}
              />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default MessageList;
