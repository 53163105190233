import foot1 from "./img/foot1.svg";
import foot2 from "./img/foot2.svg";
import foot3 from "./img/foot3.svg";
import foot4 from "./img/foot4.svg";
import heartImg from "./img/heart.svg";
import bellImg from "./img/bell.png";
import profileiconImg from "./img/profileicon.svg";
import contactImg from "./img/contact.svg";
import tresortrackImg from "./img/l1.svg";
import addCompanyImg from "./img/l3.svg";
import logOutImg from "./img/l4.svg";
import hyperImg from "./img/l2.svg";
import profileIcon from "./img/profileicon.svg";
import arrow from "./img/arrow.svg";
import mobileFrameImg from "./img/mobileframe.webp";
import frameManiImg from "./img/framemain.webp";
import linkedinImg from "./img/linkedin.svg";
import bigLogoImg from "./img/biglogo.svg";
import mobileImg from "./img/mobile.jpg";
import foot1Img from "./img/foot1.svg";
import foot2Img from "./img/foot2.svg";
import foot3Img from "./img/foot3.svg";
import foot4Img from "./img/foot4.svg";
import imageImg from "./img/image1.webp";
import team1Img from "./img/team1.webp";
import team2Img from "./img/team2.webp";
import team3Img from "./img/team3.webp";
import biImg from "./img/bi.webp";
import uploadImg from "./img/upload.svg";
import diskette from "./img/diskette.png";
import edit from "./img/edit.png";
import delete1 from "./img/delete.png";
import loginScreenBg from "./img/loginback.webp";
import noImage from "./img/no-image.jpg";
import folder from "./img/folder.svg";
import closeSmall from "./img/closesmall.svg";
import addFolder from "./img/addfolder.svg";
import main from "./img/mainlogo.svg";
import ScreenShotDes from "./img/image1.webp";
import googleDocs from "./img/google-docs.png";
import docs from "./img/docs.png";
import download from "./img/download.png";
import deleteButton from "./img/delete-button.png";
import glassMinus from "./img/minus-glass.png";
import glassPlus from "./img/plus-glass.png";
import upTriangle from "./img/up-triangle.png";
import downTriangle from "./img/down-triangle.png";
import GooglePlay from "./img/play-market.jpg";
import AppStore from "./img/app-store.jpg";
import foot1Grey from "./img/foot1-grey.svg";
import foot2Grey from "./img/foot2-grey.svg";
import foot3Grey from "./img/foot3-grey.svg";
import foot4Grey from "./img/foot4-grey.svg";
import setting from "./img/settings.png";
import upload1 from "./img/upload.png";
import favorite from "./img/favorite.png";
import unfavorite from "./img/Unfavorite.png";
import heartEmpty from "./img/heart_empty.png";
import arrowUP from "./img/arrrow-up.png";
import arrowDown from "./img/arrow-down.png";
import userImg from "./img/user.png";
import enter from "./img/enter.png";
import notificationBlack from "./img/notification.png";
import notificationWhite from "./img/Bitmap.png";
import comment from "./img/comment.png";
import commentWhite from "./img/Bitmap Copy 8.png";
import folderBlack from "./img/folder.png";
import folderWhite from "./img/folder clear.png";
import barChatBlack from "./img/bar-chart copy.png";
import barChatWhite from "./img/bar-chart.png";
import favoritesBlack from "./img/bookmark.png";
import favoritesWhite from "./img/Shape.png";
import pen from "./img/pen.png";
import check from "./img/check.png";
import settingBlack from "./img/Group 18 Copy 2.png";
import settingWhite from "./img/Group 18.png";
import publish from "./img/upload copy.png";
import warning from "./img/warning.png";
import check_blue from "./img/check_blue.png";
import resize from "./img/resize.png";
import read from "./img/read.png";
import unread from "./img/unread.png";
import dt_file_image from "./img/dt_file_image.png";

const projectImages = {
  foot1,
  foot2,
  foot3,
  foot4,
  heartImg,
  bellImg,
  contactImg,
  profileiconImg,
  tresortrackImg,
  addCompanyImg,
  logOutImg,
  hyperImg,
  profileIcon,
  arrow,
  mobileFrameImg,
  frameManiImg,
  biImg,
  team3Img,
  team2Img,
  team1Img,
  imageImg,
  foot4Img,
  foot3Img,
  foot2Img,
  foot1Img,
  mobileImg,
  linkedinImg,
  bigLogoImg,
  uploadImg,
  diskette,
  edit,
  delete1,
  loginScreenBg,
  noImage,
  folder,
  closeSmall,
  addFolder,
  main,
  ScreenShotDes,
  googleDocs,
  docs,
  download,
  deleteButton,
  glassMinus,
  glassPlus,
  upTriangle,
  downTriangle,
  GooglePlay,
  AppStore,
  foot1Grey,
  foot2Grey,
  foot3Grey,
  foot4Grey,
  setting,
  upload1,
  favorite,
  unfavorite,
  heartEmpty,
  arrowUP,
  arrowDown,
  userImg,
  enter,
  notificationBlack,
  notificationWhite,
  comment,
  commentWhite,
  folderBlack,
  folderWhite,
  barChatBlack,
  barChatWhite,
  favoritesBlack,
  favoritesWhite,
  check,
  pen,
  settingBlack,
  settingWhite,
  publish,
  warning,
  check_blue,
  resize,
  read,
  unread,
  dt_file_image,
};

export default projectImages;
