/**
 * File name: [ShareStartup.jsx]
 * Description: [Component used in the "StartupScreen.jsx" component to send notifications via mail (AWS)]
 *
 */

import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { usePhoneValidation } from "react-international-phone";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import ReactToastify from "../hooks/useReactToastify";
import projectImages from "../style/projectImages";
import Loader from "./Loader";
import axios from "axios";
import {
  FlagEmoji,
  parseCountry,
  usePhoneInput,
  defaultCountries,
} from "react-international-phone";
import "react-international-phone/style.css";

// Props from the component "StartupScreen.jsx"
const ShareStartup = ({ handleShareClose, imageLogo, title, id }) => {
  const token = JSON.parse(localStorage.getItem("userToken"));

  const { urlAddress } = useParams();
  // Tracking the share
  const [shares, setShares] = useState(0);
  const [sending, setSending] = useState(false);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [permit, setPermit] = useState("Viewer");
  const [dataAccess, setDataAccess] = useState(false);
  const [phone, setPhone] = useState("33744223344");
  const { email, isAdmin } = useSelector((state) => state.user.data || {});

  // Check if Piblished startup
  const { isPublished } = useSelector(
    (state) => state.startups.data.startups[0] || {}
  );

  // State of the selected country
  const [selectedCountry, setSelectedCountry] = useState("us");
  // Status of country filtering by letter
  const [countryFilter, setCountryFilter] = useState("");
  // Status or clicked on the list of countries
  const [click, setClick] = useState(null);
  // Sending messages to the server
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Check if the form has been validated
    try {
      await validationSchema.validate(formik.values, { abortEarly: false });
      // Additional validation for phone number completion
      if (!phoneValidation.isValid) {
        ReactToastify("warn", "Please enter a complete phone number");
        return;
      }
      setSending(true);
      setIsLoadingSpinner(true);
      const formData = {
        text: formik.values.text,
        name: formik.values.name,
        companyName: formik.values.companyName,
        emailAddress: formik.values.emailAddress,
        phoneNumber: phone,
        permit,
        title,
        email,
        startupLink: `${process.env.REACT_APP_CLIENT_URL}/startups/${urlAddress}`,
        startupID: id,
        // Add the "dataroom: and user's email address" data if the "DataRoom Access" label is enabled
        dataAccess: dataAccess ? `${formik.values.emailAddress}` : null,
      };

      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/startups/send-email`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          ReactToastify("success", "Your message was sent successfully");
          // Tracking the share
          setShares(1);
        })
        .catch((error) => {
          ReactToastify("warn", "Error sending");
        })
        .finally(() => {
          handleShareClose();
          setIsLoadingSpinner(false);
          setSending(false);
        });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        ReactToastify("warn", "Validation error occurred");
      } else {
        ReactToastify("warn", "An error occurred");
      }
    }
  };

  // <<========Tracking the spread. Sending data to the server========>>
  useEffect(() => {
    if (!id) {
      // If id is null, stop the execution
      return;
    }
    // Sending a request to the server to save information about the startup share
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/metrics/send-info`,
      {
        id,
        shares,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }, [shares]);
  // <<===============================================================>>

  const handleDataAccessChange = (e) => {
    setDataAccess(e.target.checked);
  };

  // Checking for the correctness of the phone number
  const phoneValidation = usePhoneValidation(phone);

  // Change the state of the " Click "
  const handleSectionClick = () => {
    setClick((prevClick) => (prevClick === null ? true : !prevClick));
  };

  // Filter countries
  const filteredCountries = defaultCountries.filter((country) => {
    const countryName = parseCountry(country).name.toUpperCase();
    return countryName.startsWith(countryFilter);
  });

  const {
    phone: formattedPhone,
    handlePhoneValueChange,
    inputRef,
    country,
    setCountry,
  } = usePhoneInput({
    defaultCountry: selectedCountry,
    value: phone,
    countries: filteredCountries,
    onChange: (data) => {
      setPhone(data.phone);
    },
  });

  // Refresh dataAccess state if permission changed to "Co-founder"
  useEffect(() => {
    if (permit === "Co-founder") {
      setDataAccess(true);
    } else {
      setDataAccess(false);
    }
  }, [permit]);

  // Validation logic
  const initialValues = {
    text: "Hello Tony, it was a pleasure meeting you today at the Rosewood Hotel, please find here our company profile.",
    name: "Tony Daniel",
    companyName: "LVMH",
    emailAddress: "Tonydaniel@lvmh.com",
  };

  const validationSchema = Yup.object().shape({
    text: Yup.string()
      .required("Message is required")
      .max(399, "Message is too long"),
    name: Yup.string()
      .required("Calendly/link is required")
      .max(59, "Calendly/link is too long"),
    companyName: Yup.string()
      .required("Calendly/link is required")
      .max(59, "Calendly/link is too long"),
    emailAddress: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .max(39, "Email is too long"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <>
      <div className="modal__wrapper">
        {isLoadingSpinner && <Loader />}
        <div className="inner__modal">
          <div className="modal__box share__modal">
            <div className="head__">
              <p>
                <span>
                  <img src={projectImages.foot2} alt="contact" />
                </span>
                Share
              </p>
              <Link onClick={handleShareClose}>
                <img src={projectImages.closeSmall} alt="closesmall" />
              </Link>
            </div>
            <form onSubmit={handleFormSubmit}>
              {/* <-- Title + Image --> */}
              <div className="company__info">
                <div
                  className="w-45 h-45 rounded-full shadow-md flex justify-center items-center"
                  style={{
                    width: 45,
                    height: 45,
                    marginBottom: 10,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  {/* Checking if we have a photo */}
                  {imageLogo ? (
                    <img
                      style={{ width: 45, height: 45 }}
                      src={`${imageLogo}`}
                      alt="Tresor Track"
                      className="w-7 h-7 rounded-full     "
                    />
                  ) : (
                    <img
                      style={{ width: 35, height: 35, marginTop: 5 }}
                      src={projectImages.bigLogoImg}
                      alt="bigLogoImg1"
                    />
                  )}
                </div>
                <div className="desc__">
                  <p>{title}</p>
                  <span>{process.env.REACT_APP_CLIENT_URL}</span>
                </div>
                <p style={{ width: "100%" }}>
                  <textarea
                    name="text"
                    id="text"
                    value={formik.values.text}
                    onChange={(e) => {
                      if (e.target.value.length <= 400) {
                        formik.handleChange(e);
                      }
                    }}
                    style={{
                      width: "100%",
                      height: 70,
                      resize: "none",
                      overflow: "hidden",
                      textAlign: "center",
                      paddingTop: 4,
                      border: "1px solid #979797",
                      borderRadius: "5px",
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.text && formik.errors.text && (
                    <div
                      className="error"
                      style={{ color: "red", fontSize: 12 }}
                    >
                      *{formik.errors.text}
                    </div>
                  )}
                </p>
              </div>
              {/* <-- Name --> */}
              <div className="group__input">
                <input
                  onChange={(e) => {
                    if (e.target.value.length <= 60) {
                      formik.handleChange(e);
                    }
                  }}
                  name="name"
                  id="name"
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="error" style={{ color: "red", fontSize: 12 }}>
                    *{formik.errors.name}
                  </div>
                )}
              </div>
              {/* <-- Company Name --> */}
              <div className="group__input">
                <input
                  onChange={(e) => {
                    if (e.target.value.length <= 60) {
                      formik.handleChange(e);
                    }
                  }}
                  name="companyName"
                  id="companyName"
                  value={formik.values.companyName}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <div className="error" style={{ color: "red", fontSize: 12 }}>
                    *{formik.errors.companyName}
                  </div>
                )}
              </div>
              {/* <-- Permit --> */}
              <div className="group__input">
                {/* <input type="text" value="Viewer" /> */}
                <select
                  onChange={(e) => setPermit(e.target.value)}
                  value={permit}
                  style={{
                    width: "100%",
                    height: 40,
                    borderRadius: 5,
                    border: "1px solid #979797",
                    paddingLeft: 10,
                  }}
                >
                  <option value="Viewer">Viewer</option>
                  {!isAdmin && <option value="Co-founder">Co-founder</option>}
                </select>
              </div>
              {/* <-- Email Address --> */}
              <div className="group__input">
                <input
                  onChange={(e) => {
                    if (e.target.value.length <= 40) {
                      formik.handleChange(e);
                    }
                  }}
                  name="emailAddress"
                  id="emailAddress"
                  value={formik.values.emailAddress}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.emailAddress && formik.errors.emailAddress && (
                  <div className="error" style={{ color: "red", fontSize: 12 }}>
                    *{formik.errors.emailAddress}
                  </div>
                )}
              </div>
              {/* <-- Phone Number --> */}
              <div
                className="group__input"
                style={{
                  width: "100%",
                  border: "1px solid grey",
                  borderRadius: "5px",
                  padding: "2px",
                }}
              >
                <div style={{ display: "flex", height: 37 }}>
                  {/* Select country */}
                  <div
                    style={{ height: 37 }}
                    className="relative"
                    onClick={handleSectionClick}
                  >
                    <select
                      style={{ width: 37, height: 37 }}
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      className="block appearance-none w-full   py-2 px-3 pr-8 rounded leading-tight focus:outline-none "
                    >
                      {filteredCountries.map((c) => {
                        const country = parseCountry(c);
                        return (
                          <option key={country.iso2} value={country.iso2}>
                            {country.name}
                          </option>
                        );
                      })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center p-2 text-gray-700">
                      <FlagEmoji iso2={country} />
                      {click ? (
                        <img
                          style={{ width: 8, height: 8, marginLeft: 3 }}
                          alt="upTriangle"
                          src={projectImages.upTriangle}
                        />
                      ) : (
                        <img
                          style={{ width: 8, height: 8, marginLeft: 3 }}
                          alt="downTriangle"
                          src={projectImages.downTriangle}
                        />
                      )}
                    </div>
                  </div>
                  {/* Phone input */}
                  <input
                    type="tel"
                    placeholder="Phone number"
                    value={formattedPhone}
                    onChange={handlePhoneValueChange}
                    ref={inputRef}
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      marginLeft: 5,
                    }}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div
                      className="error"
                      style={{ color: "red", fontSize: 12 }}
                    >
                      *{formik.errors.phone}
                    </div>
                  )}
                </div>
              </div>
              {/* <-- Data Access --> */}
              <div className="group__checkbox__modal">
                {!isAdmin && (
                  <label className="container__check">
                    <input
                      type="checkbox"
                      checked={dataAccess}
                      onChange={handleDataAccessChange}
                      disabled={permit === "Co-founder"} // Вимкнути, якщо permit === "Co-founder"
                    />
                    <span className="checkmark">{dataAccess && "✓"}</span>
                    Data Room Access
                  </label>
                )}
              </div>
              {/* <-- Submit --> */}
              <div className="group__submit">
                <button type="submit">
                  {sending ? "Submitting..." : "Send"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareStartup;
