/**
 * File name: [ValueSlider.jsx]
 * Description: [An element for the "Filter.jsx" component that iterates over the value of the selected range of the "raising" parameter]
 *
 */

import React from "react";
import Slider from "@mui/material/Slider";

// Props from the "Filter.jsx" component
export const ValueSlider = ({ handleChange, rasingValue }) => {
  const formattedRasingValue = (rasingValue) => {
    const minValue = rasingValue[0];
    const maxValue = rasingValue[1];

    const formatCurrency = (value) => {
      if (value === 0) {
        return "$0";
      } else if (value === 20) {
        return "$250,000";
      } else if (value === 40) {
        return "$500,000";
      } else if (value === 60) {
        return "$1M";
      } else if (value === 80) {
        return "$2M";
      } else if (value === 100) {
        return "$5M or more";
      }
      return `${(value / 100) * 500000 + 100000}`;
    };

    return `${formatCurrency(minValue)} - ${formatCurrency(maxValue)}`;
  };

  return (
    <>
      <div className="wrapper__filter">
        <p>Amount Raising</p>
        <div className="content__filter">
          <div>{formattedRasingValue(rasingValue)}</div>
          <Slider
            value={rasingValue}
            step={20}
            marks
            track={false}
            onChange={handleChange}
            valueLabelDisplay="off"
          />
        </div>
      </div>
    </>
  );
};
