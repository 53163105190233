import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setUser } from "../slices/userSlice";

const fetchUser = createAsyncThunk(
  "user/fetch",
  async ({ email, name, picture }, { dispatch }) => {
    try {
      // Sending data to the server
      const res = await axios.post(process.env.REACT_APP_SERVER_AUTH_URL, {
        email,
        name,
        picture,
      });

      // Save user data to local storage
      localStorage.setItem("userToken", JSON.stringify(res.data.token));
      localStorage.setItem("userData", JSON.stringify(res.data.user));

      // Update user state in Redux store
      dispatch(setUser(res.data.user));

      return res.data.user;
    } catch (error) {
      console.error("Error sending data to the server:", error);
      // TO DO
      // Add error handling when sending data to the server
    }
  }
);

export { fetchUser };
