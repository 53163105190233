import React from "react";
import FileViewer from "react-file-viewer";
import "./FileViewerModal.css";

// Modal component for viewing files such as images, videos, etc.
const FileViewerModal = ({
  handleCloseViewer, // function to close the viewer when called
  fileType, // type of the file being viewed (e.g., 'png', 'pdf')
  selectedImage, // path or URL to the file being displayed
  CustomErrorComponent, // custom component to display in case of an error
  handleError, // function to handle any errors that occur while viewing the file
}) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "1000",
      }}
      onClick={handleCloseViewer} // Closing a modal window when you click on the background
    >
      {/* Absolute positioning for the button */}
      <button
        style={{
          position: "absolute", // Absolute positioning inside a fixed container
          top: "1rem",
          left: "1rem",
          fontWeight: 800,
          color: "white",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
        className="absolute top-4 left-4 text-white text-2xl leading-none z-50"
        onClick={handleCloseViewer}
      >
        &times; {/* HTML entity for the "X" character */}
      </button>
      {/* Container for the file viewer */}
      <div
        className="custom-file-viewer-modal"
        onClick={(e) => e.stopPropagation()} // Stop the propagation of a click event
      >
        {/* File viewer component to display the file */}
        <FileViewer
          fileType={fileType}
          filePath={selectedImage}
          errorComponent={CustomErrorComponent}
          onError={handleError}
        />
      </div>
    </div>
  );
};

export default FileViewerModal;
