import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { StreamChat } from "stream-chat";
import ReactToastify from "../hooks/useReactToastify";
import AlertContainer from "../components/AlertContainer";
import axios from "axios";

const ChatContext = createContext();

export const useChatCustomContext = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const token = JSON.parse(localStorage.getItem("userToken"));

  const [client, setClient] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [unreadChannels, setUnreadChannels] = useState([]);
  const [userHasChannels, setUserHasChannels] = useState(false);

  const { chatToken, name, picture, email } = useSelector(
    (state) => state.user.data || {}
  );

  // GET USER ID
  const myId = email?.replace(/\./g, "_");

  //////////////////////////////////////////////////////////////////////////////////////////  START
  // Connect to Stream Chat and get information about unread messages

  useEffect(() => {
    if (myId) {
      const connectToStreamChat = async () => {
        try {
          const client = StreamChat.getInstance("tp7kwy3k8ses");
          await client.connectUser(
            { id: myId, name, image: picture },
            chatToken
          );
          setClient(client);

          const user = await client.connectUser({ id: myId }, chatToken);

          setUnreadMessages(user.me.total_unread_count);
        } catch (error) {
          // console.error("Failed to connect to Stream Chat:", error);
        }
      };

      connectToStreamChat();
    }
  }, [myId, name, picture, chatToken]);

  //////////////////////////////////////////////////////////////////////////////////////////  END

  //////////////////////////////////////////////////////////////////////////////////////////  START
  // Function to query channels with unread messages and create push notification

  const queryChannelsWithUnreadMessages = async (showNotification) => {
    if (client) {
      const filter = { members: { $in: [myId ?? ""] } };
      const ChannelsResponse = await client.queryChannels(filter);

      if (ChannelsResponse.length !== 0) {
        setUserHasChannels(true);
      }

      // Filter channels with unread messages
      const channelsWithUnreadMessages = ChannelsResponse.filter(
        (channel) => channel.state.unreadCount > 0
      );

      // Find the latest unread message in all the channels
      let latestMessages = channelsWithUnreadMessages.map((channel) => {
        return channel.state.messages
          .filter((message) => !message.user?.me)
          .reduce((latestMessage, currentMessage) => {
            if (
              !latestMessage ||
              currentMessage.created_at > latestMessage.created_at
            ) {
              return currentMessage;
            }
            return latestMessage;
          }, null);
      });

      // Show the latest unread message
      if (latestMessages[0] && showNotification) {
        const { user, text } = latestMessages[0];
        const truncatedText =
          text.length > 17 ? `${text.substring(0, 18)}...` : text;
        ReactToastify(
          "info",
          <div>
            {user.name == undefined
              ? `New message received:`
              : user.name + " sent:"}
            <br />
            {truncatedText}
          </div>
        );
      }
      setUnreadChannels(channelsWithUnreadMessages);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////  END

  //////////////////////////////////////////////////////////////////////////////////////////  START
  // Track changes in the unreadMessagesCount value

  useEffect(() => {
    // Update the page header when the number of unread messages changes
    document.title =
      unreadMessages === 0 ? `One Metric` : `(${unreadMessages}) One Metric`;

    return () => {
      document.title = "One Metric";
    };
  }, [unreadMessages]);

  //////////////////////////////////////////////////////////////////////////////////////////  END

  //////////////////////////////////////////////////////////////////////////////////////////  START
  // Update information and show notification * // Processing new messages received in the channel
  // Divide users into those who are online and those who are offline and sent request to server

  // State for storing the previous value of total_unread_count
  const prevUnreadCountRef = useRef(0);

  // Processing new messages received in the channel
  const handleNewMessage = async (event) => {
    // Get a new message from the event object
    const channelID = event.message.cid.split(":")[1];

    // Get information about the users present in this channelі
    const channel = client.channel("messaging", channelID);
    const members = channel.state.members;
    const memberArray = Object.values(members);

    const onlineUsers = [];
    const offlineUsers = [];

    // Divide users into those who are online and those who are offline
    for (const member of memberArray) {
      if (member?.user?.online) {
        onlineUsers.push(member.user);
      } else {
        offlineUsers.push(member.user);
      }
    }

    // Function for sending a request to the server
    const sendRequestToServer = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/users/sent-notification`,
          {
            offlineUsers,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.error("Error sending request to server:", error);
      }
    };

    // Call a function to send a request to the server after processing memberArray
    await sendRequestToServer();
  };

  //////////////////////////////////////////////////////////////////////////////////////////  END

  //////////////////////////////////////////////////////////////////////////////////////////  START
  // Tracking changes

  useEffect(() => {
    const handleUnreadCount = (event) => {
      if (event.total_unread_count !== undefined) {
        // An additional variable to track whether the operation has already been executed
        let isOperationExecuted = false;
        if (
          event.total_unread_count > prevUnreadCountRef.current &&
          (prevUnreadCountRef.current !== 0 || isOperationExecuted)
        ) {
          setUnreadMessages(event.total_unread_count);
          queryChannelsWithUnreadMessages(true);

          // Mark the operation as completed
          isOperationExecuted = true;

          if (prevUnreadCountRef.current === 0) {
            // Reset isOperationExecuted to false when the condition is not met
            isOperationExecuted = false;
          }
        }
        setUnreadMessages(event.total_unread_count);
        // Update the previous value of total_unread_count using ref
        prevUnreadCountRef.current = event.total_unread_count;
      }
    };

    if (client) {
      client.on("message.new", handleNewMessage);
      client.on(handleUnreadCount);
    }

    return () => {
      if (client) {
        client.off(handleUnreadCount);
      }
    };
  }, [client /*, queryChannelsWithUnreadMessages*/]); // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    queryChannelsWithUnreadMessages(false);
  }, [client]);

  return (
    <ChatContext.Provider
      value={{ client, unreadMessages, unreadChannels, userHasChannels }}
    >
      <AlertContainer />
      {children}
    </ChatContext.Provider>
  );
};
