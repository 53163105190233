import { createSlice } from "@reduxjs/toolkit";

const startupsSlice = createSlice({
  name: "startups",
  initialState: {
    isLoading: false,
    data: [],
    error: null,
  },
  reducers: {
    setStartups: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearStartups: (state) => {
      state.data = [];
    },
  },
});

export const { setStartups, setLoading, setError, clearStartups } =
  startupsSlice.actions;

export const selectStartup = (state) => state.startup.data;

export default startupsSlice.reducer;
