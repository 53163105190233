/**
 * File name: [DataRoom.jsx]
 * Description: [A component that runs in the "StartupSreen.jsx" component. Allows you to interact with the file system]
 *
 */

import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useContextMenu } from "react-contexify";
import ReactToastify from "../hooks/useReactToastify";
import projectImages from "../style/projectImages";
import Loader from "./Loader";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "react-contexify/dist/ReactContexify.css";
import ReactContexifyMenu from "./DataRoom/ReactContexifyMenu";
import FileViewerModal from "./DataRoom/FileViewerModal";
import CreateFolderModal from "./DataRoom/CreateFolderModal";
import { useSelector } from "react-redux";
import FilesListComponent from "./DataRoom/FilesListComponent";
import FoldersListComponent from "./DataRoom/FoldersListComponent";
import useEscKeyListener from "../hooks/useEscKeyListener";
import DataRoomAccessSetupComponent from "./DataRoom/DataRoomAccessSetupComponent";

const MENU_ID = "menu-id";

const DataRoom = (props) => {
  const token = JSON.parse(localStorage.getItem("userToken"));

  // Startup ID
  const id = props.id;
  const startupName = props.startupTitle;

  const [folders, setFolders] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [mainFolderId, setMainFolderId] = useState(null);
  const [currentFolder, setCurrentFolder] = useState("");
  const [currentFolderPath, setCurrentFolderPath] = useState("");
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(true);

  // Check for data availability
  const filteredFoldersArray = folders.filter((folder) => folder.trim() !== "");

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //  * Direction of working with FOLDERS system *
  //  Select new, rename, delete, preview files

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Switch to the selected folder
  const handleFolderClick = (folderId, folderName) => {
    setMainFolderId(folderId);
    setCurrentFolder(folderName);
    setCurrentFolderPath(currentFolderPath + " > " + folderName);
  };

  // Return to the main folder
  const handleBackToMainFolder = () => {
    setMainFolderId(null);
    setCurrentFolder("");
    setCurrentFolderPath("");
  };

  // Retrieve information about files and folders
  const fetchFileList = useCallback(async () => {
    setIsLoadingSpinner(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/files/startup/${id}/folder${
          currentFolder ? `/${currentFolder}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      // Updating the state
      setFileList(data.files);
      setFolders(data.folders);
    } catch (error) {
      ReactToastify("warn", "Error");
    } finally {
      setIsLoadingSpinner(false);
    }
  }, [id, currentFolder]);

  // Automatic data uploading
  useEffect(() => {
    fetchFileList();
  }, [fetchFileList]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //  * Direction of working with FILES system *
  //

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Delete a file
  const deleteFile = async (itemName) => {
    if (props.accessStatus !== "Full access") {
      return ReactToastify("warn", "You do not have permission");
    }
    setIsLoadingSpinner(true);
    try {
      const currentFolderQueryParam = mainFolderId
        ? `?currentFolder=${encodeURIComponent(currentFolder)}`
        : "";
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/files/startup/${id}/files/${itemName}${currentFolderQueryParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      ReactToastify("success", "File deleted successfully");
      fetchFileList(); // Update the list of files and folders after deletion
    } catch (error) {
      ReactToastify("Error deleting file");
    } finally {
      setIsLoadingSpinner(false);
    }
  };

  // Select a new file
  const handleFileSelect = (event) => {
    if (props.accessStatus !== "Full access") {
      return ReactToastify("warn", "You do not have permission");
    }
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  // Execute handleFileUpload when selectedFile changes
  useEffect(() => {
    if (selectedFile) {
      // Uploading files
      const handleFileUpload = async (startupId) => {
        if (selectedFile) {
          setIsLoadingSpinner(true);
          const formData = new FormData();
          const encodedFileName = encodeURIComponent(selectedFile.name);
          formData.append("file", selectedFile);
          formData.append("encodedFileName", encodedFileName);
          formData.append("startupId", startupId);
          formData.append("currentFolder", currentFolder);

          try {
            const response = await axios.post(
              `${process.env.REACT_APP_SERVER_URL}/files`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.status === 200) {
              ReactToastify("success", "File uploaded successfully");
              setSelectedFile(null);
              fetchFileList();
            }
          } catch (error) {
            ReactToastify("warn", "File is too large. Maximum size 25 MB");
          } finally {
            setIsLoadingSpinner(false);
          }
        }
      };
      handleFileUpload(id);
    }
  }, [selectedFile, id, fetchFileList, currentFolder]);

  // Function for downloading a file
  const downloadFile = async (fileName) => {
    setIsLoadingSpinner(true);
    try {
      const currentFolderQueryParam = mainFolderId
        ? `?currentFolder=${encodeURIComponent(currentFolder)}`
        : "";

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/files/${id}/${fileName}${currentFolderQueryParam}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      ReactToastify("warn", "Failed to download file");
    } finally {
      setIsLoadingSpinner(false);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //  * ALERT SYSTEM *

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const startupList = useSelector((state) => state.startups.data.startups);
  const userDataEmail = useSelector((state) => state.user.data.email);
  const ownerId = startupList.filter((item) => item._id === id)[0]?.createdBy;

  useEffect(() => {
    setIsLoadingSpinner(false);
    if (props.accessStatus !== "Full access") {
      const handleAlertAccessNotification = async () => {
        try {
          await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/startups/alert-email`,
            {
              ownerId,
              userDataEmail,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } catch (error) {
          ReactToastify(
            "warn",
            "An error has been detected in the notification system"
          );
        }
      };
      handleAlertAccessNotification();
    }
  }, [id, startupList, userDataEmail, ownerId]);

  //  Function that creates a notification when a file is opened
  const handleCreateOpenFileRecord = async () => {
    try {
      if (props.accessStatus !== "Full access") {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/users/record-open-file`,
          {
            ownerId,
            startupName: startupName,
            fileName: imageOrFileName,
            accessedByEmail: userDataEmail,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
    } catch {
      ReactToastify("warn", "Error trying to create a notification ");
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //  * react-contexify * PART__1
  //  Logic with right-clicking on files and folders of the modal window and its logic

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [imageOrFileName, setImageOrFileName] = useState(null);
  const [isFolderSelected, setIsFolderSelected] = useState(false); // State to check in <Menu/> (react-contextify)
  const [selectedImage, setSelectedImage] = useState(null); // Selected photo to view
  const [fileType, setFileType] = useState(null); // Type of photo (.png .jpg) you need to display the photo

  // Initializing the context menu
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  // Function for displaying the context menu
  const displayMenu = (e, selectedFolderOrImageName) => {
    // Set the selected file or folder name irrespective of the event
    setImageOrFileName(selectedFolderOrImageName);

    // If the event is present, it means we are handling a right-click event
    if (e) {
      e.preventDefault(); // Prevent standard browser behavior
      // Displaying the context menu
      show({
        event: e,
      });
    }
  };

  // Function for determining the file type
  const determineFileType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    if (["png", "jpg", "jpeg", "gif"].includes(extension)) {
      return "png";
    } else if (extension === "pdf") {
      return "pdf";
    } else {
      return "docx";
    }
  };

  // Function for uploading and viewing a file
  const downloadAndViewFile = async (fileName) => {
    // Additional check
    if (fileName === null) {
      return;
    }
    setIsLoadingSpinner(true);
    try {
      const currentFolderQueryParam = mainFolderId
        ? `?currentFolder=${encodeURIComponent(currentFolder)}`
        : "";

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/files/${id}/${fileName}${currentFolderQueryParam}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Creating a URL for the uploaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileType = determineFileType(fileName);
      // Saving the URL and file type to be viewed
      setSelectedImage(url);
      setFileType(fileType);
      handleCreateOpenFileRecord();
    } catch (error) {
      ReactToastify("warn", "Failed to download file");
    } finally {
      setIsLoadingSpinner(false);
    }
  };

  // Display an error if a file or photo has an error when opened
  const CustomErrorComponent = () => {
    return <div>Error loading file</div>;
  };
  const handleError = (error) => {
    ReactToastify("warn", "Error displaying a file");
  };

  // Close a modal window when viewing a photo or files
  const handleCloseViewer = () => {
    setSelectedImage(null);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //  * react-contexify * PART 2 FILES
  //  Change the name of a file or photo

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [editingFile, setEditingFile] = useState(null);
  const [newFileName, setNewFileName] = useState("");

  // Function of changing the name of the file or photo
  const handleSaveNewFileName = async (fileName) => {
    try {
      if (fileName.length === 0) {
        return ReactToastify(
          "warn",
          "Name must contain at least one character"
        );
      }

      const extension = getFileExtension(editingFile); // Extracting an extension from the current file name
      const newFullName = fileName + extension; // Add an extension to a new file name

      if (fileName + extension === imageOrFileName) {
        return ReactToastify("success", "File renamed successfully");
      }

      // Encoding file names
      const encodedOldFileName = encodeURIComponent(editingFile);
      const encodedNewFileName = encodeURIComponent(newFullName);
      // Generating data for sending
      const payload = {
        startupId: id,
        oldFileName: encodedOldFileName, // Encoded old file name
        newFileName: encodedNewFileName, // Encoded new file name
        currentFolder: currentFolder,
      };
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/files/startup/renameFile`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      ReactToastify("success", "File renamed successfully");
      fetchFileList();
    } catch (error) {
      ReactToastify("warn", "Error renaming a file");
    } finally {
      setEditingFile(null); // Finish editing regardless of the result
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //  * react-contexify * PART 3 FOLDERS
  //  Change name , delete , crete folders

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [createNewFolderName, setCreateNewFolderName] = useState("");
  const [isOpenModalCreateFolder, setIsOpenModalCreateFolder] = useState(null);

  const handleCreateFolder = async () => {
    try {
      if (createNewFolderName.length === 0) {
        return ReactToastify(
          "warn",
          "Name must contain at least one character"
        );
      }
      const folderData = {
        startupId: id,
        folderName: createNewFolderName,
      };
      // Sending a POST request to the server
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/files/createFolder`,
        folderData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Closing the modal window
      setIsOpenModalCreateFolder(false);
      // Clear the input field
      setCreateNewFolderName("");
      fetchFileList();
      ReactToastify("success", "Folder created successfully");
    } catch (error) {
      ReactToastify("warn", "Error during the folder creation process");
    }
  };

  const deleteFolder = async (folderName) => {
    setIsLoadingSpinner(true);
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/files/startup/${id}/folder/${folderName}`;
      await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchFileList();
      ReactToastify("success", "Folder successfully deleted");
    } catch (error) {
      ReactToastify("warn", "Error during the folder deletion process");
    } finally {
      setIsLoadingSpinner(false);
    }
  };

  const renameFolder = async (oldFolderName) => {
    try {
      if (newFileName.length === 0) {
        return ReactToastify(
          "warn",
          "Name must contain at least one character"
        );
      }
      if (oldFolderName === newFileName) {
        return ReactToastify("success", "File renamed successfully");
      }
      // Encoding file names
      const encodedOldFileName = encodeURIComponent(oldFolderName);
      const encodedNewFileName = encodeURIComponent(newFileName);
      // Generating data for sending
      const payload = {
        startupId: id,
        oldFolderName: encodedOldFileName, // Encoded old file name
        newFolderName: encodedNewFileName, // Encoded new file name
      };
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/files/startup/renameFolder`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      ReactToastify("success", "File renamed successfully");
      fetchFileList();
    } catch (error) {
      ReactToastify("warn", "Error renaming a file");
    } finally {
      setEditingFile(null); // Finish editing regardless of the result
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //  * Additional functionality *

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Function for getting a file name without an extension
  const getFileNameWithoutExtension = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    if (lastDotIndex === -1) return fileName; // No extension

    return fileName.substring(0, lastDotIndex);
  };

  // Function for getting the file extension
  const getFileExtension = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    if (lastDotIndex === -1) return ""; // No extension
    return fileName.substring(lastDotIndex); // Including the dot
  };

  const [editingBaseName, setEditingBaseName] = useState(""); //Add a new state for the base name

  // Function that is activated when you start editing the file name
  const handleEditFileName = (fileName) => {
    setEditingFile(fileName);
    setEditingBaseName(getFileNameWithoutExtension(fileName)); // Save the base name
  };

  // Checking the validity of the name
  const handleNameChange = (e) => {
    const invalidChars = /[<>.:"/\\|?*]+/;
    const newName = e.target.value;
    if (!invalidChars.test(newName)) {
      setEditingBaseName(newName);
    } else {
      ReactToastify("warn", "Invalid character in file name.");
    }
  };

  // Function for calculating input width for files and folders
  const calculateWidth = (textLength) => {
    const minWidth = 29; // Minimum width
    const maxWidth = 113; // Maximum width
    const widthPerChar = 20; // Approximate width of one character
    const dynamicWidth = Math.min(
      maxWidth,
      Math.max(minWidth, textLength * widthPerChar)
    );
    return dynamicWidth;
  };

  // DataRoom access setup view state && function
  const [isDataRoomAccessSetupView, setIsDataRoomAccessSetupView] =
    useState(false);
  const handleChangeDataRoomAccessView = () => {
    if (props.accessStatus === "Full access") {
      setIsDataRoomAccessSetupView((prevState) => !prevState);
    } else {
      ReactToastify("warn", "Access denied");
    }
  };

  // Using a hook to close a modal window
  useEscKeyListener(handleCloseViewer);
  useEscKeyListener(setIsOpenModalCreateFolder);

  return (
    <div>
      {isLoadingSpinner && <Loader />}
      {isDataRoomAccessSetupView ? (
        <DataRoomAccessSetupComponent
          id={id}
          handleChangeDataRoomAccessView={handleChangeDataRoomAccessView}
        />
      ) : (
        <>
          {/* react-contexify  modal MENU */}
          <ReactContexifyMenu
            handleEditFileName={handleEditFileName}
            handleFolderClick={handleFolderClick}
            downloadAndViewFile={downloadAndViewFile}
            downloadFile={downloadFile}
            imageOrFileName={imageOrFileName}
            isFolderSelected={isFolderSelected}
            setEditingFile={setEditingFile}
            setNewFileName={setNewFileName}
            deleteFolder={deleteFolder}
            deleteFile={deleteFile}
            accessStatus={props.accessStatus}
            id={id}
          />
          <div className="modal__wrapper">
            {/* Modal window showing the selected photo */}
            {selectedImage && (
              <FileViewerModal
                handleCloseViewer={handleCloseViewer}
                fileType={fileType}
                selectedImage={selectedImage}
                CustomErrorComponent={CustomErrorComponent}
                handleError={handleError}
              />
            )}
            <div className="inner__modal">
              <div className="modal__box data__room">
                <div className="head__">
                  <p
                    className="w-full flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="flex">
                      <span>
                        <img src={projectImages.foot3} alt="foot" />
                      </span>
                      Data Room
                    </div>
                    <div
                      className="flex text-xs mr-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleChangeDataRoomAccessView()}
                    >
                      <span className="flex justify-center items-center">
                        <img
                          className="w-3.5 h-3.5"
                          src={projectImages.setting}
                          alt="foot"
                        />
                      </span>
                      <div>Access setup</div>
                    </div>
                  </p>

                  <Link onClick={props.onClose}>
                    <img src={projectImages.closeSmall} alt="closesmall" />
                  </Link>
                </div>
                <div className="content__">
                  {/* Display current folder path */}
                  <div>
                    {currentFolderPath && (
                      <div style={{ marginBottom: 10, display: "flex" }}>
                        <div
                          className="pathStyle"
                          onClick={handleBackToMainFolder}
                        >
                          Current Folder
                        </div>
                        <div style={{ cursor: "pointer" }}>
                          {currentFolderPath}
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      gridRowGap: 15,
                      paddingRight: 20,
                      paddingLeft: 10,
                    }}
                    className="folder__wrapper"
                  >
                    {/* Additional check for folders && list of folders */}
                    {filteredFoldersArray[1] === "" ? null : (
                      <FoldersListComponent
                        id={id}
                        newFileName={newFileName}
                        setNewFileName={setNewFileName}
                        filteredFoldersArray={filteredFoldersArray}
                        displayMenu={displayMenu}
                        setIsFolderSelected={setIsFolderSelected}
                        editingFile={editingFile}
                        renameFolder={renameFolder}
                        setEditingFile={setEditingFile}
                        handleFolderClick={handleFolderClick}
                      />
                    )}
                    {/* List of files */}
                    <FilesListComponent
                      fileList={fileList}
                      displayMenu={displayMenu}
                      setIsFolderSelected={setIsFolderSelected}
                      handleSaveNewFileName={handleSaveNewFileName}
                      editingBaseName={editingBaseName}
                      editingFile={editingFile}
                      setEditingFile={setEditingFile}
                      getFileExtension={getFileExtension}
                      handleNameChange={handleNameChange}
                      calculateWidth={calculateWidth}
                      downloadAndViewFile={downloadAndViewFile}
                      imageOrFileName={imageOrFileName}
                    />
                    {/* Modal window to add new folders */}
                    {isOpenModalCreateFolder && (
                      <CreateFolderModal
                        setIsOpenModalCreateFolder={setIsOpenModalCreateFolder}
                        setCreateNewFolderName={setCreateNewFolderName}
                        createNewFolderName={createNewFolderName}
                        handleCreateFolder={handleCreateFolder}
                      />
                    )}
                    {/* Add file */}
                    {props.accessStatus === "Full access" && (
                      <div className="add__folder" style={{ height: 153 }}>
                        <span
                          style={{
                            borderRadius: 50,
                            width: 60,
                            height: 60,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: 23,
                          }}
                        >
                          <label htmlFor="FIleInput">
                            <img
                              src={projectImages.addCompanyImg}
                              style={{ width: 25, height: 25 }}
                              alt="addfolder"
                            />
                            <input
                              id="FIleInput"
                              style={{ display: "none" }}
                              name="file_1"
                              type="file"
                              onChange={handleFileSelect}
                            />
                          </label>
                        </span>
                        <div>Add File</div>
                      </div>
                    )}
                    {/* Check if the user can add new folders */}
                    {props.accessStatus === "Full access" && (
                      <div className="add__folder" style={{ height: 153 }}>
                        <span
                          style={{
                            borderRadius: 50,
                            width: 60,
                            height: 60,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: 23,
                          }}
                        >
                          <img
                            src={projectImages.addCompanyImg}
                            style={{ width: 25, height: 25 }}
                            alt="addfolder"
                            onClick={() => setIsOpenModalCreateFolder(true)}
                          />
                        </span>
                        <div>Add Folder</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DataRoom;
