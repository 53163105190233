import React from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import "./MessageInput.css";
import ChatDropDownImages from "./styles/Images";

// Props with ChatMainFile.jsx
const MessageInput = ({
  handleInputKeyPress,
  setShowEmojiPicker,
  setSelectedEmojis,
  showEmojiPicker,
  selectedEmojis,
  setInputValue,
  inputValue,
  handleFileSelect,
  setSelectedPhotos,
  selectedPhotos,
  screenWidth,
  isChatPage,
}) => {
  const handleEmojiSelect = (emoji) => {
    // Update `selectedEmojis` with selected emoji
    setSelectedEmojis([emoji]);

    // Update the input string with the selected emoji
    const emojiText = emoji.native;
    setInputValue((prevInputValue) => prevInputValue + emojiText);
  };
  return (
    <div
      className={`${
        isChatPage ? `chat-container__isChatPageMod` : `chat-container`
      } ${screenWidth < 800 ? "chat-container-mobile" : ""}`}
    >
      {screenWidth > 800 && showEmojiPicker && (
        <div className="picker-container">
          <div
            style={{ display: "flex", width: "105%", justifyContent: "end" }}
          >
            <button
              style={{
                width: 22,
                height: 22,
                backgroundColor: "white",
                color: "black",
                boxShadow: "rgb(66, 68, 90) 1px 2px 9px -1px",
                cursor: "pointer",
                fontWeight: 700,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 50,
              }}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            >
              X
            </button>
          </div>
          <Picker data={data} perLine={8} onEmojiSelect={handleEmojiSelect} />
        </div>
      )}
      <button className="emoji-button">
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileSelect}
          onKeyPress={handleInputKeyPress}
        />
        <label htmlFor="fileInput" className="emoji-button">
          <img
            style={{ width: 20, height: 20, marginRight: 10, marginLeft: 10 }}
            src={ChatDropDownImages.addItems}
          />
        </label>
      </button>
      {selectedPhotos && (
        <div style={{ marginRight: 5, display: "flex" }}>
          <img style={{ width: 25, height: 25 }} src={selectedPhotos} />
          <button
            onClick={() => setSelectedPhotos(null)}
            style={{
              fontSize: 10,
              display: "flex",
              position: "relative",
              bottom: 7,
              fontWeight: 700,
            }}
          >
            x
          </button>
        </div>
      )}
      <input
        placeholder="Message..."
        className="message-input"
        id="messageInput"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleInputKeyPress} // Adding an event handler for an input
      />
      {screenWidth > 800 && (
        <button
          className="emoji-button"
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
        >
          <img
            style={{ width: 19, height: 19, marginRight: 10 }}
            src={ChatDropDownImages.smile}
          />
        </button>
      )}
    </div>
  );
};

export default MessageInput;
