// DATA for AddCompany.jsx / StartupScreen.jsx

export const placeholdersTitle = [
  "UNIQUE HARDWARE",
  "BLOCKCHAIN USER PRIVACY",
  "PATENTED DAMAGE PREVENTION",
  "UNIQUE SIZE FACTOR",
];

export const placeholdersText = [
  "Patent pending unique Tracker with military grade accuracy, global coverage unique lifespan.",
  "All tracked assets can only be revealed by the user on. the blockchain, and the user alone. Encrypted and custodial and owner only-data",
  "Patent filed for the detection of fire, aggression and flood with unique triggering technology.",
  "With a V1.0 smaller than a coin, Tresor Track. aims to become small enough to fit inside a luxury watch.",
];

export const dataDescription =
  "Summary: With a theft of luxury good every 5 seconds in the US alone, Tresor Track is building the future of asset protection with the world’s most accurate and most secure technology, Tresor Track is smaller than a coin and has an autonomy of up to 4 years on a single charge. With LOIs for 10,000 units already Tresor Track will shift the balance in asset protection and tracking.";

// FOR STARTUPSCREEN.JSX + ADDCOMPANY.JSX
export const suggestedCompetitorList = [
  "Google Nest",
  "Amazon Echo",
  "Samsung SmartThings",
  "Philips Hue",
  "Ethereum",
  "Ripple",
  "Hyperledger",
  "Cardano",
  "Apple",
  "Microsoft",
  "Google",
  "Intel",
  "Monsanto",
  "John Deere",
  "AeroFarms",
  "CropX",
  "PayPal",
  "Square",
  "Stripe",
  "Robinhood",
  "Johnson & Johnson",
  "Pfizer",
  "Medtronic",
  "Cerner",
  "Amazon",
  "Alibaba",
  "eBay",
  "Walmart",
  "Salesforce",
  "Microsoft Dynamics 365",
  "Slack",
  "Dropbox",
  "Google AI",
  "IBM Watson",
  "Microsoft AI",
  "OpenAI",
  "Oculus (owned by Facebook)",
  "HTC Vive",
  "Magic Leap",
  "Microsoft HoloLens",
  "Tesla",
  "First Solar",
  "SunPower",
  "Bloom Energy",
  "Coursera",
  "Udemy",
  "Khan Academy",
  "Udacity",
  "Blue Apron",
  "HelloFresh",
  "Instacart",
  "Beyond Meat",
  "Uber",
  "Lyft",
  "Lime",
  "Bird",
  "Palo Alto Networks",
  "Fortinet",
  "Check Point Software",
  "CrowdStrike",
  "Boston Dynamics",
  "ABB Robotics",
  "Universal Robots",
  "SoftBank Robotics",
  "Airbnb",
  "Booking.com",
  "Expedia",
  "TripAdvisor",
  "LinkedIn Talent Solutions",
  "Workday",
  "ADP",
  "BambooHR",
  "Lemonade",
  "Oscar Health",
  "Root Insurance",
  "Metromile",
];
// FOR STARTUPSCREEN.JSX + ADDCOMPANY.JSX
export const suggestedTagsList = [
  "GPS",
  "Supply Chain and Logistics",
  "Sustainability",
  "Artificial Intelligence (AI)",
  "Machine Learning",
  "Internet of Things (IoT)",
  "Blockchain",
  "Data Analytics",
  "Cybersecurity",
  "Mobile App",
  "Virtual Reality (VR)",
  "Augmented Reality (AR)",
  "Personalization",
  "E-commerce",
  "Social Media Marketing",
  "User Experience (UX)",
  "On-demand",
  "Automation",
  "Remote Work",
  "Health and Wellness",
  "Sustainable Fashion",
  "Renewable Energy",
  "EdTech",
  "FoodTech",
  "FinTech",
  "InsurTech",
  "Robotics",
  "CleanTech",
  "Smart Home",
  "Travel and Tourism",
  "Hospitality",
  "Fitness and Exercise",
  "Gaming",
  "Biotechnology",
  "Nanotechnology",
  "3D Printing",
  "Customer Relationship Management (CRM)",
  "Payment Solutions",
  "Big Data",
  "Augmented Intelligence",
  "Personal Finance",
  "HR and Talent Management",
  "Marketplaces",
  "Personal Development",
  "Social Impact",
  "Remote Monitoring",
  "Precision Agriculture",
  "HealthTech",
  "Telemedicine",
];
// FOR STARTUPSCREEN.JSX + ADDCOMPANY.JSX
export const suggestedIndustrysList = [
  "IoT",
  "Blockchain",
  "Agritech",
  "Fintech",
  "Health",
  "E-commerce",
  "SaaS",
  "AI",
  "VR & AR",
  "CleanTech",
  "EdTech",
  "FoodTech",
  "Mobility",
  "Cybersecurity",
  "Robotics",
  "TravelTech",
  "HRTech",
  "InsurTech",
];
