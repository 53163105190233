import close116 from "./ImagesHub/close-116.png";
import greyLoop from "./ImagesHub/search-grey.png";
import greyBackArrow from "./ImagesHub/arrows-grey-back.png";
import smile from "./ImagesHub/smile.png";
import plus from "./ImagesHub/more.png";
import addItems from "./ImagesHub/add-item.png";

const ChatDropDownImages = {
  plus,
  smile,
  close116,
  addItems,
  greyLoop,
  greyBackArrow,
};

export default ChatDropDownImages;
