import React, { createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotifications } from "../store/thunk/fetchNotifications";

// Creating a context for Notification Records
const NotificationRecordContext = createContext();

/**
 * NotificationRecordProvider is a component that wraps its children in NotificationRecordContext.Provider.
 * It's responsible for fetching and providing notification data dynamically.
 *
 * - It uses the Redux `useDispatch` hook to dispatch actions.
 * - The `useSelector` hook fetches `accessRecords` from the notifications slice of the Redux store.
 * - It listens for changes in the user's email and dispatches an action to fetch notifications accordingly.
 * - The useEffect hook ensures that notifications are updated dynamically whenever the user's email changes.
 */
export const NotificationRecordProvider = ({ children }) => {
  const dispatch = useDispatch();

  // Selecting access records from notifications slice of Redux store
  const { accessRecords } = useSelector(
    (state) => state.notificationsSlice || {}
  );

  // Selecting user email from user data in Redux store
  const { email: userEmail } = useSelector((state) => state.user.data || {});

  // Effect hook to fetch notifications when userEmail changes
  useEffect(() => {
    // Dispatch fetchNotifications action if userEmail exists
    if (userEmail) {
      dispatch(fetchNotifications({ userEmail, dispatch }));
    }
  }, [userEmail, dispatch]);

  // Providing accessRecords to child components through context
  return (
    <NotificationRecordContext.Provider value={{ accessRecords }}>
      {children}
    </NotificationRecordContext.Provider>
  );
};

/**
 * useNotificationRecords is a custom hook for consuming the NotificationRecordContext.
 * It allows components to access the notification records provided by NotificationRecordProvider.
 */
export const useNotificationRecords = () =>
  useContext(NotificationRecordContext);
