import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../store/thunk/fetchUser";
import ReactToastify from "./useReactToastify";
import useEscKeyListener from "./useEscKeyListener";

/**
 * This custom hook useEmailAdditionModal manages the modal state for adding an additional email,
 * handles the addition of the email via an API request, and listens for an ESC key event to close the modal.
 *
 */

const useEmailAdditionModal = (token) => {
  // State for the startup link and email address from the URL
  const [companyID, setCompanyID] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  // State to control the visibility of the modal
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  // Extracting user data from the redux store
  const { _id, email, name, picture } = useSelector(
    (state) => state.user.data || {}
  );

  // On hook mount, retrieve any pending data from localStorage
  useEffect(() => {
    const pendingData = localStorage.getItem("pendingData");
    if (pendingData) {
      const decodedData = atob(pendingData); // Decode Base64 URL data
      const [companyID, email] = decodedData.split("|"); // Extract companyID and email
      setCompanyID(companyID); // Set startup companyID state
      setEmailAddress(email); // Set email address state
      setShowModal(true); // Show the modal
    }
  }, []);

  // Auto-close the modal if companyID and email address are set
  useEffect(() => {
    if (companyID && emailAddress) {
      setShowModal(false);
    }
  }, []);

  // Handle the modal cancel action
  const handleCancel = () => {
    setShowModal(!showModal); // Toggle the modal visibility
    localStorage.removeItem("pendingData"); // Clear pending data from localStorage
  };

  // Hook to listen for the ESC key and close the modal
  useEscKeyListener(handleCancel);

  // Handle the action of adding an email
  useEffect(() => {
    if (companyID && emailAddress) {
      const handleEmailAddition = async () => {
        try {
          // Make a PUT request to the server to add an email address
          const response = await axios.put(
            `${process.env.REACT_APP_SERVER_URL}/users/addEmail`,
            { _id, emailAddress },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          dispatch(fetchUser({ email, name, picture }));
          // Show a success toast notification
          ReactToastify("success", `${response.data.message}`);
        } catch {
          // Show a warning toast notification on error
          ReactToastify("warn", "Error adding an additional email address");
        }
      };
      handleEmailAddition();
    }
  }, [companyID, emailAddress]);

  return {
    showModal, // Whether the modal is currently shown
    emailAddress, // Current email address state
    handleCancel, // Function to handle the cancellation action
    companyID,
  };
};

export default useEmailAdditionModal;
