import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import { ChatProvider } from "./context/ChatProvider";
import { NotificationRecordProvider } from "./context/NotificationRecordContext";
import ErrorBoundary from "./components/ErrorBoundary";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://b0207b03bd0aee041181d61b48b82629@o4506893366329344.ingest.us.sentry.io/4506893367902208",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api.somesite\.online/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <ChatProvider>
      <NotificationRecordProvider>
        <App />
      </NotificationRecordProvider>
    </ChatProvider>
  </Provider>
);
