import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import { fetchStartups } from "../store/thunk/fetchStartups";

const Distributor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startupsBox, setStartupsBox] = useState([null]); // State to store startups, initialized with [null]

  // Selector to get the user's _id from the Redux store
  const { _id } = useSelector((state) => state.user.data || {});

  // useEffect to fetch startups on component mount
  useEffect(() => {
    async function fetchData() {
      // Async function to fetch startups
      const response = await dispatch(fetchStartups({ _id: _id }));
      setStartupsBox(response?.payload?.startups); // Update the startupsBox state with the fetched data
    }
    fetchData();
  }, [_id, dispatch]);

  // useEffect to handle navigation logic based on startupsBox state
  useEffect(() => {
    if (startupsBox?.[0] !== null) {
      // Check if startupsBox is not just [null]
      if (startupsBox?.length === 0) {
        navigate(`/addCompany`); // Redirect to addCompany if startupsBox is empty
      } else {
        // Find a startup created by the current user
        const redirectStartup = startupsBox?.find(
          (startup) => startup?.createdBy === _id
        );
        if (redirectStartup) {
          // Navigate to the startup's page based on urlAddress or _id
          navigate(
            `/startups/${redirectStartup.urlAddress || redirectStartup._id}`
          );
        } else {
          // If no startup is found, navigate to addCompany
          navigate(`/addCompany`);
        }
      }
    }
  }, [_id, navigate, startupsBox]);

  return (
    <>
      <Navbar />
      {true && <Loader />}
      {/* Display the Loader component constantly while startups are being calculated */}
    </>
  );
};

export default Distributor;
