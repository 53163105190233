import { createSlice } from "@reduxjs/toolkit";

const favoriteStartups = createSlice({
  name: "favoriteStartups",
  initialState: {
    isLoading: false,
    data: [],
    error: null,
  },
  reducers: {
    setStartups: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setStartups, setLoading, setError } = favoriteStartups.actions;

export const selectFavoriteStartups = (state) => state.startup.data;

export default favoriteStartups.reducer;
