import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import projectImages from "../style/projectImages";
import "./Notification.css";
import ChatDropDownImages from "./ChatDropDown/styles/Images";
import { useSelector } from "react-redux";

const Notification = ({
  handleChangeStateNotification,
  unreadMessages,
  unreadChannels,
}) => {
  const { accessRecords } = useSelector(
    (state) => state.notificationsSlice || {}
  );
  const filteredNotifications = accessRecords?.filter((record) => !record.read);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const [isVisible, setIsVisible] = useState(true);
  const bottomElementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // If the element is visible, set isVisible to false
      setIsVisible(!entries[0].isIntersecting);
    });

    observer.observe(bottomElementRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div className="notification-container absolute right-40 top-20">
      {/* Up conteiner */}
      <div className="up-container">
        <div style={{ display: "flex" }}>
          <div className="notification-title">Notifications</div>
          <Link
            to="/notification-page"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 27,
              height: 27,
            }}
          >
            <img
              style={{ width: 16, height: 16 }}
              src={projectImages.resize}
              alt="closesmall"
            />
          </Link>
        </div>

        <button
          className="close-button"
          onClick={() => handleChangeStateNotification()}
        >
          <img
            style={{ width: 12, height: 12 }}
            src={ChatDropDownImages.close116}
            alt="closesmall"
          />
        </button>
      </div>
      {/* Middle line */}
      <div className="middle-line-container">
        <div className="middle-line"></div>
      </div>
      {/* Down container */}
      <div className="down-container">
        {/* Display the total unread count */}
        {unreadMessages > 0 ? (
          <>
            {unreadChannels.map((channel) => {
              // Find the latest unread message in the channel
              const latestUnreadMessage = channel.state.messages
                .filter((message) => !message.user?.me) // Filter out messages sent by the current user
                .reduce((latestMessage, currentMessage) => {
                  if (
                    !latestMessage ||
                    currentMessage.created_at > latestMessage.created_at
                  ) {
                    return currentMessage;
                  }
                  return latestMessage;
                }, null);

              if (latestUnreadMessage) {
                return (
                  <Link
                    to="/chat"
                    key={channel.id}
                    className="notification  hover:bg-gray-100  rounded-md transition duration-150 ease-in-out"
                  >
                    {/* Left side */}
                    <div
                      style={{
                        width: "15%",
                        height: 120,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: 25,
                          height: 25,
                          marginTop: 10,
                          borderRadius: 50,
                        }}
                      >
                        <img
                          src={projectImages.notificationWhite}
                          alt="notification"
                        />
                      </div>
                    </div>
                    {/* Right side */}
                    <div style={{ width: "85%", height: 130 }}>
                      <div style={{ height: 50, marginTop: 10 }}>
                        <div style={{ display: "flex" }}>
                          {latestUnreadMessage.user.name === undefined ? (
                            <>
                              <div>New message received</div>
                            </>
                          ) : (
                            <>
                              <div
                                style={{ fontWeight: 700, marginRight: 10 }}
                              >{`${
                                latestUnreadMessage.user.name.length <= 17
                                  ? latestUnreadMessage.user.name
                                  : latestUnreadMessage.user.name.substring(
                                      0,
                                      18
                                    ) + "..."
                              }`}</div>
                              <div>added a new message</div>
                            </>
                          )}
                        </div>

                        <div style={{ display: "flex" }}>
                          <div>to channel</div>
                          <div style={{ fontWeight: 700, marginLeft: 10 }}>
                            {`${
                              channel.data.name.length < 32
                                ? channel.data.name
                                : channel.data.name.substring(0, 18) + "..."
                            }`}
                          </div>
                        </div>

                        <div style={{ display: "flex" }}>
                          <div>new message: </div>
                          <div style={{ fontWeight: 700, marginLeft: 10 }}>{`${
                            latestUnreadMessage.text.length <= 9
                              ? latestUnreadMessage.text
                              : latestUnreadMessage.text.substring(0, 10)
                          } `}</div>
                          <div style={{ marginLeft: 5, color: "blue" }}>
                            ...read more
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              }
              return null;
            })}
          </>
        ) : null}
        {filteredNotifications?.map((record) => {
          return (
            <Link
              to={"/notification-page"}
              className="flex items-center hover:bg-gray-100 p-2 rounded-md transition duration-150 ease-in-out"
            >
              {/* Left side */}
              <div className="flex justify-center w-1/6">
                <img
                  className="w-6 h-6"
                  src={projectImages.notificationWhite}
                  alt="Notification icon"
                />
              </div>
              {/* Right side */}
              <div
                className="flex-1"
                style={{ marginTop: record.isDataRoomOpen ? 8 : 0 }}
              >
                <div className="font-medium text-sm md:text-base">
                  {record.isDataRoomOpen
                    ? "Data room opening detected"
                    : "New file opening detected"}
                </div>
                <div className="text-xs md:text-sm text-gray-600">
                  {record.isDataRoomOpen ? "Data room" : "File"} was opened by
                  the user {record.accessedBy} at{" "}
                  {formatDate(record.accessedAt)}
                </div>
                <div
                  className="text-xs md:text-sm text-gray-800"
                  style={{ height: record.isDataRoomOpen ? 20 : " " }}
                >
                  {record.isDataRoomOpen ? null : (
                    <>File name: {record.fileName}</>
                  )}
                </div>
              </div>
            </Link>
          );
        })}
        <div ref={bottomElementRef}></div>
      </div>
      {isVisible && (
        <div className="text-center mt-2 text-sm text-blue-600 cursor-pointer hover:underline">
          Scroll to see more notifications
        </div>
      )}
    </div>
  );
};

export default Notification;
