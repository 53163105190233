import React, { useState, useEffect } from "react";
import {
  dataDescription,
  suggestedCompetitorList,
  suggestedIndustrysList,
  suggestedTagsList,
} from "../data/data";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UseFetchStartups from "../hooks/useFetchStartups";
import ReactToastify from "../hooks/useReactToastify";
import projectImages from "../style/projectImages";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";

export const imageTeam = [
  projectImages.team1Img,
  projectImages.team2Img,
  projectImages.team3Img,
  projectImages.team1Img,
  projectImages.team2Img,
  projectImages.team3Img,
];

const AddCompany = () => {
  const token = JSON.parse(localStorage.getItem("userToken"));

  // Required to solve visual design problems when using the Safari browser
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const navigate = useNavigate();
  // Photo type : "logo" , "main" etc.
  const [imageType, setImageType] = useState("");
  // It is necessary to distinguish on what principle the startup was saved. By standard, using the "Save" button, so the initial state = true
  const [pressedSave, setPressedSave] = useState(true);
  const [startupUrlAddress, setStartupUrlAddress] = useState("");
  // Explanation that this component "AddCompany"
  const [isAddCompany, setIsAddCompany] = useState(true);
  // Opens / closes the DataRoom popup
  const [showInitialDataRoom, setShowInitialDataRoom] = useState(false);
  // Hook to retrieve data from the server and save it to local storage
  UseFetchStartups();
  // The state of the modal window
  const [openSelectPhoto, setOpenSelectPhoto] = useState(false);
  // Photo selected or not selected status
  const [selectPhoto, setSelectPhoto] = useState(null);
  // Verification or form sent
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Loading spinner status
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  // USER ID
  const { _id } = useSelector((state) => state.user.data || {});
  // STARTUP ID
  const [startupId, setStartupId] = useState(null);
  // List of files to send to the server
  const [fileList, setFileList] = useState([]);
  const [folders, setFolders] = useState([
    "Customers",
    "Financial",
    "Legal",
    "Patents",
    "Team",
  ]);
  // URL that will be received after the startup is created
  const [startupURL, setStartupURL] = useState(null);
  // Title
  const [title, setTitle] = useState("Tresor Track Inc.");
  // Sub Title
  const [subTitle, setSubTitle] = useState("Asset Protection 24/7");
  const [imageLogo, setImageLogo] = useState(null);
  const [imageMain, setImageMainLogo] = useState(null);
  const [imagePhone, setImagePhoneLogo] = useState(null);
  const [imageDevice, setImageDeviceLogo] = useState(null);
  // Description
  const [description, setDescription] = useState(dataDescription);
  // App URL
  const [googleStore, setGoogleStore] = useState("");
  const [appStore, setAppStore] = useState("");
  // Features
  const [features, setFeatures] = useState([
    { value: "• Unique Satellite Network", isRemovable: false },
    { value: "• Private GSM Network", isRemovable: false },
    { value: "• Water & tamper proof", isRemovable: false },
    { value: "• Military grade Encryption", isRemovable: false },
    { value: "• Movement identification", isRemovable: false },
    { value: "• Heat and Humidity detection", isRemovable: false },
    { value: "• Biometrics enabled", isRemovable: false },
  ]);
  /////////////////////////////
  // <<=== Industry ===> START
  const [tags, setTags] = useState("");
  const [tagList, setTagList] = useState(["GPS", "Supply chain", "Logistics"]);
  const [inputClicked, setInputClicked] = useState(false);
  const [suggestedTags, setSuggestedTags] = useState(suggestedTagsList);
  // <<====================> END
  //////////////////////////////
  // <<=== Competitors ===> START
  const [competitors, setCompetitors] = useState("");
  const [competitorsList, setCompetitorsList] = useState([
    "CropX",
    "PayPal",
    "Robinhood",
  ]);
  const [inputClickedCompetitors, setInputClickedCompetitors] = useState(false);
  const [suggestedCompetitors, setSuggestedCompetitors] = useState(
    suggestedCompetitorList
  );
  // <<===================> END
  //////////////////////////////
  // <<=== Industry ===> START
  const [industrys, setIndustrys] = useState("");
  const [industrysList, setIndustrysList] = useState(["Electronics", "IoT"]);
  const [inputClickedIndustrys, setInputClickedIndustrys] = useState(false);
  const [suggestedIndustrys, setSuggestedIndustrys] = useState(
    suggestedIndustrysList
  );
  // <<==================> END
  //////////////////////////////
  // AddressableMarket
  const [adressableMarket, setAdressableMarket] = useState(1200000);
  // Incorporated
  const [incorporated, setIncorporated] = useState("Delaware C, USA");
  // Advantages
  const [advantages, setAdvantages] = useState([
    {
      title: "UNIQUE HARDWARE",
      text: "Patent pending unique Tracker with military grade accuracy, global coverage unique lifespan.",
      isRemovable: false,
    },
    {
      title: "BLOCKCHAIN USER PRIVACY",
      text: "All tracked assets can only be revealed by the user on. the blockchain, and the user alone. Encrypted and custodial and owner only-data.",
      isRemovable: false,
    },
    {
      title: "PATENTED DAMAGE PREVENTION",
      text: "Patent filed for the detection of fire, aggression and flood with unique triggering technology.",
      isRemovable: false,
    },
    {
      title: "UNIQUE SIZE FACTOR",
      text: "With a V1.0 smaller than a coin, Tresor Track. aims to become small enough to fit inside a luxury watch.",
      isRemovable: false,
    },
  ]);
  // Teams
  const [teams, setTeams] = useState([
    {
      title: "Virginie Blaise, CEO",
      text: "PhD, MIT Forbes 30U30",
      link: " ",
      isRemovable: false,
    },
    {
      title: "Elodie Arnaud CTO",
      text: "Stanford CS frmr. Google",
      link: " ",
      isRemovable: false,
    },
    {
      title: "Elodie Arnaud CTO",
      text: "Stanford CS frmr. Google",
      link: " ",
      isRemovable: false,
    },
  ]);
  const [teamImages, setTeamImages] = useState([]);

  const createImageObject = (file) => ({
    file: file,
    url: URL.createObjectURL(file),
  });
  //Fundraise Metrics
  const [fundraiseMetrics, setFundraiseMetrics] = useState({
    currentStage: "Pre-Series A",
    raising: 200000,
    raisedToDate: 1600000,
    useOfFunds: "Growth",
  });
  // Business Metrics
  const [businessMetrics, setBusinessMetrics] = useState({
    contractsValue: 24000000,
    currentRevenue: 300000,
    users: 150000,
    monthlyCashburn: 20000,
  });
  // Product Metrics
  const [productMetrics, setProductMetrics] = useState({
    costOfAcquisition: 12,
    lifeTimeValue: 1095,
    appPerCustomer: 219,
    cogsPerUnit: 45,
  });

  // <--- Take information with checkbox BY "Not for profit" --->
  const [selectedByProfit, setSelectedByProfit] = useState(false);
  const handleProfitToggle = () => {
    setSelectedByProfit((prevState) => !prevState);
  };

  // <<==================Uploading data to the server=======================>>
  useEffect(() => {
    setIsLoadingSpinner(true);
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("userID", _id);
    formData.append("urlAddress", startupUrlAddress);
    formData.append("googleStore", googleStore);
    formData.append("appStore", appStore);
    formData.append("imageLogo", imageLogo);
    formData.append("imageMain", imageMain);
    formData.append("imagePhone", imagePhone);
    formData.append("imageDevice", imageDevice);
    teamImages.forEach((image, index) => {
      formData.append(`teamImage${index + 1}`, image.file);
    });

    formData.append("title", title);
    formData.append("subTitle", subTitle);
    formData.append("description", description);
    const featureValues = features.map((feature) => feature.value);

    featureValues.forEach((value, index) => {
      formData.append(`features[${index}]`, value);
    });
    formData.append("tagList", tagList);
    formData.append("industrysList", industrysList);
    formData.append("competitorsList", competitorsList);
    formData.append("adressableMarket", adressableMarket);
    formData.append("incorporated", incorporated);
    formData.append("advantages", JSON.stringify(advantages));
    formData.append("fundraiseMetrics", JSON.stringify(fundraiseMetrics));
    formData.append("businessMetrics", JSON.stringify(businessMetrics));
    formData.append("productMetrics", JSON.stringify(productMetrics));
    formData.append("teams", JSON.stringify(teams));
    formData.append("selectedByProfit", selectedByProfit);

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/startups`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Redirection
        navigate(`/startups/${response.data.data._id}${"?firstSave=true"}`);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // If there is a response from the server with an error message
          ReactToastify("warn", error.response.data.message);
          setIsLoadingSpinner(false);
          setIsSubmitting(false);
        } else {
          // Other errors
          ReactToastify("warn", error);
          setIsLoadingSpinner(false);
          setIsSubmitting(false);
        }
      });
  }, [startupId]);
  // <<===================================================================>>

  return (
    <div>
      <Navbar />
      {isLoadingSpinner && <Loader />}
    </div>
  );
};

export default AddCompany;
