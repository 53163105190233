export const imageLogoStyle = {
  display: "flex",
  width: "57px",
  height: "60px",
  borderRadius: "5px",
  backgroundColor: "#fff",
};

export const tagsListStyle = {
  backgroundColor: "white",
  border: "1px solid black",
  width: 70,
  textAlign: "center",
  borderRadius: "8px",
  marginRight: 4,
};

export const tagsButtonStyle = {
  marginLeft: 5,
  marginBottom: -1,
  borderRadius: "8px",
  fontSize: 10,
  fontWeight: 800,
};

export const competitorsListStyle = {
  backgroundColor: "grey",
  width: 70,
  textAlign: "center",
  borderRadius: "8px",
  marginRight: 4,
};

export const competitorsButtonStyle = {
  borderRadius: "8px",
  fontSize: 10,
  fontWeight: 800,
};

export const frameUploadStyle = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  display: "inline-flex",
  zIndex: 3,
  textDecoration: "none",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const frameUploadSpanStyle = {
  position: "absolute",
  top: "20px",
  fontWeight: 500,
  color: "#010000",
  fontSize: 13,
  borderRadius: "8px",
  maxWidth: "150px",
  minWidth: "120px",
  boxShadow: "7px 0px 15px rgba(0, 0, 0, 0.15)",
  padding: "7px 12px",
  textAlign: "center",
  backgroundColor: "#ffffff",
};

export const imageDeviceSpan = {
  marginTop: "5px",
  fontSize: "12px",
  fontWeight: "500",
  color: "#010000",
  borderRadius: "8px",
  maxWidth: "150px",
  minWidth: "120px",
  boxShadow: "7px 0px 15px rgba(0, 0, 0, 0.15)",
  padding: "7px 12px",
  textAlign: "center",
  backgroundColor: "#FFFFFF",
};

export const mainBodyModal = {
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const mainBody = {
  width: 500,
  height: 414,
  backgroundColor: "white",
};

export const upContainer = {
  display: "flex",
  alignItems: "center",
  paddingLeft: 20,
  fontWeight: 700,
  width: "100%",
  justifyContent: "space-between",
  height: 50,
};

export const modalCircle = {
  borderRadius: 50,
  width: 20,
  height: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
};

export const modalButton = {
  width: 30,
  height: 30,
  marginRight: 5,
  boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const reactFormValidation = {
  fontWeight: 600,
  color: "red",
  fontSize: "14px",
  height: 20,
  display: "flex",
  marginTop: 5,
  marginBottom: 5,
  alignItems: "center",
};

export const reactFormValidationEdit = {
  border: "none",
  fontWeight: 600,
  color: "red",
  fontSize: "14px",
  height: 20,
  display: "flex",
  // marginTop: 5,
  // marginBottom: 10,
  alignItems: "center",
};
