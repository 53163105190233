import { useState, useEffect } from "react";

// This custom hook is used to determine the access level of a user based on various parameters.
// The access level is determined by the following logic:
// - 'Full access': granted if the user is the creator, a co-founder, or all additional emails are co-founders.
// - 'Medium access': granted if all additional emails have viewer and data access, or if the user has both viewer and data access.
// - 'Minimum access': granted if the user is in the viewer list, or if all additional emails are in the viewer list.

const useAccessLevel = (
  _id,
  createdBy,
  coFounder,
  email,
  viewer,
  dataAccess,
  additionalEmails
) => {
  const [accessStatus, setAccessStatus] = useState("No access");

  useEffect(() => {
    const determineAccessLevel = () => {
      // Checking for full interaction rights
      if (
        createdBy === _id ||
        coFounder?.includes(email) ||
        (additionalEmails.length > 0 &&
          additionalEmails?.every((email) => coFounder?.includes(email)))
      ) {
        return setAccessStatus("Full access");
      }
      // Checking for the average interaction right
      if (
        (additionalEmails.length > 0 &&
          additionalEmails?.every(
            (email) => viewer?.includes(email) && dataAccess?.includes(email)
          )) ||
        (dataAccess.includes(email) && viewer.includes(email))
      ) {
        return setAccessStatus("Medium access");
      }
      // Check for minimum interaction rights
      if (
        viewer.includes(email) ||
        (additionalEmails.length > 0 &&
          additionalEmails?.every((email) => viewer?.includes(email)))
      ) {
        return setAccessStatus("Minimum access");
      }
    };

    if (
      createdBy &&
      _id &&
      coFounder &&
      viewer &&
      dataAccess &&
      additionalEmails
    ) {
      determineAccessLevel();
    }
  }, [_id, createdBy, coFounder, viewer, dataAccess, additionalEmails]);

  return accessStatus;
};

export default useAccessLevel;
