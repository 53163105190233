import React from "react";
import Navbar from "../components/Navbar";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zoom: "90%",
        }}
      >
        <div className="PrivacyPolicy__container">
          <h2
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Privacy Policy
          </h2>
          <p
            style={{
              marginBottom: "20px",
            }}
          >
            Effective Date: [12.07.2023]
          </p>
          <p
            style={{
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            Introduction
          </p>
          <p
            style={{
              marginBottom: "20px",
            }}
          >
            Thank you for choosing Service One Metric ("we," "us," or "our") as
            your service provider. This Privacy Policy is intended to inform you
            about the types of personal information we collect, how we use it,
            and your rights regarding your personal information when you use our
            services.
          </p>
          <p
            style={{
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            Information We Collect
          </p>
          <p
            style={{
              marginBottom: "20px",
            }}
          >
            When you use Service One Metric, we may collect certain personal
            information from you, including but not limited to:
          </p>
          <ul
            style={{
              marginBottom: "20px",
            }}
          >
            <li>
              Contact information: such as your name, email address, and phone
              number.
            </li>
            <li>
              Account information: including your username, password, and any
              other details you provide when creating an account.
            </li>
            <li>
              Usage information: such as your IP address, browser type, device
              information, and the pages you visit on our website.
            </li>
          </ul>
          <p
            style={{
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            How We Use Your Information
          </p>
          <p
            style={{
              marginBottom: "20px",
            }}
          >
            We use the information we collect to:
          </p>
          <ul
            style={{
              marginBottom: "20px",
            }}
          >
            <li>Provide and maintain our services to you.</li>
            <li>Personalize and improve your experience with our services.</li>
            <li>
              Communicate with you regarding updates, changes, or additions to
              our services.
            </li>
            <li>
              Analyze usage trends and improve the functionality of our website.
            </li>
            <li>Comply with legal obligations and protect our rights.</li>
          </ul>
          <p
            style={{
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            Information Sharing and Disclosure
          </p>
          <p
            style={{
              marginBottom: "20px",
            }}
          >
            We may share your personal information with third parties in the
            following circumstances:
          </p>
          <ul
            style={{
              marginBottom: "20px",
            }}
          >
            <li>
              With your consent: We may share your information with third
              parties if you give us your consent to do so.
            </li>
            <li>
              Service providers: We may engage third-party service providers to
              assist us in providing our services, such as hosting providers,
              payment processors, or analytics providers.
            </li>
            <li>
              Legal requirements: We may disclose your information to comply
              with applicable laws, regulations, or legal processes.
            </li>
            <li>
              Protection of rights: We may disclose your information to protect
              the rights, property, or safety of Service One Metric, our users,
              or others.
            </li>
          </ul>
          <p
            style={{
              marginBottom: "20px",
            }}
          >
            Your Rights
          </p>
          <p
            style={{
              marginBottom: "20px",
            }}
          >
            You have certain rights regarding your personal information,
            including the right to access, correct, or delete your information.
            You can exercise these rights by contacting us using the information
            provided below.
          </p>
          <p
            style={{
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            Data Security
          </p>
          <p
            style={{
              marginBottom: "20px",
            }}
          >
            We take reasonable measures to protect the security of your personal
            information and implement appropriate safeguards to prevent
            unauthorized access, disclosure, alteration, or destruction of your
            information.
          </p>
          <p
            style={{
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            Changes to this Privacy Policy
          </p>
          <p
            style={{
              marginBottom: "20px",
            }}
          >
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or legal obligations. We encourage you to
            review this Privacy Policy periodically.
          </p>
          <p
            style={{
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            Contact Us
          </p>
          <p
            style={{
              marginBottom: "20px",
            }}
          >
            If you have any questions or concerns about this Privacy Policy or
            our privacy practices, please contact us at [contact@gmail.com].
          </p>
          <p
            style={{
              marginBottom: "20px",
            }}
          >
            Thank you for trusting Service One Metric with your personal
            information.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
