import React, { useState } from "react";
import Navbar from "../components/Navbar";
import ChatMainFile from "../components/ChatDropDown/ChatMainFile";
import "../style/chat.css";

const Chat = () => {
  const [isChatPage, setIsChatPage] = useState(true);

  return (
    <div>
      <Navbar isChatPage={isChatPage} />
      <ChatMainFile isChatPage={isChatPage} />
    </div>
  );
};

export default Chat;
