/**
 * File name: [Filters.jsx]
 * Description: [Component that works in the components "AddCompany.jsx", "StartupScreen.jsx to display other elements of the type: "ShareStartup.jsx", "Metrics.jsx", "DataRoom.jsx", "ShowContact.jsx"]
 *
 */

import React from "react";
import { Link } from "react-router-dom";
import projectImages from "../style/projectImages";
import ShareStartup from "./ShareStartup";
import ShowContact from "./ShowContact";
import DataRoom from "./DataRoom";
import "../style/css/style.css";

const Footer = ({
  id,
  _id,
  title,
  createdBy,
  imageLogo,
  showShare,
  showMetrics,
  showContact,
  accessStatus,
  isAddCompany,
  showDataRoom,
  handleShareClick,
  handleShareClose,
  handleAlertClick,
  handleMetricsClick,
  handleMetricsClose,
  handleContactClick,
  handleContactClose,
  handleDataRoomClose,
  handleDataRoomClick,
  handleInitialDataRoomClick,
}) => {
  return (
    <footer>
      <div className="container">
        <div className="outer__footer">
          <div className="footer__desc">
            <p>
              This presentation does not contain all the information that a
              prospective investor may desire in considering an investment. This
              document does not constitute an offer to the public or an offer
              for sale or solicitation to purchase or subscribe for any
              securities of the company and its affiliates and it should not be
              relied <span onClick={() => console.crashProd("error")}>o</span>n
              in connection with a decision to purchase or subscribe for any
              such securities. The document and any accompanying verbal
              presentation does not constitute a recommendation regarding any
              decision to sell or buy securities in the company.
            </p>
          </div>
          <ul>
            {/* CONTACT && CONTACT POPUP */}
            <li>
              {accessStatus === "Full access" && (
                <>
                  {showContact && (
                    <ShowContact
                      imageLogo={imageLogo}
                      handleContactClose={handleContactClose}
                      title={title}
                    />
                  )}
                  <Link href="#" className="contact__button">
                    <button
                      style={{ display: "flex" }}
                      onClick={
                        isAddCompany ? handleAlertClick : handleContactClick
                      }
                    >
                      <span>
                        {isAddCompany ? (
                          <img src={projectImages.foot1Grey} alt="foot" />
                        ) : (
                          <img src={projectImages.foot1} alt="foot" />
                        )}
                      </span>
                      <div
                        style={
                          isAddCompany ? { marginTop: 1, color: "grey" } : null
                        }
                      >
                        Contact
                      </div>
                    </button>
                  </Link>
                </>
              )}
            </li>
            {/* SHARE && SHARE POPUP */}
            <li>
              {showShare && (
                <ShareStartup
                  imageLogo={imageLogo}
                  handleShareClose={handleShareClose}
                  title={title}
                  id={id}
                />
              )}
              <Link href="#" className="share__button">
                <button
                  style={{ display: "flex" }}
                  onClick={isAddCompany ? handleAlertClick : handleShareClick}
                >
                  <span>
                    {isAddCompany ? (
                      <img src={projectImages.foot2Grey} alt="foot" />
                    ) : (
                      <img src={projectImages.foot2} alt="foot" />
                    )}
                  </span>
                  <div
                    style={
                      isAddCompany ? { marginTop: 1, color: "grey" } : null
                    }
                  >
                    Share
                  </div>
                </button>
              </Link>
            </li>
            {/* DATAROOM && SHARE DATAROOM */}
            <li>
              {showDataRoom && (
                <DataRoom
                  // Startup ID
                  id={id}
                  // User ID
                  _id={_id}
                  createdBy={createdBy}
                  startupTitle={title}
                  accessStatus={accessStatus}
                  onClose={handleDataRoomClose}
                />
              )}
              <Link className="room__button">
                <button
                  style={{ display: "flex" }}
                  onClick={
                    isAddCompany
                      ? handleInitialDataRoomClick
                      : handleDataRoomClick
                  }
                >
                  <span>
                    <img
                      src={
                        showDataRoom
                          ? projectImages.folderBlack
                          : projectImages.folderWhite
                      }
                      alt="foot"
                    />
                  </span>
                  <div>Data Room</div>
                </button>
              </Link>
            </li>
            {/* METRICS && METRICS POPUP */}
            <li>
              <Link className="metrics__button">
                <button
                  style={{ display: "flex" }}
                  onClick={isAddCompany ? handleAlertClick : handleMetricsClick}
                >
                  <span>
                    <img
                      src={
                        showMetrics
                          ? projectImages.barChatBlack
                          : projectImages.barChatWhite
                      }
                      alt="foot111"
                    />
                  </span>
                  <div
                    style={
                      isAddCompany ? { marginTop: 1, color: "grey" } : null
                    }
                  >
                    Metrics
                  </div>
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
