import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import projectImages from "../style/projectImages";
import "./SearchBarDropDown.css";

// GET props with <Navbar/> component
const SearchBarDropDown = ({ setSearchQuery, isHomeScreen }) => {
  // GET Startup data with Redux store
  const data = useSelector((state) => state.startups.data.startups || []);

  ///////////////////////////////////////////////////////////////////////////////////////////// START
  // <<==== A function that closes <SearchBarDropDown/> if the user clicks anywhere else ====>>
  const dropDownRef = useRef(null);

  const handleClick = () => {
    setSearchQuery("");
  };

  // Listen for clicks on the document
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setSearchQuery("");
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setSearchQuery]);
  ///////////////////////////////////////////////////////////////////////////////////////////// END

  return (
    <div
      className="searchBarDrop__main"
      style={{ top: isHomeScreen ? 121 : null }}
    >
      <div
        className="searchBarDropDownContainer"
        ref={dropDownRef}
        style={{ height: data?.length === 0 ? 90 : 250 }}
      >
        {data?.length === 0 ? (
          <div style={{ padding: 20 }}>
            <div style={{ fontWeight: 700 }}>No startups found</div>
            <a
              href="/home"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Browse all startups
            </a>
          </div>
        ) : (
          <>
            {data?.map((startup) => {
              return (
                <Link
                  to={`/startups/${
                    startup.urlAddress !== "" ? startup.urlAddress : startup._id
                  }`}
                  onClick={handleClick}
                  key={startup._id}
                  className="startupLink"
                >
                  <div className="startupLogoContainer">
                    <div className="startupLogo">
                      {startup.imageLogo ? (
                        <img
                          src={`${startup.imageLogo}`}
                          alt="biglogo"
                        />
                      ) : (
                        <img src={projectImages.bigLogoImg} alt="bigLogoImg1" />
                      )}
                    </div>
                  </div>
                  <div className="startupDetailsContainer">
                    <div style={{ fontWeight: 700 }}>
                      {startup.title.length < 24
                        ? startup.title
                        : startup.title.substring(0, 25) + "..."}
                    </div>
                  </div>
                </Link>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchBarDropDown;
