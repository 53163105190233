import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./slices/userSlice";
import startupsReducer from "./slices/startupsSlice";
import favoriteStartups from "./slices/favoriteStartups";
import notificationsSlice from "./slices/notificationsSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    startups: startupsReducer,
    favoriteStartups: favoriteStartups,
    notificationsSlice: notificationsSlice,
  },
});

export * from "./thunk/fetchUser";
export * from "./thunk/fetchStartups";
