import React from "react";
import "./PhotoModal.css";

// Props with <MessageList/>
const PhotoModal = ({ imageUrl, onClose }) => {
  return (
    <div className="photo-modal-overlay">
      <div className="photo-modal">
        <img className="modal-image" src={imageUrl} alt="Large Photo" />
      </div>
      <button className="close-button" onClick={onClose}>
        X
      </button>
    </div>
  );
};

export default PhotoModal;
