/**
 * File name: [ShowContact.jsx]
 * Description: [Component used in the "StartupScreen.jsx" component to send notifications via mail (AWS)]
 *
 */

import React, { useState } from "react";
import { usePhoneValidation } from "react-international-phone";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import projectImages from "../style/projectImages";
import ReactToastify from "../hooks/useReactToastify";
import {
  FlagEmoji,
  parseCountry,
  usePhoneInput,
  defaultCountries,
} from "react-international-phone";
import "react-international-phone/style.css";
import axios from "axios";

// Props from the component "StartupScreen.jsx"
const ShowContact = ({
  // Startup ID
  id,
  name,
  email,
  title,
  imageLogo,
  startupData,
  handleContactClose,
  setIsLoadingSpinner,
}) => {
  const token = JSON.parse(localStorage.getItem("userToken"));

  // Checking whether the conditions are specified
  const [isChecked, setIsChecked] = useState(false);
  // Phone number state
  const [phone, setPhone] = useState("33744223344");
  // State of the selected country
  const [selectedCountry, setSelectedCountry] = useState("us");
  // Status of country filtering by letter
  const [countryFilter, setCountryFilter] = useState("");
  // Status or clicked on the list of countries
  const [click, setClick] = useState(null);
  // Checking for the correctness of the phone number
  const phoneValidation = usePhoneValidation(phone);

  // Change the state of the " Click "
  const handleSectionClick = () => {
    setClick((prevClick) => (prevClick === null ? true : !prevClick));
  };

  // Filter countries
  const filteredCountries = defaultCountries.filter((country) => {
    const countryName = parseCountry(country).name.toUpperCase();
    return countryName.startsWith(countryFilter);
  });

  const {
    phone: formattedPhone,
    handlePhoneValueChange,
    inputRef,
    country,
    setCountry,
  } = usePhoneInput({
    defaultCountry: selectedCountry,
    value: phone,
    countries: filteredCountries,
    onChange: (data) => {
      setPhone(data.phone);
    },
  });

  // Change of state "isChecked"
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // Sending the form to the server with a validation check and "isChecked"
  const handleFormSubmit = async (e) => {
    setIsLoadingSpinner(true);
    try {
      await validationSchema.validate(formik.values, { abortEarly: false });
      // Additional validation for phone number completion
      if (!phoneValidation.isValid) {
        setIsLoadingSpinner(false);
        ReactToastify("warn", "Please enter a complete phone number");
        return;
      }
      // Validation passed, proceed with sending the request
      if (!isChecked) {
        setIsLoadingSpinner(false);
        ReactToastify("warn", "Terms and condidions must be accepted");
        return;
      }
      // Collecting a data packet for sending
      const dataSendToEmail = {
        message: formik.values.message,
        email: formik.values.email,
        emailToReply: email,
        phone: phone,
        calendly: formik.values.calendly,
      };

      const data = {
        startupName: title, // Name of the startup, used for naming the channel
        investorEmail: email, // Email of the investor, used for creating unique channel participant ID
        startupOwnerID: startupData.createdBy, // Database ID of the startup owner, used to fetch their email
        initialMessage: formik.values.message, // Initial message to be sent in the chat channel
        imageLogo: imageLogo, // Logo of the startup, used as the channel image
        startupID: id, // Unique identifier of the startup, used in forming the channel ID
        investorName: name, // Name of the investor, used for display purposes in the channel

        // email: formik.values.email,
        // coFounders: startupData.coFounder,
      };

      // Channel-create
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/channel/channel-create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Send-email-contact
      const response1 = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/startups/send-email-contact`,
        dataSendToEmail,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoadingSpinner(false);
      ReactToastify("success", "Your message was sent successfully");
      handleContactClose();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setIsLoadingSpinner(false);
        ReactToastify("warn", "Validation error occurred");
      } else {
        setIsLoadingSpinner(false);
        ReactToastify("warn", "An error occurred");
      }
    }
  };

  // Validation logic
  const initialValues = {
    message:
      "Hello David, it was a pleasure meeting you today at the Rosewood Hotel, I was happy to see your pitch do you have further information? Can I have access to your data room? All the best Tony",
    email: "Tonydaniel@lvmh.com",
    phone: phone,
    calendly: "Calendly/link",
    terms: false,
  };

  const validationSchema = Yup.object().shape({
    message: Yup.string()
      .required("Message is required")
      .max(399, "Message is too long"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .max(39, "Email is too long"),
    calendly: Yup.string()
      .required("Calendly/link is required")
      .max(59, "Calendly/link is too long"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  if (!startupData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="modal__wrapper">
      <div className="inner__modal">
        <div className="modal__box contact__modal">
          {/* Header */}
          <div className="head__" style={{ flexDirection: "unset" }}>
            <p>
              <span>
                <img src={projectImages.comment} alt="contact" />
              </span>
              Contact
            </p>
            <Link onClick={handleContactClose}>
              <img src={projectImages.closeSmall} alt="closesmall" />
            </Link>
          </div>
          {/* <<== FORM BODY ==>> */}
          <form onSubmit={formik.handleSubmit}>
            {/* <-- Title + Image --> */}
            <div className="company__info">
              <div
                className="w-45 h-45 rounded-full shadow-md flex justify-center items-center"
                style={{
                  width: 45,
                  height: 45,
                  marginBottom: 10,
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
                }}
              >
                {/* Checking if we have a photo */}
                {imageLogo ? (
                  <img
                    style={{ width: 45, height: 45 }}
                    src={`${imageLogo}`}
                    alt="Tresor Track"
                    className="w-7 h-7 rounded-full     "
                  />
                ) : (
                  <img
                    style={{ width: 35, height: 35, marginTop: 5 }}
                    src={projectImages.bigLogoImg}
                    alt="bigLogoImg1"
                  />
                )}
              </div>
              <div className="desc__">
                <p>{title.substring(0, 25) + "..."}</p>
                <span>{process.env.REACT_APP_CLIENT_URL}</span>
              </div>
            </div>
            {/* <-- Message --> */}
            <div className="group__input" style={{ marginBottom: 0 }}>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                value={formik.values.message}
                onChange={(e) => {
                  if (e.target.value.length <= 400) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
              ></textarea>
              {formik.touched.message && formik.errors.message && (
                <div className="error" style={{ color: "red", fontSize: 12 }}>
                  *{formik.errors.message}
                </div>
              )}
            </div>
            <div style={{ color: "grey", fontSize: 12, marginBottom: 7 }}>
              Your contact details:
            </div>
            {/* <-- Email --> */}
            <div className="group__input">
              <input
                name="email"
                id="email"
                type="text"
                value={formik.values.email}
                onChange={(e) => {
                  if (e.target.value.length <= 37) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="error" style={{ color: "red", fontSize: 12 }}>
                  *{formik.errors.email}
                </div>
              )}
            </div>
            {/* <-- Phone --> */}
            <div
              className="group__input"
              style={{
                width: "100%",
                border: "1px solid grey",
                borderRadius: "5px",
                padding: "2px",
              }}
            >
              <div style={{ display: "flex", height: 37 }}>
                {/* Select country */}
                <div
                  style={{ height: 37 }}
                  className="relative"
                  onClick={handleSectionClick}
                >
                  <select
                    style={{ width: 37, height: 37 }}
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="block appearance-none w-full   py-2 px-3 pr-8 rounded leading-tight focus:outline-none "
                  >
                    {filteredCountries.map((c) => {
                      const country = parseCountry(c);
                      return (
                        <option key={country.iso2} value={country.iso2}>
                          {country.name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center p-2 text-gray-700">
                    <FlagEmoji iso2={country} />
                    {click ? (
                      <img
                        style={{ width: 8, height: 8, marginLeft: 3 }}
                        alt="upTriangle"
                        src={projectImages.upTriangle}
                      />
                    ) : (
                      <img
                        style={{ width: 8, height: 8, marginLeft: 3 }}
                        alt="downTriangle"
                        src={projectImages.downTriangle}
                      />
                    )}
                  </div>
                </div>
                {/* Phone input */}
                <input
                  type="tel"
                  placeholder="Phone number"
                  value={formattedPhone}
                  onChange={handlePhoneValueChange}
                  ref={inputRef}
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    marginLeft: 5,
                  }}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className="error" style={{ color: "red", fontSize: 12 }}>
                    *{formik.errors.phone}
                  </div>
                )}
              </div>
            </div>
            {/* <-- Calendly --> */}
            <div className="group__input">
              <input
                type="text"
                name="calendly"
                value={formik.values.calendly}
                onChange={(e) => {
                  if (e.target.value.length <= 60) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.calendly && formik.errors.calendly && (
                <div className="error" style={{ color: "red", fontSize: 12 }}>
                  *{formik.errors.calendly}
                </div>
              )}
            </div>
            {/* <-- Handle checkbox change --> */}
            <div className="group__checkbox__modal">
              <label className="container__check">
                <input
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  type="checkbox"
                />
                <span className="checkmark">{isChecked && "✓"}</span>
              </label>
              <Link to="/terms" target="_blank">
                Terms and Conditions
              </Link>
            </div>
            {/* <-- Send form --> */}
            <div className="group__submit">
              <button type="submit">Send</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShowContact;
