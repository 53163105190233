import React from "react";
import projectImages from "../../style/projectImages";

// Component to render a list of folders
const FoldersListComponent = ({
  filteredFoldersArray, // Array of folder names to display
  displayMenu, // Function to display context menu
  setIsFolderSelected, // Function to set if a folder is selected
  editingFile, // State to track the folder currently being renamed
  renameFolder, // Function to handle folder rename
  setEditingFile, // Function to set the folder currently being edited
  handleFolderClick, // Function to handle click event on a folder
  setNewFileName, // Function to set the new name for a folder
  id, // Unique startup identifier
  newFileName, // State to track the new folder name input
}) => {
  return (
    <>
      {/* Map over the filteredFoldersArray to display each folder */}
      {filteredFoldersArray.map((folder) => (
        <div
          className="folder__item"
          key={folder} // Unique key for React list rendering
          onContextMenu={(e) => {
            // Display the context menu on right-click
            displayMenu(e, folder);
            setIsFolderSelected(true); // Indicate that a folder is selected
          }}
        >
          {/* Adjust height dynamically if a folder is being edited */}
          <div style={{ height: editingFile === folder ? 0 : 23 }} />

          {/* Conditional rendering if a folder is being edited */}
          {editingFile === folder && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {/* Button to confirm the renaming of a folder */}
              <button
                style={{ marginRight: 5 }}
                onClick={() => renameFolder(folder)}
              >
                <img
                  alt="confirm"
                  style={{ width: 13, height: 13 }}
                  src={projectImages.check_blue}
                />
              </button>
              {/* Button to cancel editing */}
              <button
                style={{ marginRight: 10 }}
                onClick={() => setEditingFile(false)}
              >
                <img
                  alt="cancel"
                  style={{ width: 20, height: 20 }}
                  src={projectImages.deleteButton}
                />
              </button>
            </div>
          )}

          {/* Button to select or open a folder */}
          <button style={{ width: "100%" }}>
            <span
              onClick={() => handleFolderClick(id, folder)}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ maxWidth: 120, maxHeight: 100 }}
                className="filesStyle"
                src={projectImages.folder}
                alt="folder icon"
              />
            </span>
            <div>
              {/* Input field to edit the folder's name */}
              {editingFile === folder ? (
                <div>
                  <input
                    type="text"
                    value={newFileName}
                    onChange={(e) => setNewFileName(e.target.value)}
                    onKeyDown={(e) => {
                      // Submit new name on Enter key press
                      if (e.key === "Enter") {
                        renameFolder(folder);
                      }
                    }}
                    style={{ maxWidth: 153, marginTop: 0 }}
                    className="form-input px-2 py-1 border border-gray-300"
                  />
                </div>
              ) : (
                // Display folder name
                <p
                  style={{
                    textAlign: "center",
                    marginTop: 6,
                    wordBreak: "break-word",
                  }}
                >
                  {folder}
                </p>
              )}
            </div>
          </button>
        </div>
      ))}
    </>
  );
};

export default FoldersListComponent;
