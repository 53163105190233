import React from "react";

// Modal component for creating a new folder.
const CreateFolderModal = ({
  setIsOpenModalCreateFolder, // Function to set the state for showing or hiding the modal.
  setCreateNewFolderName, // Function to set the state of the new folder's name.
  createNewFolderName, // Current state value for the new folder's name.
  handleCreateFolder, // Function to be called when creating the folder.
}) => {
  return (
    // Modal overlay that covers the entire screen.
    <div
      style={{ zIndex: 99 }}
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center"
    >
      {/* Modal content container */}
      <div className="bg-white p-5 rounded-lg shadow-lg w-1/3">
        {/* Modal header */}
        <h2 className="text-lg font-semibold mb-4">Create a new folder</h2>
        {/* Input field for the new folder's name */}
        <input
          type="text"
          className="border border-gray-300 p-2 w-full mb-4"
          placeholder="Folder name"
          value={createNewFolderName}
          onChange={(e) => setCreateNewFolderName(e.target.value)} // Update the folder's name as the user types.
          onKeyDown={(e) => {
            // Submit the form when the Enter key is pressed.
            if (e.key === "Enter") {
              handleCreateFolder();
            }
          }}
        />
        {/* Action buttons for the modal */}
        <div className="flex justify-end">
          {/* Button to create the folder */}
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={handleCreateFolder}
          >
            Create
          </button>
          {/* Button to cancel and close the modal */}
          <button
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => setIsOpenModalCreateFolder(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateFolderModal;
