import React from "react";
import projectImages from "../style/projectImages";
import { Link } from "react-router-dom";

const ConfirmEmailAdditionModal = ({
  isOpen,
  companyID,
  onCancel,
  email,
  startupName,
}) => {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      style={{ zIndex: 10 }}
      className={`fixed inset-0 bg-black bg-opacity-80 overflow-y-auto h-full w-full flex justify-center items-center transition-opacity ${
        isOpen ? "block" : "hidden"
      }`}
      onClick={onCancel}
    >
      <div
        className="bg-white rounded-2xl shadow-2xl transform transition-all relative"
        onClick={stopPropagation}
        style={{
          padding: 20,
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)", // Increase shadow intensity
        }}
      >
        <div
          style={{
            display: "flex",
            height: 40,
            marginBottom: 20,
            justifyContent: "space-between",
            borderBottom: "1px solid #979797",
          }}
        >
          <div
            className="text-2xl font-semibold  text-gray-800"
            style={{ fontSize: 18 }}
          >
            Notification
          </div>
          <button
            className=" bg-white rounded-full  shadow-md"
            onClick={onCancel}
            style={{
              width: "24px",
              height: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 2,
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
              transform: "rotate(135deg)", // Rotate the image 135 degrees
            }}
          >
            <img
              style={{ width: 15, height: 15 }}
              src={projectImages.closeSmall}
              alt="closesmall"
            />
          </button>
        </div>
        <p className="text-gray-600 mb-6">
          Dataroom access to{" "}
          <span className="font-medium text-gray-800">{startupName}</span> has
          been granted.
        </p>
        <div className="flex justify-around mt-4">
          <Link
            to={`${process.env.REACT_APP_CLIENT_URL}/startups/${companyID}`}
            className="px-5 py-2.5 bg-black text-white font-medium rounded-lg hover:bg-blue-600 transition-colors"
            onClick={onCancel}
          >
            Visit Dataroom
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmailAdditionModal;
