import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setAccessRecords,
  setId,
  setLoading,
  setOwnerId,
  setError,
} from "../slices/notificationsSlice";

export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async ({ userEmail }, { dispatch }) => {
    const token = JSON.parse(localStorage.getItem("userToken"));

    if (!token) {
      return;
    }

    try {
      dispatch(setLoading(true));
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/users/record/find?userEmail=${userEmail}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(setAccessRecords(response?.data?.accessRecords));
      dispatch(setOwnerId(response?.data?.ownerId));
      dispatch(setId(response?.data?._id));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error));
      console.error(error);
    }
  }
);
