import React from "react";
import Navbar from "../components/Navbar";
import "./Terms.css";

const Terms = () => {
  const styles = {
    container: {
      margin: "20px",
      fontFamily: "Arial, sans-serif",
      fontSize: "16px",
      lineHeight: "1.5",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    section: {
      marginBottom: "20px",
    },
    subheading: {
      fontSize: "18px",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    paragraph: {
      marginBottom: "10px",
    },
    link: {
      color: "#0000EE",
      textDecoration: "underline",
    },
  };
  return (
    <div style={{ height: 1200 }}>
      <Navbar />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zoom: "90%",
        }}
      >
        <div className="Terms__container">
          <div style={styles.container}>
            <h1 style={styles.heading}>Terms of Service</h1>
            <div style={styles.section}>
              <p style={styles.paragraph}>
                By accessing or using the Service, you agree to be bound by
                these Terms. If you disagree with any part of the terms, then
                you may not access the Service.
              </p>
            </div>
            <div style={styles.section}>
              <h2 style={styles.subheading}>1. Use of Service</h2>
              <p style={styles.paragraph}>
                a. The Service provided by OneMetric is intended for your
                personal and non-commercial use only. You must be at least 18
                years old or the legal age of majority in your jurisdiction to
                use the Service.
              </p>
              <p style={styles.paragraph}>
                b. You are responsible for maintaining the confidentiality of
                your account and password and for restricting access to your
                computer or device. You agree to accept responsibility for all
                activities that occur under your account or password.
              </p>
              <p style={styles.paragraph}>
                c. You must not use the Service for any illegal or unauthorized
                purpose. You must not, in the use of the Service, violate any
                laws in your jurisdiction.
              </p>
            </div>
            <div style={styles.section}>
              <h2 style={styles.subheading}>2. Intellectual Property</h2>
              <p style={styles.paragraph}>
                a. The Service and its original content, features, and
                functionality are owned by OneMetric Inc. and are protected by
                international copyright, trademark, patent, trade secret, and
                other intellectual property or proprietary rights laws.
              </p>
              <p style={styles.paragraph}>
                b. You may not modify, reproduce, distribute, create derivative
                works or adaptations of, publicly display or in any way exploit
                any of the content, in whole or in part, except as expressly
                authorized by us.
              </p>
            </div>
            <div style={styles.section}>
              <h2 style={styles.subheading}>3. Disclaimer of Warranty</h2>
              <p style={styles.paragraph}>
                a. The Service is provided on an "as is" and "as available"
                basis. OneMetric Inc. does not warrant that the Service will be
                uninterrupted, timely, secure, or error-free.
              </p>
              <p style={styles.paragraph}>
                b. We make no warranties or representations about the accuracy
                or completeness of the content provided through the Service. Any
                reliance on such content is at your own risk.
              </p>
            </div>
            <div style={styles.section}>
              <h2 style={styles.subheading}>4. Limitation of Liability</h2>
              <p style={styles.paragraph}>
                In no event shall OneMetric Inc. be liable for any indirect,
                incidental, special, consequential, or punitive damages,
                including but not limited to lost profits or loss of data,
                arising out of or in connection with your use of the Service.
              </p>
            </div>
            <div style={styles.section}>
              <h2 style={styles.subheading}>5. Governing Law</h2>
              <p style={styles.paragraph}>
                These Terms shall be governed by and construed in accordance
                with the laws of [Jurisdiction], without regard to its conflict
                of law provisions.
              </p>
            </div>
            <div style={styles.section}>
              <h2 style={styles.subheading}>6. Changes to Terms of Service</h2>
              <p style={styles.paragraph}>
                OneMetric Inc. reserves the right, at its sole discretion, to
                modify or replace these Terms at any time. Your continued use of
                the Service after any such changes constitutes your acceptance
                of the new Terms.
              </p>
            </div>
            <p style={styles.paragraph}>
              If you have any questions or concerns about these Terms, please
              contact us at{" "}
              <a style={styles.link} href="[contact email]">
                [contact@gmail.com]
              </a>
              .
            </p>
            <p style={styles.paragraph}>
              By using the OneMetric website, you acknowledge that you have
              read, understood, and agree to be bound by these Terms of Service.
            </p>
            <p style={styles.paragraph}>Date of last update: [12.07.2023]</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
