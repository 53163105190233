import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading, setStartups } from "../slices/favoriteStartups";

export const getFavoriteStartups = createAsyncThunk(
  "startups/getFavoriteStartups",
  async ({ _id }, { dispatch }) => {
    const token = JSON.parse(localStorage.getItem("userToken"));

    if (!token) {
      return;
    }

    try {
      dispatch(setLoading(true));
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/startups/save-startup`,
        {
          params: {
            adminId: _id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const setFavoriteStartups = response.data;

      dispatch(setStartups(setFavoriteStartups));
      dispatch(setLoading(false));
    } catch (error) {
      console.error(error);
    }
  }
);
