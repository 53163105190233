import { createSlice } from "@reduxjs/toolkit";
import { fetchUser } from "../thunk/fetchUser";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    data: {},
    error: null,
    isAuthenticated: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
    initializeUser: (state, action) => {
      state.data = action.payload;
      // Update user status after authorization
      state.isAuthenticated = true;
    },
    clearUser: (state) => {
      state.data = {};
      state.isAuthenticated = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const { setUser, initializeUser, clearUser } = userSlice.actions;
// Obtaining the user's authorization status
export const selectIsUserAuthenticated = (state) => state.user.isAuthenticated;

export const userReducer = userSlice.reducer;
