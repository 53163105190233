import { createSlice } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    isLoading: false,
    accessRecords: [],
    ownerId: "",
    _id: "",
    error: null,
  },
  reducers: {
    setAccessRecords: (state, action) => {
      state.accessRecords = action.payload;
    },
    setOwnerId: (state, action) => {
      state.ownerId = action.payload;
    },
    setId: (state, action) => {
      state._id = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearRecords: (state) => {
      state.accessRecords = [];
      state._id = "";
      state.ownerId = "";
    },
  },
});

export const {
  setAccessRecords,
  setOwnerId,
  setId,
  setLoading,
  setError,
  clearRecords,
} = notificationsSlice.actions;

export const selectNotifications = (state) => state.notifications;

export default notificationsSlice.reducer;
