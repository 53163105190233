import React from "react";
import {
  Menu,
  Item,
  // Separator,
  // Submenu,
  // useContextMenu,
} from "react-contexify";

// Define a unique identifier for the context menu
const MENU_ID = "menu-id";

// ReactContexifyMenu component takes several props for handling different actions
const ReactContexifyMenu = ({
  handleFolderClick, // Function to handle the event when a folder is clicked
  handleEditFileName, // Function to handle the event when a file name is edited
  downloadAndViewFile, // Function to handle file viewing and downloading
  imageOrFileName, // The current file or folder name or image being interacted with
  isFolderSelected, // Boolean to check if the current selection is a folder
  setEditingFile, // Function to set the file being edited
  setNewFileName, // Function to set the new file name during renaming
  deleteFolder, // Function to delete a folder
  deleteFile, // Function to delete a file
  downloadFile, // Function to download a file
  accessStatus,
  id, // Unique startup identifier
}) => {
  return (
    <div>
      {/* Create the context menu with defined MENU_ID */}
      <Menu id={MENU_ID}>
        {/* Menu item to open a folder or file based on the selection */}
        <Item
          onClick={() =>
            isFolderSelected
              ? handleFolderClick(id, imageOrFileName)
              : downloadAndViewFile(imageOrFileName)
          }
        >
          {isFolderSelected ? "Open Folder" : "Open File"}
        </Item>
        <Item
          disabled={isFolderSelected || accessStatus !== "Full access"}
          onClick={() => {
            downloadFile(imageOrFileName);
          }}
        >
          Download
        </Item>
        <Item
          disabled={accessStatus !== "Full access"}
          onClick={() => {
            setEditingFile(imageOrFileName);
            setNewFileName(imageOrFileName);
            handleEditFileName(imageOrFileName);
          }}
        >
          Rename
        </Item>
        <Item
          disabled={accessStatus !== "Full access"}
          onClick={() => {
            isFolderSelected
              ? deleteFolder(imageOrFileName)
              : deleteFile(imageOrFileName);
          }}
        >
          Delete
        </Item>
        {/* <Separator />
        <Item disabled={isFolderSelected}>Disabled</Item>
        <Separator />
        <Submenu label="Submenu">
          <Item>Sub Item 1</Item>
          <Item>Sub Item 2</Item>
        </Submenu> */}
      </Menu>
    </div>
  );
};

export default ReactContexifyMenu;
