import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFavoriteStartups } from "../store/thunk/favoriteStartups";
import { Link, useNavigate } from "react-router-dom";
import { useSelectedValues } from "../hooks/useSelectedValues";
import { fetchStartups } from "../store/thunk/fetchStartups";
import Navbar from "../components/Navbar";
import Filters from "../components/Filters";
import Loader from "../components/Loader";
import projectImages from "../style/projectImages";
import {
  suggestedCompetitorList,
  suggestedIndustrysList,
  suggestedTagsList,
} from "../data/data";
import ChatMainFile from "../components/ChatDropDown/ChatMainFile";
import ButtonChatDropDown from "../components/ButtonChatDropDown";
import { useChatCustomContext } from "../context/ChatProvider";
import useEscKeyListener from "../hooks/useEscKeyListener";
import ConfirmEmailAdditionModal from "../components/EmailConfirmationModal";
import axios from "axios";
import ReactToastify from "../hooks/useReactToastify";
import { fetchUser } from "../store/thunk/fetchUser";

const Home = () => {
  const token = JSON.parse(localStorage.getItem("userToken"));

  const { userHasChannels } = useChatCustomContext();
  const [isHomeScreen, setIsHomeScreen] = useState(true);
  // Required to solve visual design problems when using the Safari browser
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Startup data
  const data = useSelector((state) => state?.startups?.data?.startups || []);
  // User data
  const { isAdmin, _id, email, name, picture, additionalEmails } = useSelector(
    (state) => state.user.data || {}
  );
  // Data from "favorite" startups
  const favoriteStartups = useSelector(
    (state) => state?.favoriteStartups?.data
  );
  // Data from the search
  const isLoading = useSelector((state) => state?.startups?.isLoading);

  // <--- FILTERING BY TAGS with a custom hook --->
  const [selectedTags, handleTagToggle] = useSelectedValues([]);
  // <--- FILTERING BY INDUSTRYS with a custom hook --->
  const [selectedIndustries, handleIndustryToggle] = useSelectedValues([]);
  // <--- FILTERING BY COMPETITORS  with a custom hook --->
  const [selectedCompetitors, handleCompetitorsToggle] = useSelectedValues([]);

  // Sets a new "searchQuery" value based on the passed "newSearchQuery" value.
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchQuerySubmit = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
  };

  // <--- FILTERING BY RASING --->
  const [rasingValue, setRasingValue] = useState([0, 100]);
  const handleChange = (event, newValue) => {
    if (newValue[0] !== newValue[1]) {
      setRasingValue(newValue);
    }
  };

  // <--- FILTERING BY Fundraising Stage --->
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setSelectedCheckbox((prevValue) => (prevValue === value ? "" : value));
  };

  // <--- FILTERING BY DataRoom --->
  const [selectedByDataRoom, setSelectedByDataRoom] = useState(false);
  const handleDataRoomToggle = () => {
    setSelectedByDataRoom((prevState) => !prevState);
  };

  // <--- FILTERING BY Not for profit --->
  const [selectedProfit, setSelectedByProfit] = useState(false);
  const handleProfitToggle = () => {
    setSelectedByProfit((prevState) => !prevState);
  };

  useEffect(() => {
    // Downloads "startups"
    dispatch(
      fetchStartups({
        _id,
        isAdmin,
        filteringByName: searchQuery,
        tags: selectedTags.join(","),
        industrys: selectedIndustries.join(","),
        competitors: selectedCompetitors.join(","),
        raisingValue: rasingValue,
        currentStage: selectedCheckbox,
        selectedByDataRoom: selectedByDataRoom,
        selectedProfit: selectedProfit,
        additionalEmails,
      })
    );
    // Downloads "favorite startups"
    dispatch(getFavoriteStartups({ _id }));
  }, [
    dispatch,
    _id,
    isAdmin,
    searchQuery,
    selectedTags,
    selectedIndustries,
    selectedCompetitors,
    selectedCheckbox,
    rasingValue,
    selectedByDataRoom,
    selectedProfit,
  ]);

  const handleStartupClick = async (id) => {
    navigate(`/startups/${id}`);
  };

  // Check if the data has been downloaded
  const haveDataValue = Array.isArray(data) && data.length > 0;

  // Function to find unique values from a property in an array of objects and merge with existing array
  function findUniqueValues(data, suggestedValues, property) {
    data.forEach((startup) => {
      // Concatenate values from the specified property of each object in data to the suggestedValues array
      suggestedValues.concat(startup[property]);
    });

    // Return a new array containing only unique values
    return [...new Set(suggestedValues)];
  }

  // Create an array of unique tag values
  const tagListArray = findUniqueValues(data, suggestedTagsList, "tagList");

  // Create an array of unique industry values
  const industryListArray = findUniqueValues(
    data,
    suggestedIndustrysList,
    "industrysList"
  );

  // Create an array of unique competitor values
  const competitorListArray = findUniqueValues(
    data,
    suggestedCompetitorList,
    "competitorsList"
  );

  // <<=====================================================================>>
  const [chatDropDownOpen, setChatDropDownOpen] = useState(false);

  const handleChatDroppDown = () => {
    if (window.innerWidth <= 600) {
      // If the window width is less than or equal to 600px, redirect to /chat
      navigate("/chat");
    } else {
      // Otherwise, open or close the dropdown
      setChatDropDownOpen((prevState) => !prevState);
    }
  };
  useEscKeyListener(setChatDropDownOpen);

  return (
    <div className="h-screen">
      {!chatDropDownOpen && userHasChannels && (
        <ButtonChatDropDown handleChatDroppDown={handleChatDroppDown} />
      )}
      {chatDropDownOpen && (
        <ChatMainFile handleChatDroppDown={handleChatDroppDown} />
      )}
      {isLoading && <Loader />}
      <Navbar
        onSearchQuerySubmit={handleSearchQuerySubmit}
        isHomeScreen={isHomeScreen}
      />
      <div style={{ zoom: "80%" }} className="companies__wrapper">
        {/* FILTER LIST  */}
        <Filters
          // New keywords
          tagListArray={tagListArray}
          industryListArray={industryListArray}
          competitorListArray={competitorListArray}
          //
          selectedTags={selectedTags}
          handleTagToggle={handleTagToggle}
          selectedIndustries={selectedIndustries}
          handleIndustryToggle={handleIndustryToggle}
          selectedCompetitors={selectedCompetitors}
          handleCompetitorsToggle={handleCompetitorsToggle}
          rasingValue={rasingValue}
          handleChange={handleChange}
          selectedCheckbox={selectedCheckbox}
          handleCheckboxChange={handleCheckboxChange}
          handleDataRoomToggle={handleDataRoomToggle}
          handleProfitToggle={handleProfitToggle}
        />
        {/* )} */}

        {/* STARTUP LIST */}
        <div className="companies__main">
          {/* {isAdmin && ( */}
          <>
            <div style={{ width: "100%" }}>
              <div style={{ fontWeight: 700 }}>Favorites</div>
              <div
                style={{
                  width: 250,
                  height: isSafari ? 2 : 1,
                  background: "black",
                }}
              ></div>
              <div
                style={{ marginTop: 20, width: "100%" }}
                className="companies__grid"
              >
                {favoriteStartups.length > 0 ? (
                  <>
                    {data.map((startup) => {
                      if (!favoriteStartups.includes(startup._id)) return null; // Skip startups that don't match the condition

                      return (
                        <div
                          className="elem__company"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            maxWidth: "100%",
                          }}
                          key={startup._id}
                          onClick={() =>
                            handleStartupClick(
                              startup.urlAddress !== ""
                                ? startup.urlAddress
                                : startup._id
                            )
                          }
                        >
                          {/* Startup photo */}
                          <div className="company__logo">
                            {startup.imageLogo ? (
                              <img
                                src={`${startup.imageLogo}`}
                                alt="biglogo"
                              />
                            ) : (
                              <img
                                style={{
                                  width: 90,
                                  height: 100,
                                  marginTop: 5,
                                }}
                                src={projectImages.bigLogoImg}
                                alt="bigLogoImg1"
                              />
                            )}
                          </div>
                          <div
                            className="company__desc"
                            style={{ width: "100%" }}
                          >
                            <div
                              style={{ marginBottom: 7 }}
                              className="top__part"
                            >
                              {/* Startup title */}
                              <div
                                style={{
                                  fontWeight: 600,
                                  fontSize: 24,
                                  wordBreak: "break-word",
                                }}
                              >
                                {startup.title.length < 24
                                  ? startup.title
                                  : startup.title.substring(0, 25) + "..."}
                              </div>
                              {/* Startup subtitle */}
                              <span>
                                {startup.subTitle.length < 24
                                  ? startup.subTitle
                                  : startup.subTitle.substring(0, 35) + "..."}
                              </span>
                            </div>
                            <div className="desc__">
                              {/* Startup text */}
                              <p>
                                {startup.description.length < 189
                                  ? startup.description
                                  : startup.description.substring(0, 190) +
                                    "..."}
                              </p>
                              {/* Startup tags */}
                              <p>
                                Tags:{" "}
                                {startup.tagList
                                  .map((el, index) => {
                                    return el.value;
                                  })
                                  .join(", ")}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div style={{ fontSize: 13, marginTop: 20 }}>
                    No Favorites Yet
                  </div>
                )}
              </div>
              <div style={{ marginBottom: 40 }}>
                <div style={{ fontWeight: 700, marginTop: 40 }}>
                  Filtered Results
                </div>
                <div
                  style={{ width: "100%", height: 1, background: "black" }}
                ></div>
              </div>
            </div>
          </>
          {/* )} */}
          <div className="companies__grid" style={{ width: "100%" }}>
            {/* ELEMENT */}
            {data &&
              data.map((startup) => {
                return (
                  <div
                    className="elem__company "
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      maxWidth: "77%",
                    }}
                    key={startup._id}
                    onClick={() =>
                      handleStartupClick(
                        startup.urlAddress !== ""
                          ? startup.urlAddress
                          : startup._id
                      )
                    }
                  >
                    {/* Startup photo */}
                    <div className="company__logo">
                      {startup.imageLogo ? (
                        <img
                          src={`${startup.imageLogo}`}
                          alt="biglogo"
                        />
                      ) : (
                        <img
                          style={{
                            width: 90,
                            height: 100,
                            marginTop: 5,
                          }}
                          src={projectImages.bigLogoImg}
                          alt="bigLogoImg1"
                        />
                      )}
                    </div>
                    <div className="company__desc">
                      <div style={{ marginBottom: 7 }} className="top__part">
                        {/* Startup title */}
                        <div style={{ fontWeight: 600, fontSize: 24 }}>
                          {startup.title.length < 24
                            ? startup.title
                            : startup.title.substring(0, 25) + "..."}
                        </div>
                        {/* Startup subtitle */}
                        <span>
                          {startup.subTitle.length < 24
                            ? startup.subTitle
                            : startup.subTitle.substring(0, 35) + "..."}
                        </span>
                      </div>
                      <div className="desc__">
                        {/* Startup text */}
                        <p>
                          {startup.description.length < 189
                            ? startup.description
                            : startup.description.substring(0, 190) + "..."}
                        </p>
                        {/* Startup tags */}
                        <p>
                          Tags:{" "}
                          {startup.tagList
                            .map((el, index) => {
                              return el.value;
                            })
                            .join(", ")}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {!haveDataValue ? (
            <div
              style={{
                width: "100%",
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontWeight: 800 }}>No Startups Found</div>
              <Link style={{ color: "blue" }} to="/addCompany">
                Create a new startup!
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Home;
