import React from "react";
import { Link } from "react-router-dom";
import projectImages from "../style/projectImages";
import Button from "@mui/material/Button";
import "./AlertPopup.css";

const AlertPopup = ({ handleOpenAlertPopup, title, handleDeleteClick }) => {
  return (
    <div>
      <div className="modal__wrapper">
        <div className="inner__modal">
          <div
            className="modal__box data__room"
            style={{ maxWidth: 350, height: 200, padding: 14 }}
          >
            <div
              className="head__"
              style={{
                transform: "none",
                justifyContent: "unset",
                paddingBottom: 10,
              }}
            >
              <Link style={{ cursor: "default", transform: "none" }}>
                <img
                  style={{ maxWidth: 25 }}
                  src={projectImages.warning}
                  alt="warning"
                />
              </Link>
              <div style={{ marginLeft: 10, fontWeight: 700 }}>Warning</div>
            </div>
            <div style={{ fontSize: 18, height: 75 }}>
              Are you sure you want to delete <strong>"{title}"</strong>?
            </div>
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: 10 }}
                onClick={handleOpenAlertPopup}
              >
                Cancel
              </Button>
              <Button
                onClick={handleDeleteClick}
                variant="contained"
                color="error"
              >
                Delete Startup
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertPopup;
