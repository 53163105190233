import React, { useEffect, useState, useRef } from "react";
import projectImages from "../../style/projectImages";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactToastify from "../../hooks/useReactToastify";
import Loader from "../Loader";
import "./DataRoomAccessSetupComponent.css";

const DataRoomAccessSetupComponent = ({
  handleChangeDataRoomAccessView,
  id,
}) => {
  const token = JSON.parse(localStorage.getItem("userToken"));

  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [dataAccess, setDataAccess] = useState(null);

  const [viewerList, setViewerList] = useState([]);
  const [coFoundersList, setCoFoundersList] = useState([]);

  // Function for switching the access state
  const toggleAccess = (user) => {
    let newViwerList = [];
    if (dataAccess.includes(user)) {
      newViwerList = dataAccess.filter((item) => item !== user);
    } else {
      newViwerList = [...dataAccess, user];
    }
    setDataAccess(newViwerList);
  };

  useEffect(() => {
    const getStartupBody = async () => {
      setIsLoadingSpinner(true);
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/startups/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { coFounder, viewer, dataAccess } = response.data.data.startup;

      setDataAccess(dataAccess);
      setViewerList(viewer);
      setCoFoundersList(coFounder);
      setIsLoadingSpinner(false);
    };

    getStartupBody();
  }, [id]);

  const handeSendUpdateAccess = async () => {
    setIsLoadingSpinner(true);
    try {
      const requestData = {
        dataAccess: dataAccess,
        viewer: viewerList,
        coFounder: coFoundersList,
      };
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/startups/updateAccess/${id}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      ReactToastify("success", "Access successfully updated");
    } catch (error) {
      ReactToastify("warn", "Error while updating access");
    } finally {
      setIsLoadingSpinner(false);
    }
  };

  const handleSelectChange = (user, event) => {
    const selectedRole = event.target.value;

    if (selectedRole === "Co-founder") {
      setCoFoundersList((prevCoFounders) => {
        if (!prevCoFounders.includes(user)) {
          return [...prevCoFounders, user];
        } else {
          return prevCoFounders;
        }
      });

      setViewerList((prevViewers) =>
        prevViewers.filter((vUser) => vUser !== user)
      );
    } else if (selectedRole === "Viewer") {
      setViewerList((prevViewers) => {
        if (!prevViewers.includes(user)) {
          return [...prevViewers, user];
        } else {
          return prevViewers;
        }
      });

      setCoFoundersList((prevCoFounders) =>
        prevCoFounders.filter((cfUser) => cfUser !== user)
      );
    }
  };

  const [isVisible, setIsVisible] = useState(true);
  const bottomElementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // If the element is visible, set isVisible to false
      setIsVisible(!entries[0].isIntersecting);
    });

    observer.observe(bottomElementRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <>
      {isLoadingSpinner && <Loader />}
      <div className="modal__wrapper">
        <div className="inner__modal">
          <div className="modal__box data__room data-room-mod">
            <div className="head__">
              <p
                className="w-full flex"
                style={{ justifyContent: "space-between" }}
              >
                <div className="flex">
                  <span>
                    <img
                      src={projectImages.setting}
                      alt="foot"
                      style={{ maxWidth: 40, height: 30 }}
                    />
                  </span>
                  Access setup
                </div>
              </p>

              <Link onClick={handleChangeDataRoomAccessView}>
                <img src={projectImages.closeSmall} alt="closesmall" />
              </Link>
            </div>
            <div
              className="table-body min-w-full leading-normal mt-4"
              style={{ maxHeight: 360, overflowY: "auto" }}
            >
              {coFoundersList.length === 0 && viewerList.length === 0 ? (
                <div className="text-center p-4">
                  Use the Share button to provide data room access
                </div>
              ) : (
                <>
                  <thead>
                    <tr>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        E-mail
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Role
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Dataroom Access
                      </th>
                    </tr>
                  </thead>

                  {coFoundersList.map((user) => {
                    return (
                      <tbody>
                        <tr>
                          <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {user}
                            </p>
                          </td>
                          <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                            <select
                              value="Co-founder"
                              onChange={(event) =>
                                handleSelectChange(user, event)
                              }
                              className="border bg-white rounded px-3 py-1 outline-none"
                            >
                              <option>Co-founder</option>
                              <option>Viewer</option>
                            </select>
                          </td>
                          <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                            <button
                              className="text-indigo-600 hover:text-indigo-900 flex items-center justify-center"
                              onClick={toggleAccess}
                            >
                              {dataAccess.includes(user) ? (
                                <div className="w-5 h-5 bg-blue-100 rounded flex items-center justify-center grayscale">
                                  <img
                                    src={projectImages.check_blue}
                                    alt="Access granted"
                                    className="w-3 h-3 grayscale"
                                  />
                                </div>
                              ) : (
                                <div className="w-5 h-5 bg-red-100 rounded flex items-center justify-center">
                                  <img
                                    src={projectImages.deleteButton}
                                    alt="Access denied"
                                    className="w-4 h-4"
                                  />
                                </div>
                              )}
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                  {viewerList.map((user) => {
                    return (
                      <tbody>
                        <tr>
                          <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {user}
                            </p>
                          </td>
                          <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                            <select
                              value="Viewer"
                              onChange={(event) =>
                                handleSelectChange(user, event)
                              }
                              className="border bg-white rounded px-3 py-1 outline-none"
                            >
                              <option>Viewer</option>
                              <option>Co-founder</option>
                            </select>
                          </td>
                          <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                            <button
                              className="text-indigo-600 hover:text-indigo-900 flex items-center justify-center"
                              onClick={() => toggleAccess(user)}
                            >
                              {dataAccess.includes(user) ? (
                                <div className="w-5 h-5 bg-blue-100 rounded flex items-center justify-center">
                                  <img
                                    src={projectImages.check_blue}
                                    alt="Access granted"
                                    className="w-3 h-3"
                                  />
                                </div>
                              ) : (
                                <div className="w-5 h-5 bg-red-100 rounded flex items-center justify-center">
                                  <img
                                    src={projectImages.deleteButton}
                                    alt="Access denied"
                                    className="w-4 h-4"
                                  />
                                </div>
                              )}
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </>
              )}

              <div ref={bottomElementRef}></div>
            </div>

            <div className="flex justify-end items-center pt-4">
              {isVisible && (
                <div
                  className="text-center  text-sm text-blue-600 cursor-pointer hover:underline"
                  style={{ marginRight: 20 }}
                >
                  Scroll down to see more
                </div>
              )}
              {coFoundersList.length === 0 && viewerList.length === 0 ? null : (
                <button
                  style={{ width: "16%" }}
                  onClick={() => handeSendUpdateAccess()}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataRoomAccessSetupComponent;
