import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFavoriteStartups } from "../store/thunk/favoriteStartups";
import { imageTeam } from "./AddCompany";
import { GrFormAdd } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { RxCross2, RxPlus } from "react-icons/rx";
import { useForm } from "react-hook-form";
import UseFetchStartups from "../hooks/useFetchStartups";
import projectImages from "../style/projectImages";
import ReactToastify from "../hooks/useReactToastify";
import ShareStartup from "../components/ShareStartup";
import SelectPhoto from "../components/SelectPhoto";
import ShowContact from "../components/ShowContact";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "axios";
import {
  imageDeviceSpan,
  reactFormValidation,
  reactFormValidationEdit,
  tagsButtonStyle,
} from "../style/setStyles";
import Metrics from "../components/Metrics";
import usePageTimer from "../hooks/usePageTimer";
import SettingMenu from "../components/SettingMenu";
import {
  suggestedCompetitorList,
  suggestedIndustrysList,
  suggestedTagsList,
} from "../data/data";
import "./StartupScreen.css";
import useEscKeyListener from "../hooks/useEscKeyListener";
import useEmailAdditionModal from "../hooks/useEmailAdditionModal";
import ConfirmEmailAdditionModal from "../components/EmailConfirmationModal";
import useAccessLevel from "../hooks/useAccessLevel";

const StartupScreen = () => {
  const token = JSON.parse(localStorage.getItem("userToken"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  ////////////////////////////////////////////////////////
  // <<========= Edit mode manager ================>> START
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const firstSaveParam = searchParams.get("firstSave");

  const [editMode, setEditMode] = useState(false);
  const [firstSave, setFirstSave] = useState(!!firstSaveParam);

  // Check if the startup ID is present in the favoriteStartups.data list
  useEffect(() => {
    dispatch(getFavoriteStartups({ _id }));
  }, []);

  useEffect(() => {
    // At the first rendering of the component, you can set firstSave to true if editModeParam is "true"
    setFirstSave(!!firstSaveParam);
  }, [firstSaveParam]);
  // Required to avoid a warning when we use the publish/non-publish function
  const [isHandleSubmitActive, setIsHandleSubmitActive] = useState(false);
  // Required to solve visual design problems when using the Safari browser
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // Required for click tracking logic outside the dropdown menu
  const startupScreenRef = useRef(null);
  const isUpdatedByEffectRef = useRef(false);
  // URL Address startup
  const { urlAddress } = useParams();
  // ID startup
  const [id, setId] = useState(null);
  // Photo selected or not selected status
  const [selectPhoto, setSelectPhoto] = useState(null);
  // State that checks whether the data is loaded or not
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  // Used in the component <Navbar/>
  const [isStartupScreen, setIsStartupScreen] = useState(true);
  // Hook that counts the time spent on the page
  usePageTimer(id);
  // The state of the modal window
  const [openSelectPhoto, setOpenSelectPhoto] = useState(false);
  // Photo type (logo, main photo, etc.)
  const [imageType, setImageType] = useState("");
  // Whether there is access or not
  const [hasAccess, setHasAccess] = useState(false);
  // We need to avoid the problem of not updating the "ToogleMenu.jsx" when creating a new "Startup"
  UseFetchStartups();
  // The state of the spinner
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(true);
  // Startup owner
  const [createdBy, setCreatedBy] = useState("");
  const [coFounder, setCoFounder] = useState([]);
  const [viewer, setViewer] = useState(null);
  const [dataRoomAccess, setDataRoomAccess] = useState(null);
  // Opens / closes the DataRoom popup
  const [showDataRoom, setShowDataRoom] = useState(false);
  // Opens / closes the Contact popup
  const [showContact, setShowContact] = useState(false);
  // Opens / closes the Share popup
  const [showShare, setShowShare] = useState(false);
  // Opens / closes the Metrics popup
  const [showMetrics, setShowMetrics] = useState(false);
  const { isAdmin, _id, email, chatToken, name, additionalEmails } =
    useSelector((state) => state.user.data || {});

  // Favorite Startups
  const favStartups = useSelector((state) => state.favoriteStartups.data);

  // Values for inputs forms
  const [newgoogleStore, setNewGoogleStore] = useState("");
  const [newappStore, setNewAppStore] = useState("");
  // Startup URL
  const [startupUrlAddress, setStartupUrlAddress] = useState("");
  // information about startup for display on screen
  const [startupData, setStartupData] = useState(null);

  // Title
  const [newTitle, setNewTitle] = useState("");
  const [newImageLogo, setNewImageLogo] = useState(null);
  const [newImageMain, setNewImageMain] = useState(null);
  const [newImagePhone, setNewImagePhone] = useState(null);
  const [newImageDevice, setNewImageDevice] = useState(null);
  // SubTitle
  const [newSubTitle, setNewSubTitle] = useState("");
  // Description
  const [newDescription, setNewDescription] = useState("");
  // Features
  const [newFeatures, setNewFeatures] = useState([]);
  /////////////////////////////
  // <<====== Tags ======> START
  const [tags, setTags] = useState("");
  const [newtagList, setNewtagList] = useState([]);
  const [inputClicked, setInputClicked] = useState(false);
  const [suggestedTags, setSuggestedTags] = useState(suggestedTagsList);
  // <<===================> END
  /////////////////////////////
  // <<==== Industry ====> START
  const [industrys, setIndustrys] = useState("");
  const [newindustrys, setNewindustrys] = useState([]);
  const [inputClickedIndustry, setInputClickedIndustry] = useState(false);
  const [suggestedIndustrys, setSuggestedIndustrys] = useState(
    suggestedIndustrysList
  );
  // <<====================> END
  //////////////////////////////
  // <<=== Competitors ===> START
  const [competitors, setCompetitors] = useState("");
  const [newcompetitors, setNewcompetitors] = useState([]);
  const [inputClickedCompetitor, setInputClickedCompetitor] = useState(false);
  const [suggestedCompetitor, setSuggestedCompetitor] = useState(
    suggestedCompetitorList
  );
  // <<====================> END
  //////////////////////////////
  // AddressableMarket
  const [newAddressableMarket, setNewAddressableMarket] = useState("");
  // Incorporated
  const [newIncorporated, setNewIncorporated] = useState("");
  // Advantages
  const [newAdvantages, setNewAdvantages] = useState([]);
  // Teams
  const [newTeams, setNewTeams] = useState([{ title: "", text: "", link: "" }]);
  const [newTeamImages, setNewTeamImages] = useState([]);
  // Fundraise Metrics
  const [newFundraiseMetrics, setNewFundraiseMetrics] = useState({
    currentStage: "",
    raising: "",
    raisedToDate: "",
    useOfFunds: "",
  });
  // Business Metrics
  const [newBusinessMetrics, setNewBusinessMetrics] = useState({
    contractsValue: "",
    currentRevenue: "",
    users: "",
    monthlyCashburn: "",
  });
  // Product Metrics
  const [newProductMetrics, setNewProductMetrics] = useState({
    costOfAcquisition: "",
    lifeTimeValue: "",
    appPerCustomer: "",
    cogsPerUnit: "",
  });

  // State for new photos
  const [selectedLogoImage, setSelectedLogoImage] = useState(null);
  const [selectedMainImage, setSelectedMainImage] = useState(null);
  const [selectedPhoneImage, setSelectedPhoneImage] = useState(null);
  const [selectedDeviceImage, setSelectedDeviceImage] = useState(null);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  // <<=========================================== FEATURES =========================================>> START
  const handleFeatureChange = (index, newValue) => {
    const updatedFeatures = [...newFeatures];
    updatedFeatures[index] = newValue;
    setNewFeatures(updatedFeatures);
  };
  // <<===============================================================================================>> END
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  // <<================ The part responsible for logic : TAGS, INDUSTRY, COMPETITORS ================>> START

  // Function for ADDING a new item to the list
  const addNewItem = (inputValue, itemList, setItemList) => {
    const trimmedValue = inputValue.trim();
    // Check if the item already exists in the list
    const isItemAlreadyExists = itemList.some(
      (item) => item.value.trim().toLowerCase() === trimmedValue.toLowerCase()
    );
    // Adding a new element if it does not exist and is not an empty string
    if (!isItemAlreadyExists && trimmedValue !== "") {
      const newItem = {
        id: Date.now(),
        value: trimmedValue,
      };
      const newList = [...itemList, newItem];
      setItemList(newList);
    }
  };
  // Function to REMOVE an item from the list by index
  const removeItem = (index, itemList, setItemList) => {
    const newList = [...itemList];
    newList.splice(index, 1);
    setItemList(newList);
  };

  // Tags
  const handleAddTag = () => {
    addNewItem(tags, newtagList, setNewtagList);
    setTags("");
  };

  const handleRemoveTag = (index) => {
    removeItem(index, newtagList, setNewtagList);
  };

  const handleSelectSuggestedTag = (tag) => {
    addNewItem(tag.trim(), newtagList, setNewtagList);
  };

  // Industry
  const handleAddIndustry = () => {
    addNewItem(industrys, newindustrys, setNewindustrys);
    setIndustrys("");
  };

  const handleRemoveIndustries = (index) => {
    removeItem(index, newindustrys, setNewindustrys);
  };

  const handleSelectSuggestedIndustry = (industry) => {
    addNewItem(industry.trim(), newindustrys, setNewindustrys);
  };

  // Competitors
  const handleAddCompetitors = () => {
    addNewItem(competitors, newcompetitors, setNewcompetitors);
    setCompetitors("");
  };

  const handleRemoveCompetitors = (index) => {
    removeItem(index, newcompetitors, setNewcompetitors);
  };

  const handleSelectSuggestedCompetitor = (competitor) => {
    addNewItem(competitor.trim(), newcompetitors, setNewcompetitors);
  };

  // <<==================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<===================================== ADVANTAGE ==================================>> START
  const handleAddAdvantage = () => {
    if (newAdvantages.length < 4) {
      const updatedAdvantages = [...newAdvantages];
      updatedAdvantages.push({ title: "", text: "", isRemovable: true });
      setNewAdvantages(updatedAdvantages);
    }
  };

  const handleRemoveAdvantage = (index) => {
    if (index === 0) return;
    const updatedAdvantages = [...newAdvantages];
    updatedAdvantages.splice(index, 1);
    setNewAdvantages(updatedAdvantages);
  };

  const handleAdvantageChange = (index, field, value) => {
    const updatedAdvantages = [...newAdvantages];
    updatedAdvantages[index][field] = value;
    setNewAdvantages(updatedAdvantages);
  };
  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<=================================== TEAM ==========================================>> START
  const handleTeamChange = (index, field, value) => {
    // value = value.trim();
    const updatedTeams = [...newTeams];
    updatedTeams[index][field] = value;
    setNewTeams(updatedTeams);
  };
  const handleRemoveTeam = (index) => {
    if (index === 0) {
      return; // If index = 0, return from the function without deleting the command
    }

    const updatedTeams = [...newTeams];
    updatedTeams.splice(index, 1);
    setNewTeams(updatedTeams);
  };
  const handleAddTeam = () => {
    if (newTeams.length < 6) {
      const newTeamEntity = {
        title: "",
        text: "",
        link: "",
      };
      const updatedTeams = [...newTeams, newTeamEntity];
      setNewTeams(updatedTeams);
    }
  };
  const handleTeamImageChange = (index, file) => {
    const updatedTeamImages = [...newTeamImages];
    updatedTeamImages[index] = file;
    setNewTeamImages(updatedTeamImages);

    const updatedTeams = [...newTeams];
    updatedTeams[index].image = file;
    setNewTeams(updatedTeams);
  };
  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<=========================== Uploading single startup data =========================>> START
  useEffect(() => {
    setIsLoadingSpinner(true);
    const fetchStartupData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/startups/${urlAddress}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const fetchedStartupData = response.data.data.startup;
        const formattedFeatures = fetchedStartupData.features.map(
          (feature) => feature
        );
        const formattedTags = fetchedStartupData.tagList.map((tag) => tag);
        const formattedIndustrys = fetchedStartupData.industrysList.map(
          (industry) => industry
        );
        const formattedCompetitors = fetchedStartupData.competitorsList.map(
          (competitor) => competitor
        );
        const formattedAdvantages = fetchedStartupData.advantages.map(
          (advantage) => advantage
        );
        setId(response.data.data.startup._id);
        setStartupUrlAddress(response.data.data.startup.urlAddress);
        setStartupData(fetchedStartupData);
        setNewFeatures(formattedFeatures);
        setNewtagList(formattedTags);
        setNewindustrys(formattedIndustrys);
        setNewcompetitors(formattedCompetitors);
        setNewAdvantages(formattedAdvantages);
        setViewer(response.data.data.startup.viewer);
        setDataRoomAccess(response.data.data.startup.dataAccess);
        setCreatedBy(response.data.data.startup.createdBy);
        setCoFounder(response.data.data.startup.coFounder);
        setNewGoogleStore(response.data.data.startup.googleStore);
        setNewAppStore(response.data.data.startup.appStore);
        setSelectedByProfit(response.data.data.startup.selectedByProfit);
        // Data uploaded
        setIsDataLoaded(true);
      } catch (error) {
        console.error(error);
        navigate(`/home`);
      }
    };
    fetchStartupData();
    setIsLoadingSpinner(false);
  }, [urlAddress]);

  // Calling handleEditClick() after loading data
  useEffect(() => {
    if (firstSave && isDataLoaded) {
      handleEditClick();
    }
  }, [firstSave, isDataLoaded]);
  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<================ Change the appearance of the EDIT => SAVE button =================>> START
  const handleEditClick = async (viewerClick = false) => {
    // If this is the moment of startup creation, then we ignore the access,
    // because then the HOOK will not have time to receive the data.
    if (accessStatus !== "Full access" && !firstSave) {
      ReactToastify("warn", "You do not have permission to edit this startup");
      return;
    } else {
      setEditMode(true);
      setNewTitle(title);
      setNewSubTitle(subTitle);
      setNewDescription(description);
      setNewAddressableMarket(adressableMarket);
      setNewIncorporated(incorporated);
      setNewImageLogo(imageLogo);
      setNewImageMain(imageMain);
      setNewImagePhone(imagePhone);
      setNewImageDevice(imageDevice);
      setNewFundraiseMetrics(fundraiseMetrics);
      setNewBusinessMetrics(businessMetrics);
      setNewFundraiseMetrics({
        currentStage: fundraiseMetrics[0].currentStage,
        raising: fundraiseMetrics[0].raising,
        raisedToDate: fundraiseMetrics[0].raisedToDate,
        useOfFunds: fundraiseMetrics[0].useOfFunds,
      });
      setNewBusinessMetrics({
        contractsValue: businessMetrics[0].contractsValue,
        currentRevenue: businessMetrics[0].currentRevenue,
        users: businessMetrics[0].users,
        monthlyCashburn: businessMetrics[0].monthlyCashburn,
      });
      setNewProductMetrics({
        costOfAcquisition: productMetrics[0].costOfAcquisition,
        lifeTimeValue: productMetrics[0].lifeTimeValue,
        appPerCustomer: productMetrics[0].appPerCustomer,
        cogsPerUnit: productMetrics[0].cogsPerUnit,
      });
      const formattedTeams = teams.map((team) => ({
        title: team.title,
        text: team.text,
        link: team.link,
      }));
      const formattedTeamsImages = teams.map((team) => team.image);
      setNewTeams(formattedTeams);
      setNewTeamImages(formattedTeamsImages);
      setNewGoogleStore(googleStore);
      setNewAppStore(appStore);
    }
    return true;
  };
  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<====== Sends a request to delete the startup when you click the delete button =====>> START
  const handleDeleteClick = async () => {
    if (accessStatus !== "Full access") {
      ReactToastify(
        "warn",
        "You do not have permission to delete this startup"
      );
      return;
    } else {
      setIsLoadingSpinner(true);
      try {
        await axios.delete(
          `${process.env.REACT_APP_SERVER_URL}/startups/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Show a notification when the task is completed
        ReactToastify("success", "Successfully deleted");
        setTimeout(() => {
          navigate(`/home`);
        }, 1000);
      } catch (error) {
        ReactToastify("warn", error);
      } finally {
        setIsLoadingSpinner(false);
      }
    }
  };
  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<========== Change the appearance of the SAVE => EDIT + save data button ===========>> START
  const handleSaveClick = async (manualPush = false, publish = false) => {
    try {
      if (manualPush) {
        setIsLoadingSpinner(true);
      }
      // Create a FormData object and add data to it
      const formData = new FormData();
      formData.append("urlAddress", startupUrlAddress);
      formData.append("appStore", newappStore);
      formData.append("googleStore", newgoogleStore);
      formData.append("title", newTitle);
      formData.append("subTitle", newSubTitle);
      formData.append("description", newDescription);
      newFeatures.forEach((feature, index) => {
        formData.append(`features[${index}]`, feature);
      });
      formData.append("tagList", JSON.stringify(newtagList));
      formData.append("industrysList", JSON.stringify(newindustrys));
      formData.append("competitorsList", JSON.stringify(newcompetitors));
      formData.append("adressableMarket", newAddressableMarket);
      formData.append("incorporated", newIncorporated);
      // Check if a new photo is selected
      if (selectedLogoImage) {
        formData.append("imageLogo", newImageLogo);
      }
      if (selectedMainImage) {
        formData.append("imageMain", newImageMain);
      }
      if (selectedPhoneImage) {
        formData.append("imagePhone", newImagePhone);
      }
      if (selectedDeviceImage) {
        formData.append("imageDevice", newImageDevice);
      }
      formData.append("teams", JSON.stringify(newTeams));
      newTeamImages.forEach((image, index) => {
        if (image instanceof File) {
          formData.append(`teamImage${index + 1}`, image);
        }
      });
      formData.append("advantages", JSON.stringify(newAdvantages));
      formData.append("fundraiseMetrics", JSON.stringify(newFundraiseMetrics));
      formData.append("businessMetrics", JSON.stringify(newBusinessMetrics));
      formData.append("productMetrics", JSON.stringify(newProductMetrics));
      formData.append("selectedByProfit", selectedByProfit);
      formData.append("phoneImageStatus", phoneImageStatus);
      formData.append("deviceImageStatus", deviceImageStatus);
      // Execute a PATCH query using FormData
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/startups/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Get data from the server response
      const responseUrlAddress = response.data.data.startup.urlAddress;
      const responseStartup_id = response.data.data.startup._id;

      // If "isUpdatedByEffectRef" = true, then autosave will be hidden
      if (manualPush) {
        setIsLoadingSpinner(true);
        // Update startup status and data after a successful upgrade
        setEditMode(false);
        setShowUnsavedChangesWarning(false);
        // Show notification of successful update
        if (!publish) {
          ReactToastify("success", "Successfully updated");
        }
        setTimeout(() => {
          navigate(
            responseUrlAddress !== ""
              ? `/startups/${responseUrlAddress}`
              : `/startups/${responseStartup_id}`
          );
          window.location.reload();
        }, 1000);
      } else if (isUpdatedByEffectRef.current && firstSave) {
        setEditMode(true);
        setFirstSave(false);
      }
    } catch (error) {
      ReactToastify("warn", "Error");
    }
  };
  // <<===================================================================================>>
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<=========================== Startup autosave logic ===============================>> START

  useEffect(() => {
    let intervalId;

    if (editMode) {
      intervalId = setInterval(async () => {
        isUpdatedByEffectRef.current = true;
        handleSaveClick(false, false);
      }, 10000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [editMode]);

  // <<==================================================================================================================>> END
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // <<== Sends information about the saved startup to the server, including the administrator's userId and startupId ==>> START

  const handleSaveStartup = async () => {
    setIsLoadingSpinner(true);
    try {
      const requestData = {
        adminId: _id,
        startupId: id,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/startups/save-startup`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(getFavoriteStartups({ _id }));
      ReactToastify("success", response.data.message);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingSpinner(false);
    }
  };
  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<==================== Extracting information from startupData ======================>> START

  const {
    title,
    subTitle,
    imageLogo,
    description,
    imageMain,
    imagePhone,
    imagePhoneIsIncluded,
    imageDeviceIsIncluded,
    imageDevice,
    adressableMarket,
    incorporated,
    advantages,
    teams,
    fundraiseMetrics,
    businessMetrics,
    productMetrics,
    googleStore,
    appStore,
    isPublished,
    dataAccess,
  } = startupData || {};

  useEffect(() => {
    imageLogo === undefined
      ? setIsLoadingSpinner(true)
      : setTimeout(() => {
          setIsLoadingSpinner(false);
        }, 300);
  }, [imageLogo]);

  // Check for information availability
  const haveTeamData = teams && teams[0].title !== "";

  // Opens / closes the DataRoom popup
  const handleDataRoomClick = () => {
    if (accessStatus === "Full access" || accessStatus === "Medium access") {
      setShowDataRoom(true);
    } else {
      ReactToastify("warn", "You do not have permission to open Data Room");
    }
  };

  const handleDataRoomClose = () => {
    setShowDataRoom(false);
  };

  // Opens / closes the Contact popup
  const handleContactClick = () => {
    setShowContact(true);
  };
  const handleContactClose = () => {
    setShowContact(false);
  };
  // Opens / closes the Share popup
  const handleShareClick = () => {
    if (isPublished) {
      if (accessStatus === "Full access") {
        setShowShare(true);
      } else {
        ReactToastify("warn", "You do not have permission to open Share");
      }
    } else {
      ReactToastify("warn", "Startup must be published");
    }
  };
  const handleShareClose = () => {
    setShowShare(false);
  };
  // Opens / closes the Metrics popup
  const handleMetricsClick = () => {
    if (accessStatus === "Full access") {
      setShowMetrics(true);
    } else {
      ReactToastify(
        "warn",
        "Metrics are available only for the company's owner"
      );
    }
  };

  const handleMetricsClose = () => {
    setShowMetrics(false);
  };

  // Check if the startup ID is present in the favoriteStartups.data list
  const isFavorite = favStartups.includes(id);

  // <<================ VALIDATION ================>>
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // <<============================================>>

  // <<===== The process of processing photos =====>>
  // <<----Handle Logo Image---->>
  const handleImageChange = (event, imageType) => {
    const file = event.target.files[0];
    setImageType(imageType);
    setSelectPhoto(file);
    setOpenSelectPhoto(true);

    // Clearing the value of the element <input type="file" />
    event.target.value = null;
  };

  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<===== Callback function for getting the selected photo from <SelectPhoto/> ========>> START

  const handleSelectedImage = (selectedImage, selectedImageType) => {
    if (selectedImageType === "logo") {
      setNewImageLogo(selectedImage);
      setSelectedLogoImage(selectedImage);
    } else if (selectedImageType === "main") {
      setNewImageMain(selectedImage);
      setSelectedMainImage(selectedImage);
    } else if (selectedImageType === "phone") {
      setNewImagePhone(selectedImage);
      setSelectedPhoneImage(selectedImage);
    } else if (selectedImageType === "device") {
      setNewImageDevice(selectedImage);
      setSelectedDeviceImage(selectedImage);
    }
    setOpenSelectPhoto(false);
  };

  // Closing a modal window
  const handleCloseSelectedImage = () => {
    // setNewImageLogo(null);
    setOpenSelectPhoto(false);
  };

  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<================= Enable <Loader/> until the page is fully loaded =================>> START

  // Enable <Loader/> until the page is fully loaded
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    // Check if the page is already loaded
    if (document.readyState === "complete") {
      setIsLoaded(true);
      setIsPageLoading(false);
    } else {
      // Add event listener for page load
      const handlePageLoad = () => {
        setIsLoaded(true);
        setIsPageLoading(false);
      };
      window.addEventListener("load", handlePageLoad);

      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener("load", handlePageLoad);
      };
    }
  }, []);

  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<======================== Tracking clicks on the "App Button" ======================>> START

  const [appClicks, setAppClicks] = useState(0);
  const handleAppClicks = (event) => {
    const link = event.currentTarget.getAttribute("href");
    if (link.startsWith("http://") || link.startsWith("https://")) {
      // Open the link in a new window
      window.open(link, "_blank");
    } else {
      // Add the prefix "http://" before opening the link
      window.open(`http://${link}`, "_blank");
    }
    setAppClicks(1);
  };
  useEffect(() => {
    if (!id) {
      // If id is null, stop the execution
      return;
    }
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/metrics/send-info`,
      {
        id,
        appClicks,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAppClicks(0);
  }, [appClicks, id]);

  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<================= Tracking clicks on the "Linkedin Clicked" =======================>> START

  const [linkedinClicked, setLinkedinClicked] = useState(0);
  const handleLinkedinClicked = (event) => {
    event.preventDefault();
    const link = event.currentTarget.getAttribute("href") || "";

    if (!link.trim()) {
      return; // Exit if the reference is empty or contains only spaces
    }

    const normalizedLink = link.trim();
    if (
      normalizedLink.startsWith("http://") ||
      normalizedLink.startsWith("https://")
    ) {
      window.open(normalizedLink, "_blank");
    } else {
      const completeLink = `http://${normalizedLink}`;
      if (completeLink !== "http://") {
        window.open(completeLink, "_blank");
      }
    }
    setLinkedinClicked(1); // Set the state after successfully opening the link
  };

  useEffect(() => {
    if (!id) {
      // If id is null, stop the execution
      return;
    }
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/metrics/send-info`,
      {
        id,
        linkedinClicked,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setLinkedinClicked(0);
  }, [linkedinClicked, id]);

  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<====== Function for converting a string value to a number with comma removal ====>> START

  const parseNumber = (value) => {
    const numberString = value.replace(/,/g, "");
    const parsedNumber = parseFloat(numberString);
    return isNaN(parsedNumber) ? "" : parsedNumber;
  };

  // Showing / hiding an element "SettingMenu.jsx"
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  // <<=================================================================================================>> END
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  // <<== Required to determine if the user clicked outside the zone of the "SettingMenu.jsx" element ==>> START

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      startupScreenRef.current &&
      !startupScreenRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<================ Switching the status Published / Unpublished =====================>> START

  const handleIsPublished = async () => {
    try {
      // await handleSaveClick(false, false);
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/startups/${id}`,
        {
          isPublished: !isPublished,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // if (isPublished) {
      ReactToastify("success", "Success");
      // }
    } catch (error) {
      ReactToastify("warn", error);
    } finally {
      setIsDropdownOpen(false);
      setTimeout(() => {
        navigate(`/startups/${startupUrlAddress ? startupUrlAddress : id}`);
        window.location.reload();
      }, [1000]);
    }
  };

  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<======== Function that is triggered in a component <SettingMenu.jsx/> =============>> START

  const handlePublishSubmit = async () => {
    reset({}, { keepErrors: false }); // Reset the state of the form, including errors
    if (accessStatus !== "Full access") {
      ReactToastify("warn", "You do not have permission");
      return;
    }
    setIsLoadingSpinner(true);
    // if (editMode) {
    //   ReactToastify("warn", "Save the startup first");
    //   setIsLoadingSpinner(false);
    // } else {
    setIsHandleSubmitActive(true);
    if (isPublished) {
      handleIsPublished();
    } else {
      if (editMode) {
        await handleSaveClick(false, false);
        setIsLoadingSpinner(false);
      } else {
        handleEditClick(); // Required to enable EditMode and fill in the value in input
      }
      setTimeout(() => {
        handleSubmit(() => {
          handleIsPublished();
        })();
      }, [1000]);
      setIsLoadingSpinner(false);
    }
  };

  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<========== If a validation error is detected, this message is triggered ===========>> START

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      ReactToastify(
        "warn",
        "Please fill in all required fields and save the form."
      );
      setIsDropdownOpen(false);
      setIsLoadingSpinner(false);
    }
  }, [errors]);

  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<======================= Switching the status Profit / Unprofit ====================>> START

  const [selectedByProfit, setSelectedByProfit] = useState(false);
  const handleProfitToggle = () => {
    setSelectedByProfit((prevState) => !prevState);
  };

  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<=========== Warning about unsaved data when refreshing or closing a page ==========>> START

  // Additional condition to prevent issues with window.location.reload()
  const [showUnsavedChangesWarning, setShowUnsavedChangesWarning] =
    useState(false);

  useEffect(() => {
    // Attach the handleBeforeUnload function to the window's onbeforeunload event
    window.onbeforeunload = handleBeforeUnload;

    // Clean up the event listener when the component unmounts
    return () => {
      window.onbeforeunload = null;
    };
  }, [showUnsavedChangesWarning]);

  useEffect(() => {
    // Check if the editMode is true
    if (editMode) {
      // Set showUnsavedChangesWarning to true
      setShowUnsavedChangesWarning(true);
    } else {
      // Set showUnsavedChangesWarning to false
      setShowUnsavedChangesWarning(false);
    }
  }, [editMode]);

  const handleBeforeUnload = (event) => {
    // Check if editMode is true and showUnsavedChangesWarning is true
    if (editMode && showUnsavedChangesWarning && !isHandleSubmitActive) {
      // Prevent the default behavior
      event.preventDefault();
      // Set the custom message for the confirmation dialog
      event.returnValue = "Save changes before closing the page?";
    }
  };

  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<===================================================================================>> START

  // Using a hook to close a modal window
  useEscKeyListener(setShowDataRoom);
  useEscKeyListener(setShowContact);
  useEscKeyListener(setShowMetrics);
  useEscKeyListener(setShowShare);
  useEscKeyListener(setIsDropdownOpen);

  // <<===================================================================================>> END
  //////////////////////////////////////////////////////////////////////////////////////////
  // <<===========  Logic of resetting the visibility of the phone and device ============>> START
  const [phoneImageStatus, setPhoneImageStatus] =
    useState(imagePhoneIsIncluded);

  useEffect(() => {
    setPhoneImageStatus(imagePhoneIsIncluded);
  }, [imagePhoneIsIncluded]);

  const handleToggleStatusPhoto = async () => {
    setPhoneImageStatus((phoneImageStatus) => !phoneImageStatus);
  };

  const [deviceImageStatus, setDeviceImageStatus] = useState(
    imageDeviceIsIncluded
  );

  useEffect(() => {
    setDeviceImageStatus(imageDeviceIsIncluded);
  }, [imageDeviceIsIncluded]);

  const handleToggleStatusDevice = async () => {
    setDeviceImageStatus((deviceImageStatus) => !deviceImageStatus);
  };
  // <<===============================================================================================>> END
  //     HOOK. This hook extracts URL parameters, decodes them, and uses this data to control a modal's
  //     visibility and make an API request based on user interaction.
  // <<===============================================================================================>> START
  const {
    showModal, // Whether the modal is currently shown
    emailAddress, // Current email address state
    handleCancel, // Function to handle the cancellation action
    companyID, //
  } = useEmailAdditionModal(token);
  // <<===============================================================================================>> END
  //     HOOK. Checking the user's access level to this startup
  // <<===============================================================================================>> START
  const accessStatus = useAccessLevel(
    _id,
    createdBy,
    coFounder,
    email,
    viewer,
    dataAccess,
    additionalEmails
  );
  // <<===============================================================================================>> END
  //    Function that notifies the user if the link he uses is not valid
  // <<===============================================================================================>> START
  useEffect(() => {
    const invalidLinkInfo = localStorage.getItem("invalidLinkInfo");
    if (invalidLinkInfo === "Link is not valid") {
      ReactToastify("warn", invalidLinkInfo);
      localStorage.removeItem("invalidLinkInfo");
    }
  }, []);
  // <<===============================================================================================>> END

  return (
    <>
      <Navbar
        setIsHandleSubmitActive={setIsHandleSubmitActive}
        handlePublishSubmit={handlePublishSubmit}
        isUpdatedByEffectRef={isUpdatedByEffectRef}
        setIsDropdownOpen={setIsDropdownOpen}
        handleDeleteClick={handleDeleteClick}
        handleSaveStartup={handleSaveStartup}
        startupScreenRef={startupScreenRef}
        isStartupScreen={isStartupScreen}
        handleEditClick={handleEditClick}
        handleSaveClick={handleSaveClick}
        isDropdownOpen={isDropdownOpen}
        handleSubmit={handleSubmit}
        accessStatus={accessStatus}
        isDataLoaded={isDataLoaded}
        isPublished={isPublished}
        SettingMenu={SettingMenu}
        setEditMode={setEditMode}
        isFavorite={isFavorite}
        editMode={editMode}
        title={title}
      />
      {isLoadingSpinner && <Loader />}
      {isPageLoading && <Loader />}
      {!isPageLoading && !isLoaded && <Loader />}
      {/* {hasAccess ? ( */}
      <>
        {openSelectPhoto && (
          <SelectPhoto
            imageType={imageType}
            imageLogo={selectPhoto}
            onClose={handleCloseSelectedImage}
            onImageSelect={handleSelectedImage}
          />
        )}
        {showModal && (
          <ConfirmEmailAdditionModal
            isOpen={showModal}
            onCancel={() => handleCancel()}
            email={emailAddress}
            companyID={companyID}
            startupName={title}
          />
        )}
        <div className="main__wrapper zoom-out">
          <div className="container">
            <div className="outer__main">
              <div
                style={{ marginBottom: editMode ? 35 : 40 }}
                className="main__top "
              >
                <div className="top__desc">
                  <ul>
                    {/* {isAdmin && ( */}
                    <>
                      {/* CONTACT && CONTACT POPUP */}
                      {!(coFounder.includes(email) || _id === createdBy) && (
                        <li>
                          {showContact && (
                            <ShowContact
                              id={id}
                              name={name}
                              email={email}
                              title={title}
                              chatToken={chatToken}
                              imageLogo={imageLogo}
                              startupData={startupData}
                              setIsLoadingSpinner={setIsLoadingSpinner}
                              handleContactClose={handleContactClose}
                            />
                          )}
                          <Link href="#" className="contact__button">
                            <button
                              style={{ display: "flex" }}
                              onClick={handleContactClick}
                            >
                              <span>
                                <img
                                  src={
                                    showContact
                                      ? projectImages.comment
                                      : projectImages.commentWhite
                                  }
                                  alt="foot"
                                />
                              </span>
                              <div style={{ marginTop: 1 }}>Contact</div>
                            </button>
                          </Link>
                        </li>
                      )}
                    </>
                    {/* )} */}
                    {/* SHARE && SHARE POPUP */}
                    <li>
                      {showShare && (
                        <ShareStartup
                          imageLogo={imageLogo}
                          handleShareClose={handleShareClose}
                          title={title}
                          id={id}
                        />
                      )}
                      <Link href="#" className="share__button">
                        <button
                          style={{ display: "flex" }}
                          onClick={() => {
                            accessStatus === "Full access"
                              ? handleShareClick()
                              : ReactToastify(
                                  "warn",
                                  "You do not have permission"
                                );
                          }}
                        >
                          <span>
                            <img src={projectImages.foot2} alt="foot" />
                          </span>
                          <div style={{ marginTop: 1 }}>Share</div>
                        </button>
                      </Link>
                    </li>
                    {/* DATAROOM && SHARE DATAROOM */}
                    <li>
                      <Link className="room__button">
                        <button
                          style={{ display: "flex" }}
                          onClick={handleDataRoomClick}
                        >
                          <span>
                            <img
                              src={
                                showDataRoom
                                  ? projectImages.folderBlack
                                  : projectImages.folderWhite
                              }
                              alt="foot"
                            />
                          </span>
                          <div style={{ marginTop: 1 }}>Data Room</div>
                        </button>
                      </Link>
                    </li>
                    {/* METRICS && METRICS POPUP */}
                    {accessStatus === "Full access" && (
                      <li>
                        {showMetrics && (
                          <Metrics
                            handleMetricsClose={handleMetricsClose}
                            id={id}
                          />
                        )}
                        <Link className="metrics__button">
                          <button
                            style={{ display: "flex" }}
                            onClick={handleMetricsClick}
                          >
                            <span>
                              <img
                                src={
                                  showMetrics
                                    ? projectImages.barChatBlack
                                    : projectImages.barChatWhite
                                }
                                alt="foot"
                              />
                            </span>
                            <div style={{ marginTop: 1 }}>Metrics</div>
                          </button>
                        </Link>
                      </li>
                    )}
                    {/* Add to favourites */}
                    <li>
                      {isFavorite ? (
                        <Link
                          onClick={handleSaveStartup}
                          style={{
                            left: -4,
                            top: 1,
                            position: "relative",
                          }}
                        >
                          <span style={{ width: 23, height: 23 }}>
                            <img
                              src={projectImages.favoritesBlack}
                              alt="heart"
                            />
                          </span>
                          <div style={{ marginTop: 1 }}>Remove</div>
                        </Link>
                      ) : (
                        <Link
                          style={{
                            left: -4,
                            top: 0,
                            position: "relative",
                          }}
                          onClick={handleSaveStartup}
                        >
                          <span
                            style={{
                              width: 25,
                              height: 25,
                              marginRight: 4,
                              marginLeft: 7,
                            }}
                          >
                            <img
                              src={projectImages.favoritesWhite}
                              alt="heart"
                            />
                          </span>
                          <div style={{ marginTop: 1 }}>Favourites</div>
                        </Link>
                      )}
                    </li>
                  </ul>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "unset",
                    }}
                    className="head__"
                  >
                    {/* TITLE */}
                    <div
                      style={{
                        // width: "96%",
                        display: "flex",
                        flexDirection: "revert",
                      }}
                      className="info__"
                    >
                      {editMode ? (
                        <h2>
                          <textarea
                            className="editable"
                            style={{
                              maxWidth: "100%",
                              textAlign: "left",
                              overflowWrap: "break-word",
                              resize: "none",
                              marginBottom: 0,
                            }}
                            type="text"
                            {...register("newTitle", {
                              required: "Title is required",
                            })}
                            value={newTitle}
                            onChange={(e) => setNewTitle(e.target.value)}
                            maxLength={100}
                            onBlur={() => {
                              isUpdatedByEffectRef.current = true;
                              handleSaveClick(false, false);
                            }}
                          />
                          {errors.newTitle && (
                            <div style={reactFormValidationEdit}>
                              *{errors.newTitle.message}
                            </div>
                          )}
                        </h2>
                      ) : (
                        <h2
                          className="title__container title__container__mod"
                          style={{
                            marginRight: 12,
                            textAlign: "left",
                            resize: "none",
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                          }}
                        >
                          {title}
                        </h2>
                      )}
                      {/* LOGO IMAGE */}
                      {editMode ? (
                        <>
                          {selectedLogoImage ? (
                            <img
                              style={{ maxWidth: 60, maxHeight: 60 }}
                              src={URL.createObjectURL(selectedLogoImage)}
                              alt="selectedImage"
                            />
                          ) : (
                            <>
                              {imageLogo ? (
                                <img
                                  style={{ width: 60, height: 60 }}
                                  src={`${imageLogo}`}
                                  alt="imagelogo"
                                />
                              ) : (
                                <img
                                  style={{ width: 60, height: 60 }}
                                  src={projectImages.bigLogoImg}
                                  alt="bigLogoImg1"
                                />
                              )}
                            </>
                          )}
                          <label
                            style={{ maxWidth: 50 }}
                            htmlFor="teamFIleInput"
                          >
                            <img
                              style={{
                                maxWidth: 45,
                                position: "relative",
                                right: 48,
                              }}
                              src={projectImages.uploadImg}
                              alt="upload"
                            />
                            <input
                              style={{ display: "none" }}
                              name="image"
                              id="teamFIleInput"
                              type="file"
                              onChange={(event) =>
                                handleImageChange(event, "logo")
                              }
                            />
                          </label>
                        </>
                      ) : (
                        <span
                          style={{
                            padding: 0,
                            width: "10%",
                            height: 60,
                            minWidth: 60,
                            minHeight: 60,
                          }}
                        >
                          {imageLogo ? (
                            <img
                              style={{ width: 60, height: 60 }}
                              src={`${imageLogo}`}
                              alt="imagelogo"
                            />
                          ) : (
                            <img
                              style={{ width: 60, height: 60 }}
                              src={projectImages.bigLogoImg}
                              alt="bigLogoImg1"
                            />
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    {/* newSubTitle */}
                    {editMode ? (
                      <p
                        style={{
                          width: "100%",
                          fontSize: 24,
                          fontWeight: 600,
                          marginBottom: 5,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <textarea
                          className="editable"
                          type="text"
                          {...register("newSubTitle", {
                            required: "Sub title is required",
                          })}
                          style={{ width: "100%" }}
                          value={newSubTitle}
                          onChange={(e) => setNewSubTitle(e.target.value)}
                          maxLength={100}
                          onBlur={() => {
                            isUpdatedByEffectRef.current = true;
                            handleSaveClick(false, false);
                          }}
                        />
                        <div
                          style={{ marginTop: 10 }}
                          className="elem__checkbox"
                        >
                          <label className="container__check">
                            <input
                              onChange={handleProfitToggle}
                              checked={selectedByProfit}
                              type="checkbox"
                            />
                            <span className="checkmark"></span>
                            Not for Profit
                          </label>
                        </div>
                        {errors.newSubTitle && (
                          <div style={reactFormValidationEdit}>
                            *{errors.newSubTitle.message}
                          </div>
                        )}
                      </p>
                    ) : (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: 24,
                            resize: "none",
                            overflowWrap: "break-word",
                          }}
                        >
                          {subTitle}
                        </div>
                        {selectedByProfit ? (
                          <div
                            style={{
                              marginLeft: 10,
                              marginTop: 7,
                              fontWeight: 600,
                              fontSize: 14,
                            }}
                          >
                            ● Not for profit
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                  {/* Custom URL */}
                  {editMode ? (
                    <>
                      <p
                        className="tagEdit"
                        style={{ display: "flex", marginTop: 3 }}
                      >
                        <div
                          style={{
                            marginTop: 2,
                            fontWeight: 600,
                            fontSize: 15,
                          }}
                        >
                          onemetric.us/
                        </div>
                        <input
                          style={{ height: 30, width: "100%", fontSize: 15 }}
                          className="editable featureEdit"
                          value={startupUrlAddress}
                          onChange={(e) => {
                            // Remove spaces from the value
                            const trimmedValue = e.target.value
                              .trim()
                              .replace(/[^A-Za-z0-9\s]/g, "");
                            setStartupUrlAddress(trimmedValue);
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          maxLength={25}
                          onBlur={() => {
                            isUpdatedByEffectRef.current = true;
                            handleSaveClick(false, false);
                          }}
                        />
                      </p>
                    </>
                  ) : (
                    <>
                      {startupUrlAddress.length === 0 ? null : (
                        <div style={{ marginTop: 6, fontWeight: 600 }}>
                          https://www.onemetric.us/startups/
                          {startupUrlAddress}
                        </div>
                      )}
                    </>
                  )}
                  {/* TEXT */}
                  {editMode ? (
                    <>
                      <textarea
                        className="editable textAreaDescription"
                        name="big__text"
                        type="text"
                        {...register("newDescription", {
                          required: "Description is required",
                        })}
                        value={newDescription}
                        onChange={(e) => setNewDescription(e.target.value)}
                        maxLength={5000}
                        style={{ marginBottom: 10, width: "100%" }}
                        onBlur={() => {
                          isUpdatedByEffectRef.current = true;
                          handleSaveClick(false, false);
                        }}
                      />
                      {errors.newDescription && (
                        <div style={reactFormValidation}>
                          *{errors.newDescription.message}
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        height: "auto",
                        resize: "none",
                        overflowWrap: "break-word",
                      }}
                      className="textAreaDescription"
                    >
                      {description}
                    </div>
                  )}
                  {/* LINK */}
                  {editMode ? (
                    <div style={{ width: "100%", height: 67 }}>
                      <p
                        className="startup__link"
                        style={{ display: "flex", marginBottom: 5 }}
                      >
                        <span
                          style={{
                            marginTop: 2,
                            fontWeight: 600,
                            width: 140,
                          }}
                        >
                          App Store link:
                        </span>

                        <input
                          className="editable featureEdit"
                          style={{ height: 30, width: "78%" }}
                          name="big__text"
                          type="text"
                          value={newappStore}
                          onChange={(e) =>
                            setNewAppStore(e.target.value.trim())
                          }
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          onBlur={() => {
                            isUpdatedByEffectRef.current = true;
                            handleSaveClick(false, false);
                          }}
                        />
                      </p>
                      <p className="startup__link" style={{ display: "flex" }}>
                        <span
                          style={{
                            marginTop: 2,
                            fontWeight: 600,
                            width: 140,
                          }}
                        >
                          Google Play link:
                        </span>

                        <input
                          className="editable featureEdit"
                          style={{ height: 30, width: "78%" }}
                          name="big__text"
                          type="text"
                          pattern="^[^\s]+$"
                          title="Spaces are not allowed"
                          value={newgoogleStore}
                          onChange={(e) =>
                            setNewGoogleStore(e.target.value.trim())
                          }
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          onBlur={() => {
                            isUpdatedByEffectRef.current = true;
                            handleSaveClick(false, false);
                          }}
                        />
                      </p>
                    </div>
                  ) : (
                    <div style={{ display: "flex", width: "100%", height: 55 }}>
                      <a
                        href={appStore}
                        onAuxClick={handleAppClicks}
                        onClick={handleAppClicks}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {appStore && appStore.trim() !== "" && (
                          <img
                            style={{
                              width: 120,
                              height: 35,
                              marginRight: 10,
                            }}
                            src={projectImages.AppStore}
                            alt="AppStore"
                          />
                        )}
                      </a>
                      <a
                        href={googleStore}
                        onAuxClick={handleAppClicks}
                        onClick={handleAppClicks}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {googleStore && googleStore.trim() !== "" && (
                          <img
                            style={{ width: 120, height: 35 }}
                            src={projectImages.GooglePlay}
                            alt="GooglePlay"
                          />
                        )}
                      </a>
                    </div>
                  )}

                  <div style={{ width: "100%" }} className="list">
                    {/* <--- FEATURES --->> */}
                    <p>Features:</p>
                    <ul
                      style={{ width: "100%", marginLeft: 0, paddingLeft: 0 }}
                    >
                      {editMode ? (
                        <>
                          {newFeatures.map((feature, index) => (
                            <li style={{ width: "100%" }} key={index}>
                              <p
                                style={{ width: "100%" }}
                                data-name={`l${index + 1}`}
                              >
                                {index === 0 &&
                                  errors.features &&
                                  errors.features[0] && (
                                    <div style={reactFormValidationEdit}>
                                      {errors.features[0].message}
                                    </div>
                                  )}
                                <textarea
                                  style={{
                                    resize: "none",
                                    height: 33,
                                    width: "100%",
                                  }}
                                  {...register(`features.${index}`, {
                                    required: {
                                      value: newFeatures[0] === "",
                                      message: "Feature is required",
                                    },
                                  })}
                                  className="editable"
                                  name={`l${index + 1}`}
                                  value={feature}
                                  onChange={(e) =>
                                    handleFeatureChange(index, e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                      e.preventDefault();
                                    }
                                  }}
                                  maxLength={54}
                                  onBlur={() => {
                                    isUpdatedByEffectRef.current = true;
                                    handleSaveClick(false, false);
                                  }}
                                />
                              </p>
                            </li>
                          ))}
                        </>
                      ) : (
                        <>
                          {newFeatures.map((feature, index) => (
                            <li style={{ width: "100%" }} key={index}>
                              <p
                                style={{
                                  width: "100%",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {feature}
                              </p>
                            </li>
                          ))}
                        </>
                      )}
                    </ul>
                    <div className="bottom__desc bottom__descEdit">
                      {/* <--- TAG --->> */}
                      {editMode ? (
                        <div className="tagEdit">
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              height: "fit-content",
                              gap: 4,
                            }}
                          >
                            <div style={{ marginRight: 5 }}>Tags:</div>
                            {newtagList[0] &&
                            newtagList[0].value.trim() === "" ? null : (
                              <>
                                {newtagList.map((tag, index) => (
                                  <div
                                    style={{
                                      backgroundColor: "white",
                                      border: "1px solid black",
                                      display: "flex",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      borderRadius: "8px",
                                      padding: "0 10px",
                                      marginBottom: 4,
                                    }}
                                    key={index}
                                  >
                                    {tag.value}
                                    <button
                                      style={
                                        (tagsButtonStyle,
                                        { alignItems: "center" })
                                      }
                                      onClick={() => handleRemoveTag(index)}
                                    >
                                      <RxCross2
                                        style={{ maxWidth: 15, maxHeight: 15 }}
                                      />
                                    </button>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                          <input
                            className="editable"
                            style={{
                              height: 30,
                              width: "100%",
                              fontStyle: "italic",
                            }}
                            placeholder="GPS, Supply chain, Logistics"
                            name="tags"
                            value={tags}
                            onChange={(e) => setTags(e.target.value)}
                            onClick={() => setInputClicked(true)}
                            onKeyDown={(e) => {
                              if (e.keyCode === 13) {
                                e.preventDefault();
                                handleAddTag();
                              }
                            }}
                            maxLength={57}
                            onBlur={() => {
                              isUpdatedByEffectRef.current = true;
                              handleSaveClick(false, false);
                            }}
                          />
                          {inputClicked && (
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 4,
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              {suggestedTags
                                .filter((tag) =>
                                  tag.toLowerCase().includes(tags.toLowerCase())
                                )
                                .map((tag, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      backgroundColor: "lightgray",
                                      border: "1px solid black",
                                      borderRadius: "8px",
                                      padding: "0 10px",
                                      marginBottom: 4,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleSelectSuggestedTag(tag)
                                    }
                                    onBlur={() => {
                                      isUpdatedByEffectRef.current = true;
                                      handleSaveClick(false, false);
                                    }}
                                  >
                                    {tag}
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      ) : (
                        <p style={{ marginBottom: 10 }}>
                          <span style={{ marginRight: 5 }}>Tags:</span>
                          {newtagList
                            .map((el, index) => {
                              return el.value;
                            })
                            .join(", ")}
                        </p>
                      )}
                      {/* <--- Industry --->> */}
                      {editMode ? (
                        <div className="tagEdit">
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              height: "fit-content",
                              gap: 4,
                            }}
                          >
                            <div style={{ marginRight: 5 }}>Industry:</div>
                            {newindustrys[0] &&
                            newindustrys[0].value.trim() === "" ? null : (
                              <>
                                {newindustrys.map((industry, index) => (
                                  <div
                                    style={{
                                      backgroundColor: "white",
                                      border: "1px solid black",
                                      display: "flex",
                                      justifyContent: "center",
                                      minWidth: "60px",
                                      width: "fit-content",
                                      textAlign: "center",
                                      borderRadius: "8px",
                                      marginRight: 4,
                                      padding: "0 10px",
                                    }}
                                    key={index}
                                  >
                                    {industry.value}
                                    <button
                                      style={tagsButtonStyle}
                                      onClick={() =>
                                        handleRemoveIndustries(index)
                                      }
                                    >
                                      <RxCross2
                                        style={{ maxWidth: 15, maxHeight: 15 }}
                                      />
                                    </button>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                          <input
                            className="editable"
                            style={{
                              height: 30,
                              width: "100%",
                              fontStyle: "italic",
                            }}
                            placeholder="IOT, Healthcare, HAAS"
                            name="industrys"
                            value={industrys}
                            onChange={(e) => setIndustrys(e.target.value)}
                            onClick={() => setInputClickedIndustry(true)}
                            onKeyDown={(e) => {
                              if (e.keyCode === 13) {
                                e.preventDefault();
                                handleAddIndustry();
                              }
                            }}
                            maxLength={57}
                            onBlur={() => {
                              isUpdatedByEffectRef.current = true;
                              handleSaveClick(false, false);
                            }}
                          />
                          {inputClickedIndustry && (
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 4,
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              {suggestedIndustrys
                                .filter((industry) =>
                                  industry
                                    .toLowerCase()
                                    .includes(industrys.toLowerCase())
                                )
                                .map((industry, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      backgroundColor: "lightgray",
                                      border: "1px solid black",
                                      borderRadius: "8px",
                                      padding: "0 10px",
                                      marginBottom: 4,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleSelectSuggestedIndustry(industry)
                                    }
                                    onBlur={() => {
                                      isUpdatedByEffectRef.current = true;
                                      handleSaveClick(false, false);
                                    }}
                                  >
                                    {industry}
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      ) : (
                        <p style={{ marginBottom: 10 }}>
                          <span style={{ marginRight: 5 }}>Industry:</span>
                          {newindustrys
                            .map((el, index) => {
                              return el.value;
                            })
                            .join(", ")}
                        </p>
                      )}
                      {/* <--- Competitors --->> */}
                      {editMode ? (
                        <div className="tagEdit">
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              height: "fit-content",
                              gap: 4,
                            }}
                          >
                            <div style={{ marginRight: 5 }}>Competitors:</div>
                            {newcompetitors[0] &&
                            newcompetitors[0].value.trim() === "" ? null : (
                              <>
                                {newcompetitors.map((competitor, index) => (
                                  <div
                                    style={{
                                      backgroundColor: "white",
                                      border: "1px solid black",
                                      display: "flex",
                                      justifyContent: "center",
                                      minWidth: "60px",
                                      width: "fit-content",
                                      textAlign: "center",
                                      borderRadius: "8px",
                                      marginRight: 4,
                                      padding: "0 10px",
                                    }}
                                    key={index}
                                  >
                                    {competitor.value}
                                    <button
                                      style={tagsButtonStyle}
                                      onClick={() =>
                                        handleRemoveCompetitors(index)
                                      }
                                    >
                                      <RxCross2
                                        style={{ maxWidth: 15, maxHeight: 15 }}
                                      />
                                    </button>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                          <input
                            className="editable"
                            style={{
                              height: 30,
                              width: "100%",
                              fontStyle: "italic",
                            }}
                            placeholder="Airtag, iTracker, Samsung GPS, Coyotte"
                            name="competitors "
                            value={competitors}
                            onChange={(e) => setCompetitors(e.target.value)}
                            onClick={() => setInputClickedCompetitor(true)}
                            onKeyDown={(e) => {
                              if (e.keyCode === 13) {
                                e.preventDefault();
                                handleAddCompetitors();
                              }
                            }}
                            maxLength={57}
                            onBlur={() => {
                              isUpdatedByEffectRef.current = true;
                              handleSaveClick(false, false);
                            }}
                          />
                          {inputClickedCompetitor && (
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 4,
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              {suggestedCompetitor
                                .filter((competitor) =>
                                  competitor
                                    .toLowerCase()
                                    .includes(competitors.toLowerCase())
                                )
                                .map((competitor, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      backgroundColor: "lightgray",
                                      border: "1px solid black",
                                      borderRadius: "8px",
                                      padding: "0 10px",
                                      marginBottom: 4,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleSelectSuggestedCompetitor(
                                        competitor
                                      )
                                    }
                                    onBlur={() => {
                                      isUpdatedByEffectRef.current = true;
                                      handleSaveClick(false, false);
                                    }}
                                  >
                                    {competitor}
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      ) : (
                        <p style={{ marginBottom: 10 }}>
                          <span style={{ marginRight: 5 }}>Competitors:</span>
                          {newcompetitors
                            .map((el, index) => {
                              return el.value;
                            })
                            .join(", ")}
                        </p>
                      )}
                      {/* <--- Addressable Market --->> */}
                      {editMode ? (
                        <div className="tagEdit">
                          {errors.newAddressableMarket && (
                            <div style={reactFormValidationEdit}>
                              *{errors.newAddressableMarket.message}
                            </div>
                          )}
                          <p className="tagEdit" style={{ display: "flex" }}>
                            <div style={{ marginTop: 2, marginRight: 4 }}>
                              Addressable Market: $
                            </div>
                            <input
                              style={{ height: 30, width: "73.1%" }}
                              className="editable featureEdit4"
                              value={newAddressableMarket?.toLocaleString(
                                "en-US"
                              )}
                              onChange={(e) =>
                                setNewAddressableMarket(
                                  parseNumber(e.target.value)
                                )
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={20}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                        </div>
                      ) : (
                        <p style={{ marginBottom: 10 }}>
                          <span style={{ marginTop: 2, marginRight: 5 }}>
                            Addressable Market:
                          </span>
                          ${adressableMarket?.toLocaleString("en-US")}
                        </p>
                      )}
                      {/* <--- Incorporated --->> */}
                      {editMode ? (
                        <div className="tagEdit">
                          {errors.newIncorporated && (
                            <div style={reactFormValidationEdit}>
                              *{errors.newIncorporated.message}
                            </div>
                          )}
                          <p className="tagEdit" style={{ display: "flex" }}>
                            <div style={{ marginTop: 2, marginRight: 5 }}>
                              Incorporated:
                            </div>
                            <input
                              style={{ height: 30, width: "87.1%" }}
                              className="editable featureEdit5"
                              value={newIncorporated}
                              onChange={(e) =>
                                setNewIncorporated(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={30}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                        </div>
                      ) : (
                        <p style={{ marginBottom: 10 }}>
                          <span style={{ marginRight: 5 }}>Incorporated:</span>
                          {incorporated}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {/* MEDIA */}
                <div className="top__media">
                  {/* Desktop */}
                  <div className="desktop__">
                    <img src={projectImages.frameManiImg} alt="framemain" />
                    <div
                      className="frame frame__mod"
                      style={{ width: "92%", left: "4%" }}
                    >
                      {editMode ? (
                        <>
                          {selectedMainImage ? (
                            <img
                              src={URL.createObjectURL(selectedMainImage)}
                              alt="selectedImage"
                            />
                          ) : (
                            <>
                              {imageMain ? (
                                <img
                                  src={`${imageMain}`}
                                  alt="imagelogo"
                                  className="imageLogo__mode"
                                />
                              ) : (
                                <img
                                  src={projectImages.ScreenShotDes}
                                  alt="biglogo"
                                  className="upload__image"
                                />
                              )}
                            </>
                          )}
                          <label
                            style={{
                              maxWidth: 45,
                              position: "absolute",
                              marginLeft: "50%",
                              zIndex: 3,
                            }}
                            htmlFor="fileInputMain1"
                          >
                            <img
                              style={{ maxWidth: 45 }}
                              src={projectImages.uploadImg}
                              alt="upload"
                            />
                            <input
                              style={{ display: "none" }}
                              name="image"
                              id="fileInputMain1"
                              type="file"
                              onChange={(event) =>
                                handleImageChange(event, "main")
                              }
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </label>
                        </>
                      ) : (
                        <>
                          {imageMain == null ? (
                            <img
                              src={projectImages.ScreenShotDes}
                              alt="biglogo"
                              className="upload__image"
                            />
                          ) : (
                            <img
                              src={`${imageMain}`}
                              alt="imageMain"
                              className="imageLogo__mode"
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {/* Mobile */}
                  {phoneImageStatus || editMode ? (
                    <div className="mobile__">
                      <img
                        src={projectImages.mobileFrameImg}
                        alt="mobileframe"
                      />
                      <div
                        className="frame"
                        style={{ width: "100%", justifyContent: "center" }}
                      >
                        {editMode ? (
                          <>
                            {phoneImageStatus ? (
                              <>
                                {selectedPhoneImage ? (
                                  <img
                                    src={URL.createObjectURL(
                                      selectedPhoneImage
                                    )}
                                    alt="selectedImagew"
                                  />
                                ) : (
                                  <>
                                    {imagePhone ? (
                                      <img
                                        src={`${imagePhone}`}
                                        alt="imagePhone"
                                      />
                                    ) : (
                                      <img
                                        src={projectImages.mobileImg}
                                        alt="biglogo"
                                        className="upload__image"
                                      />
                                    )}
                                  </>
                                )}
                                <div
                                  style={{
                                    position: "absolute",
                                    width: 30,
                                    height: 30,
                                    backgroundColor: "white",
                                    zIndex: 4,
                                    left: "79%",
                                    top: "-8%",
                                  }}
                                >
                                  <button
                                    style={
                                      (tagsButtonStyle,
                                      { alignItems: "center" })
                                    }
                                    onClick={() => handleToggleStatusPhoto()}
                                  >
                                    <RxCross2
                                      style={{ maxWidth: 30, maxHeight: 30 }}
                                    />
                                  </button>
                                </div>
                                <label
                                  htmlFor="fileInputPhone"
                                  style={{
                                    position: "absolute",
                                    left: "50%",
                                    top: "50%",
                                    transform: "translate(-50%, -50%)",
                                    display: "inline-flex",
                                    zIndex: 3,
                                    textDecoration: "none",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    style={{ maxWidth: 45 }}
                                    src={projectImages.uploadImg}
                                    alt="upload"
                                  />
                                  <input
                                    style={{ display: "none" }}
                                    id="fileInputPhone"
                                    name="image"
                                    type="file"
                                    onChange={(event) =>
                                      handleImageChange(event, "phone")
                                    }
                                    onBlur={() => {
                                      isUpdatedByEffectRef.current = true;
                                      handleSaveClick(false, false);
                                    }}
                                  />
                                  <span style={imageDeviceSpan}>
                                    Upload your App Screenshot
                                  </span>
                                </label>
                              </>
                            ) : (
                              <div>
                                <button
                                  onClick={() => handleToggleStatusPhoto()}
                                  className="bg-white rounded-full shadow-lg flex items-center justify-center"
                                  style={{
                                    position: "relative",
                                    width: 80,
                                    height: 80,
                                    zIndex: 99,
                                  }}
                                >
                                  <RxPlus style={{ width: 40, height: 40 }} />
                                </button>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {imagePhone == null ? (
                              <img
                                src={projectImages.mobileImg}
                                alt="biglogo"
                                className="upload__image"
                              />
                            ) : (
                              <img
                                src={`${imagePhone}`}
                                alt="imagePhone"
                                className="imageLogo__mode-2"
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : null}
                  {/* Device */}
                  {deviceImageStatus || editMode ? (
                    <div
                      className="product__image"
                      style={{
                        maxWidth: 240,
                        maxHeight: 240,
                        width: 240,
                        height: 120,
                        marginLeft: deviceImageStatus ? "" : "8vw",
                        marginTop: deviceImageStatus
                          ? editMode
                            ? ""
                            : phoneImageStatus
                            ? ""
                            : 50
                          : "",
                      }}
                    >
                      {deviceImageStatus ? (
                        <>
                          {editMode ? (
                            <div
                              className="product__image upload"
                              style={{ width: 240, height: 120, marginTop: 20 }}
                            >
                              {selectedDeviceImage ? (
                                <img
                                  src={URL.createObjectURL(selectedDeviceImage)}
                                  alt="selectedImagew"
                                />
                              ) : (
                                <>
                                  {imageDevice ? (
                                    <img
                                      src={`${imageDevice}`}
                                      alt="imageDevice"
                                    />
                                  ) : (
                                    <img
                                      src={projectImages.biImg}
                                      alt="bi"
                                      className="upload__image"
                                    />
                                  )}
                                </>
                              )}
                              <div
                                style={{
                                  position: "absolute",
                                  width: 30,
                                  height: 30,
                                  backgroundColor: "white",
                                  zIndex: 4,
                                  left: "79%",
                                  top: "-8%",
                                  // boxShadow: "1px 1px 4px 1px rgba(66, 68, 90, 1)",
                                }}
                              >
                                <button
                                  style={
                                    (tagsButtonStyle, { alignItems: "center" })
                                  }
                                  onClick={() => handleToggleStatusDevice()}
                                >
                                  <RxCross2
                                    style={{ maxWidth: 30, maxHeight: 30 }}
                                  />
                                </button>
                              </div>
                              <label
                                style={{
                                  position: "absolute",
                                  left: "50%",
                                  top: "50%",
                                  transform: "translate(-50%, -50%)",
                                  display: "inline-flex",
                                  zIndex: 3,
                                  textDecoration: "none",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                htmlFor="fileInputDeviceLogo"
                              >
                                <img
                                  src={projectImages.uploadImg}
                                  alt="upload"
                                />
                                <input
                                  style={{ display: "none" }}
                                  name="image"
                                  id="fileInputDeviceLogo"
                                  type="file"
                                  onChange={(event) =>
                                    handleImageChange(event, "device")
                                  }
                                  onBlur={() => {
                                    isUpdatedByEffectRef.current = true;
                                    handleSaveClick(false, false);
                                  }}
                                />
                                <span style={imageDeviceSpan}>
                                  Upload your Device Screenshot
                                </span>
                              </label>
                            </div>
                          ) : (
                            <>
                              {imageDevice ? (
                                <img
                                  src={`${imageDevice}`}
                                  alt="imageDevice"
                                  className="product_image_mod"
                                />
                              ) : (
                                <img
                                  src={projectImages.biImg}
                                  alt="bi"
                                  className="upload__image product_image_mod"
                                />
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <div>
                          <button
                            onClick={() => handleToggleStatusDevice()}
                            className="bg-white rounded-full shadow-lg flex items-center justify-center"
                            style={{
                              position: "relative",
                              width: 80,
                              height: 80,
                              zIndex: 99,
                            }}
                          >
                            <RxPlus style={{ width: 40, height: 40 }} />
                          </button>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* Competitive Advantage */}
              <div className="main__grid">
                <p>
                  Competitive <br />
                  Advantage
                </p>
                <div className="grid__wrapper">
                  {editMode ? (
                    <>
                      {newAdvantages.map((advantage, index) => (
                        <div key={index} className="elem__grid elem__grid__mod">
                          <h6>
                            {errors.advantage?.title?.[index] && (
                              <div style={reactFormValidationEdit}>
                                *{errors.advantage.title[index].message}
                              </div>
                            )}
                            <textarea
                              type="text"
                              value={advantage.title}
                              style={{
                                resize: "none",
                                width: "100%",
                                textAlign: "left",
                              }}
                              {...register(`advantage.title[${index}]`, {
                                required: "Advantage title is required",
                              })}
                              className="editable"
                              onChange={(e) =>
                                handleAdvantageChange(
                                  index,
                                  "title",
                                  e.target.value
                                )
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={150}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </h6>
                          <p>
                            {errors.advantage?.text?.[index] && (
                              <div style={reactFormValidationEdit}>
                                *{errors.advantage.text[index].message}
                              </div>
                            )}
                            <textarea
                              type="text"
                              style={{
                                width: "100%",
                                height: "140px",
                                whiteSpace: "pre-line",
                                resize: "none",
                              }}
                              className="editable"
                              value={advantage.text}
                              {...register(`advantage.text[${index}]`, {
                                required: "Advantage text is required",
                              })}
                              onChange={(e) =>
                                handleAdvantageChange(
                                  index,
                                  "text",
                                  e.target.value
                                )
                              }
                              maxLength={500}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                          {index !== 0 && (
                            <button
                              onClick={() => handleRemoveAdvantage(index)}
                              style={{ marginLeft: 10 }}
                            >
                              <MdDelete />
                            </button>
                          )}
                          {index === newAdvantages.length - 1 &&
                            newAdvantages.length < 4 && (
                              <button
                                onClick={handleAddAdvantage}
                                style={{ marginLeft: 10 }}
                              >
                                <GrFormAdd />
                              </button>
                            )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {advantages &&
                        advantages.map((advantage, index) => {
                          return (
                            <div
                              className="elem__grid elem__grid__mod"
                              key={index}
                            >
                              <h6
                                style={{
                                  width: 308,
                                  resize: "none",
                                  marginBottom: 30,
                                  textAlign: "left",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {advantage.title}
                              </h6>
                              <p
                                style={{
                                  width: 308,
                                  textAlign: "left",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {advantage.text}
                              </p>
                            </div>
                          );
                        })}
                    </>
                  )}
                </div>
              </div>
              {/* Founding Team */}
              <div className="team__wrapper">
                <p>
                  Founding <br />
                  Team
                </p>
                <div className="team__grid">
                  {editMode ? (
                    <>
                      {newTeams.map((team, index) => (
                        <div className="elem__team" key={index}>
                          <div
                            className="team__image upload"
                            // style={isSafari ? { marginLeft: 140 } : null}
                          >
                            {newTeamImages[index] instanceof File ? (
                              <img
                                style={{ maxWidth: 45, maxHeight: 45 }}
                                src={URL.createObjectURL(newTeamImages[index])}
                                alt="selectedImage"
                              />
                            ) : newTeamImages[index] ? (
                              <img
                                style={{ maxWidth: 45, maxHeight: 45 }}
                                src={`${newTeamImages[index]}`}
                                alt="selectedImage"
                              />
                            ) : (
                              <img src={imageTeam[index]} alt="team" />
                            )}
                            <label
                              style={{
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                                display: "inline-flex",
                                zIndex: 3,
                                textDecoration: "none",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              htmlFor={`teamfileInput-${index}`}
                            >
                              <img src={projectImages.uploadImg} alt="upload" />
                              <input
                                id={`teamfileInput-${index}`}
                                style={{ display: "none" }}
                                type="file"
                                name="image"
                                onChange={(e) =>
                                  handleTeamImageChange(
                                    index,
                                    e.target.files[0]
                                  )
                                }
                              />
                            </label>
                          </div>
                          <div className="team__desc">
                            <p data-index={index}>
                              {errors.team?.title?.[index] && (
                                <div style={reactFormValidationEdit}>
                                  *{errors.team.title[index].message}
                                </div>
                              )}
                              <input
                                type="text"
                                name="image"
                                {...register(`team.title[${index}]`, {
                                  required: "Team title is required",
                                })}
                                value={team.title}
                                className="editable"
                                onChange={(e) =>
                                  handleTeamChange(
                                    index,
                                    "title",
                                    e.target.value
                                  )
                                }
                                maxLength={25}
                                onBlur={() => {
                                  isUpdatedByEffectRef.current = true;
                                  handleSaveClick(false, false);
                                }}
                              />
                            </p>
                            <span data-index={index}>
                              {errors.team?.text?.[index] && (
                                <div style={reactFormValidationEdit}>
                                  *{errors.team.text[index].message}
                                </div>
                              )}
                              <textarea
                                type="text"
                                style={{
                                  width: "140px",
                                  height: "70px",
                                  resize: "none",
                                }}
                                className="editable"
                                {...register(`team.text[${index}]`, {
                                  required: "Team text is required",
                                })}
                                value={team.text}
                                onChange={(e) =>
                                  handleTeamChange(
                                    index,
                                    "text",
                                    e.target.value
                                  )
                                }
                                maxLength={36}
                                onBlur={() => {
                                  isUpdatedByEffectRef.current = true;
                                  handleSaveClick(false, false);
                                }}
                              />
                            </span>
                            <div style={{ display: "flex" }}>
                              <Link>
                                <img
                                  style={{ width: 25, height: 25 }}
                                  src={projectImages.linkedinImg}
                                  alt="linkedin"
                                />
                              </Link>
                              <input
                                type="text"
                                name="image"
                                value={team.link}
                                style={{ fontSize: 13, width: 150 }}
                                placeholder="Add a link to the profile"
                                className="editable"
                                onChange={(e) =>
                                  handleTeamChange(
                                    index,
                                    "link",
                                    e.target.value.trim()
                                  )
                                }
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  if (e.keyCode === 13) {
                                    e.preventDefault();
                                  }
                                }}
                                onBlur={() => {
                                  isUpdatedByEffectRef.current = true;
                                  handleSaveClick(false, false);
                                }}
                              />
                              <div style={{ marginTop: 4 }}>
                                {index !== 0 && (
                                  <button
                                    onClick={() => handleRemoveTeam(index)}
                                    style={{ marginLeft: 10 }}
                                  >
                                    <MdDelete />
                                  </button>
                                )}
                                {index === newTeams.length - 1 &&
                                  newTeams.length < 6 && (
                                    <button
                                      onClick={handleAddTeam}
                                      style={{ marginLeft: 10 }}
                                    >
                                      <GrFormAdd />
                                    </button>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {!haveTeamData ? (
                        <></>
                      ) : (
                        <>
                          {teams.map((el, index) => {
                            return (
                              <div
                                style={{
                                  cursor: el.link === " " ? null : "pointer",
                                }}
                                href={`${el.link}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onAuxClick={handleLinkedinClicked}
                                onClick={handleLinkedinClicked}
                                className="elem__team"
                                key={index}
                              >
                                <div className="team__image">
                                  {el.image ? (
                                    <img
                                      src={`${el.image}`}
                                      alt="team"
                                    />
                                  ) : (
                                    <img src={imageTeam[index]} alt="team" />
                                  )}
                                </div>
                                <div className="team__desc">
                                  <p>{el.title}</p>
                                  <span
                                    style={{
                                      width: 140,
                                      height: 50,
                                      textAlign: "left",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {el.text}
                                  </span>

                                  <a
                                    href={`${el.link}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onAuxClick={handleLinkedinClicked}
                                    onClick={handleLinkedinClicked}
                                  >
                                    {el.link && el.link.trim() !== "" && (
                                      <img
                                        src={projectImages.linkedinImg}
                                        alt="linkedin"
                                      />
                                    )}
                                  </a>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              {/* METRICS */}
              {/* Fundraise Metrics */}
              <div style={{ zoom: "85%" }} className="info__grid">
                <div className="elem__info">
                  <div className="head__">
                    <p>
                      Fundraise <br />
                      Metrics
                    </p>
                  </div>
                  <div className="content__">
                    {editMode ? (
                      <>
                        <div className="row">
                          <span data-name="col1_1">Current Stage</span>
                          <p
                            className="col"
                            style={{ minWidth: "98%", display: "flex" }}
                            data-name="col1_2"
                          >
                            <div style={{ marginLeft: 18 }} />
                            <select
                              style={{
                                width: isSafari ? "59%" : "55%",
                                height: 56,
                              }}
                              className="editable"
                              value={newFundraiseMetrics.currentStage}
                              onChange={(e) =>
                                setNewFundraiseMetrics((prevState) => ({
                                  ...prevState,
                                  currentStage: e.target.value,
                                }))
                              }
                            >
                              <option value="Pre-Series A">Pre-Series A</option>
                              <option value="Pre-seed">Pre-seed</option>
                              <option value="Seed">Seed</option>
                              <option value="Bridge Round">Bridge Round</option>
                              <option value="Series A">Series A</option>
                              <option value="Series B+">Series B+</option>
                              <option value="Series C+">Series C+</option>
                            </select>
                          </p>
                        </div>
                        <div className="row">
                          <span style={{ marginBottom: 0 }} data-name="col1_3">
                            Raising
                          </span>
                          {errors.raising && (
                            <div style={reactFormValidationEdit}>
                              *{errors.raising.message}
                            </div>
                          )}
                          <p
                            className="col"
                            style={{ display: "flex" }}
                            data-name="col1_4"
                          >
                            $
                            <input
                              style={{ width: "61%" }}
                              className="editable"
                              {...register(`raising`, {
                                required: "Raising is required",
                                pattern: {
                                  value: /^[0-9,]*$/, // A regular expression that checks that the value consists of only digits
                                  message: "Only digits are allowed",
                                },
                              })}
                              value={newFundraiseMetrics.raising?.toLocaleString(
                                "en-US"
                              )}
                              onChange={(e) =>
                                setNewFundraiseMetrics((prevState) => ({
                                  ...prevState,
                                  raising: parseNumber(e.target.value),
                                }))
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={10}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                        </div>
                        <div className="row">
                          <span style={{ marginBottom: 0 }} data-name="col1_5">
                            Raised to date
                          </span>
                          {errors.raisedToDate && (
                            <div style={reactFormValidationEdit}>
                              *{errors.raisedToDate.message}
                            </div>
                          )}
                          <p
                            className="col"
                            style={{ display: "flex" }}
                            data-name="col1_6"
                          >
                            $
                            <input
                              style={{ width: "62%" }}
                              className="editable"
                              {...register(`raisedToDate`, {
                                required: "Raised To Date is required",
                                pattern: {
                                  value: /^[0-9,]*$/, // A regular expression that checks that the value consists of only digits
                                  message: "Only digits are allowed",
                                },
                              })}
                              value={newFundraiseMetrics.raisedToDate?.toLocaleString(
                                "en-US"
                              )}
                              onChange={(e) =>
                                setNewFundraiseMetrics((prevState) => ({
                                  ...prevState,
                                  raisedToDate: parseNumber(e.target.value),
                                }))
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={10}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                        </div>
                        <div className="row">
                          <span style={{ marginBottom: 0 }} data-name="col1_7">
                            Use of Funds
                          </span>
                          {errors.useOfFunds && (
                            <div style={reactFormValidationEdit}>
                              *{errors.useOfFunds.message}
                            </div>
                          )}
                          <p
                            className="col"
                            style={{ display: "flex" }}
                            data-name="col1_8"
                          >
                            <div style={{ marginLeft: 20 }} />
                            <input
                              style={{ width: "62%" }}
                              className="editable"
                              {...register(`useOfFunds`, {
                                required: "Use of Funds is required",
                              })}
                              value={newFundraiseMetrics.useOfFunds}
                              onChange={(e) =>
                                setNewFundraiseMetrics((prevState) => ({
                                  ...prevState,
                                  useOfFunds: e.target.value,
                                }))
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={10}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <span>Current Stage</span>
                          <p>
                            {fundraiseMetrics &&
                              fundraiseMetrics?.[0]?.currentStage}
                          </p>
                        </div>
                        <div className="row">
                          <span>Raising</span>
                          <p>
                            $
                            {fundraiseMetrics &&
                              fundraiseMetrics?.[0]?.raising?.toLocaleString(
                                "en-US"
                              )}
                          </p>
                        </div>
                        <div className="row">
                          <span>Raised to date</span>
                          <p>
                            $
                            {fundraiseMetrics &&
                              fundraiseMetrics?.[0]?.raisedToDate?.toLocaleString(
                                "en-US"
                              )}
                          </p>
                        </div>
                        <div className="row">
                          <span>Use of Funds</span>
                          <p>
                            {fundraiseMetrics &&
                              fundraiseMetrics?.[0]?.useOfFunds}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* Business Metrics */}
                <div className="elem__info">
                  <div className="head__">
                    <p>
                      Business <br />
                      Metrics
                    </p>
                  </div>
                  <div className="content__">
                    {editMode ? (
                      <>
                        <div className="row">
                          <span style={{ marginBottom: 0 }} data-name="col2_1">
                            Contracts value
                          </span>
                          {errors.contractsValue && (
                            <div style={reactFormValidationEdit}>
                              *{errors.contractsValue.message}
                            </div>
                          )}
                          <p
                            className="col"
                            style={{ display: "flex" }}
                            data-name="col2_2"
                          >
                            $
                            <input
                              style={{ width: "58%" }}
                              className="editable"
                              {...register(`contractsValue`, {
                                required: "Contracts value is required",
                                pattern: {
                                  value: /^[0-9,]*$/, // A regular expression that checks that the value consists of only digits
                                  message: "Only digits are allowed",
                                },
                              })}
                              value={newBusinessMetrics.contractsValue?.toLocaleString(
                                "en-US"
                              )}
                              onChange={(e) =>
                                setNewBusinessMetrics((prevState) => ({
                                  ...prevState,
                                  contractsValue: parseNumber(e.target.value),
                                }))
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={10}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                        </div>
                        <div className="row">
                          <span style={{ marginBottom: 0 }} data-name="col2_3">
                            Current revenue
                          </span>
                          {errors.currentRevenue && (
                            <div style={reactFormValidationEdit}>
                              *{errors.currentRevenue.message}
                            </div>
                          )}
                          <p
                            className="col"
                            style={{ display: "flex" }}
                            data-name="col2_4"
                          >
                            $
                            <input
                              style={{ width: "58%" }}
                              className="editable"
                              {...register(`currentRevenue`, {
                                required: "Current revenue is required",
                                pattern: {
                                  value: /^[0-9,]*$/, // A regular expression that checks that the value consists of only digits
                                  message: "Only digits are allowed",
                                },
                              })}
                              value={newBusinessMetrics.currentRevenue?.toLocaleString(
                                "en-US"
                              )}
                              onChange={(e) =>
                                setNewBusinessMetrics((prevState) => ({
                                  ...prevState,
                                  currentRevenue: parseNumber(e.target.value),
                                }))
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={10}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                        </div>
                        <div className="row">
                          <span style={{ marginBottom: 0 }} data-name="col2_5">
                            Users
                          </span>
                          {errors.users && (
                            <div style={reactFormValidationEdit}>
                              *{errors.users.message}
                            </div>
                          )}
                          <p
                            className="col"
                            style={{ display: "flex" }}
                            data-name="col2_6"
                          >
                            <div style={{ marginLeft: 20 }} />
                            <input
                              style={{ width: "58%" }}
                              className="editable"
                              {...register(`users`, {
                                required: "Users is required",
                                pattern: {
                                  value: /^[0-9,]*$/, // A regular expression that checks that the value consists of only digits
                                  message: "Only digits are allowed",
                                },
                              })}
                              value={newBusinessMetrics.users?.toLocaleString(
                                "en-US"
                              )}
                              onChange={(e) =>
                                setNewBusinessMetrics((prevState) => ({
                                  ...prevState,
                                  users: parseNumber(e.target.value),
                                }))
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={10}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                        </div>
                        <div className="row">
                          <span style={{ marginBottom: 0 }} data-name="col2_7">
                            Monthly Cashburn
                          </span>
                          {errors.monthlyCashburn && (
                            <div style={reactFormValidationEdit}>
                              *{errors.monthlyCashburn.message}
                            </div>
                          )}
                          <p
                            className="col"
                            style={{ display: "flex" }}
                            data-name="col2_8"
                          >
                            $
                            <input
                              style={{ width: "58%" }}
                              className="editable"
                              {...register(`monthlyCashburn`, {
                                required: "Monthly cashburn is required",
                                pattern: {
                                  value: /^[0-9,]*$/, // A regular expression that checks that the value consists of only digits
                                  message: "Only digits are allowed",
                                },
                              })}
                              value={newBusinessMetrics.monthlyCashburn?.toLocaleString(
                                "en-US"
                              )}
                              onChange={(e) =>
                                setNewBusinessMetrics((prevState) => ({
                                  ...prevState,
                                  monthlyCashburn: parseNumber(e.target.value),
                                }))
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={10}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <span>Contracts value</span>
                          <p>
                            $
                            {businessMetrics?.[0]?.contractsValue?.toLocaleString(
                              "en-US"
                            )}
                          </p>
                        </div>
                        <div className="row">
                          <span>Current revenue</span>
                          <p>
                            $
                            {businessMetrics?.[0]?.currentRevenue?.toLocaleString(
                              "en-US"
                            )}
                          </p>
                        </div>
                        <div className="row">
                          <span>Users</span>
                          <p>
                            {businessMetrics?.[0]?.users?.toLocaleString(
                              "en-US"
                            )}
                          </p>
                        </div>
                        <div className="row">
                          <span>Monthly Cashburn</span>
                          <p>
                            $
                            {businessMetrics?.[0]?.monthlyCashburn?.toLocaleString(
                              "en-US"
                            )}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* Product METRICS */}
                <div className="elem__info">
                  <div className="head__">
                    <p>
                      Product <br />
                      METRICS
                    </p>
                  </div>
                  <div className="content__">
                    {editMode ? (
                      <>
                        <div className="row">
                          <span style={{ marginBottom: 0 }} data-name="col3_1">
                            Cost of Acquisition
                          </span>
                          {errors.costOfAcquisition && (
                            <div style={reactFormValidationEdit}>
                              *{errors.costOfAcquisition.message}
                            </div>
                          )}
                          <p
                            className="col"
                            style={{ display: "flex" }}
                            data-name="col3_2"
                          >
                            $
                            <input
                              style={{ width: "58%" }}
                              className="editable"
                              {...register(`costOfAcquisition`, {
                                required:
                                  "Cost of acquisition value is required",
                                pattern: {
                                  value: /^[0-9,]*$/, // A regular expression that checks that the value consists of only digits
                                  message: "Only digits are allowed",
                                },
                              })}
                              value={newProductMetrics.costOfAcquisition?.toLocaleString(
                                "en-US"
                              )}
                              onChange={(e) =>
                                setNewProductMetrics((prevState) => ({
                                  ...prevState,
                                  costOfAcquisition: parseNumber(
                                    e.target.value
                                  ),
                                }))
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={10}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                        </div>
                        <div className="row">
                          <span style={{ marginBottom: 0 }} data-name="col3_3">
                            Life time value
                          </span>
                          {errors.lifeTimeValue && (
                            <div style={reactFormValidationEdit}>
                              *{errors.lifeTimeValue.message}
                            </div>
                          )}
                          <p
                            className="col"
                            style={{ display: "flex" }}
                            data-name="col3_4"
                          >
                            $
                            <input
                              style={{ width: "58%" }}
                              className="editable"
                              {...register(`lifeTimeValue`, {
                                required: "Life time value is required",
                                pattern: {
                                  value: /^[0-9,]*$/, // A regular expression that checks that the value consists of only digits
                                  message: "Only digits are allowed",
                                },
                              })}
                              value={newProductMetrics.lifeTimeValue?.toLocaleString(
                                "en-US"
                              )}
                              onChange={(e) =>
                                setNewProductMetrics((prevState) => ({
                                  ...prevState,
                                  lifeTimeValue: parseNumber(e.target.value),
                                }))
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={10}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                        </div>
                        <div className="row">
                          <span style={{ marginBottom: 0 }} data-name="col3_5">
                            ARR per Customer
                          </span>
                          {errors.appPerCustomer && (
                            <div style={reactFormValidationEdit}>
                              *{errors.appPerCustomer.message}
                            </div>
                          )}
                          <p
                            className="col"
                            style={{ display: "flex" }}
                            data-name="col3_6"
                          >
                            $
                            <input
                              style={{ width: "58%" }}
                              className="editable"
                              {...register(`appPerCustomer`, {
                                required: "Arr per csustomer is required",
                                pattern: {
                                  value: /^[0-9,]*$/, // A regular expression that checks that the value consists of only digits
                                  message: "Only digits are allowed",
                                },
                              })}
                              value={newProductMetrics.appPerCustomer?.toLocaleString(
                                "en-US"
                              )}
                              onChange={(e) =>
                                setNewProductMetrics((prevState) => ({
                                  ...prevState,
                                  appPerCustomer: parseNumber(e.target.value),
                                }))
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={10}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                        </div>
                        <div className="row">
                          <span style={{ marginBottom: 0 }} data-name="col3_7">
                            COGS Per Unit
                          </span>
                          {errors.cogsPerUnit && (
                            <div style={reactFormValidationEdit}>
                              *{errors.cogsPerUnit.message}
                            </div>
                          )}
                          <p
                            className="col"
                            style={{ display: "flex" }}
                            data-name="col3_8"
                          >
                            $
                            <input
                              style={{ width: "58%" }}
                              className="editable "
                              {...register(`cogsPerUnit`, {
                                required: "Cogs per unit is required",
                                pattern: {
                                  value: /^[0-9,]*$/, // A regular expression that checks that the value consists of only digits
                                  message: "Only digits are allowed",
                                },
                              })}
                              value={newProductMetrics.cogsPerUnit?.toLocaleString(
                                "en-US"
                              )}
                              onChange={(e) =>
                                setNewProductMetrics((prevState) => ({
                                  ...prevState,
                                  cogsPerUnit: parseNumber(e.target.value),
                                }))
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={10}
                              onBlur={() => {
                                isUpdatedByEffectRef.current = true;
                                handleSaveClick(false, false);
                              }}
                            />
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <span>Cost of Acquisition</span>
                          <p>
                            $
                            {productMetrics?.[0]?.costOfAcquisition?.toLocaleString(
                              "en-US"
                            )}
                          </p>
                        </div>
                        <div className="row">
                          <span>Life time value </span>
                          <p>
                            $
                            {productMetrics?.[0]?.lifeTimeValue?.toLocaleString(
                              "en-US"
                            )}
                          </p>
                        </div>
                        <div className="row">
                          <span>ARR per Customer</span>
                          <p>
                            $
                            {productMetrics?.[0]?.appPerCustomer?.toLocaleString(
                              "en-US"
                            )}
                          </p>
                        </div>
                        <div className="row">
                          <span>COGS Per Unit</span>
                          <p>
                            $
                            {productMetrics?.[0]?.cogsPerUnit?.toLocaleString(
                              "en-US"
                            )}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer
          id={id} // startup ID
          _id={_id} // user ID
          title={title}
          createdBy={createdBy}
          showShare={showShare}
          imageLogo={imageLogo}
          showContact={showContact}
          showDataRoom={showDataRoom}
          accessStatus={accessStatus} // Access level
          handleShareClick={handleShareClick}
          handleShareClose={handleShareClose}
          handleMetricsClick={handleMetricsClick}
          handleContactClick={handleContactClick}
          handleContactClose={handleContactClose}
          handleDataRoomClose={handleDataRoomClose}
          handleDataRoomClick={handleDataRoomClick}
        />
      </>
      {/* ) : null} */}
    </>
  );
};

export default StartupScreen;
