import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios";
import ReactToastify from "../hooks/useReactToastify";

// RedirectManager handles the redirection process based on the link's validity.
// On component mount, it checks the current URL against an API endpoint and navigates
// accordingly. Valid links will store data and redirect to the home page. Invalid links
// result in a redirect with a specific query parameter. Errors in link checking are
// handled by a toast notification, and a loader is displayed during the process.

const RedirectManager = () => {
  const navigate = useNavigate();

  useEffect(() => {
    async function checkLink() {
      const currentURL = window.location.href;
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/link-status/change`,
          {
            link: currentURL,
          }
        );

        if (response.data.message === "Link is valid") {
          const params = new URLSearchParams(window.location.search);
          const data = params.get("data");

          if (data) {
            localStorage.setItem("pendingData", data);
            navigate("/");
          }
        } else {
          localStorage.setItem("invalidLinkInfo", "Link is not valid");
          navigate("/?link=notValid");
        }
      } catch (error) {
        ReactToastify("warn", "Error in redirect manager");
      }
    }

    checkLink();
  }, []);

  // Display the Loader component while the redirect logic is processed
  return (
    <>
      <Loader />
    </>
  );
};

export default RedirectManager;
