import React, { useState, useEffect, useRef } from "react";
import { useChatCustomContext } from "../../context/ChatProvider";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { Chat } from "stream-chat-react";
import ChatDropDownImages from "./styles/Images";
import axios from "axios";

import ShowResults from "./ShowResults";
import MessageInput from "./MessageInput";
import MessageList from "./MessageList";
import ChannelList from "./ChannelList";
import "./ChatMainFile.css";
import Loader from "../Loader";

// Props with <Home/>
const ChatMainFile = ({ handleChatDroppDown, isChatPage }) => {
  const token = JSON.parse(localStorage.getItem("userToken"));

  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const chatContainerRef = useRef();
  // Status for the selected channel
  const [selectedChannel, setSelectedChannel] = useState(null);
  // State for displaying search results
  const [showResults, setShowResults] = useState(false);
  // State for storing messages in the selected channel
  const [channelMessages, setChannelMessages] = useState([]);

  const [selectedEmojis, setSelectedEmojis] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  //////////////////////////////////////////////////////////////////////////////////////////
  //======================================================================================>>  START
  // User preferences
  const { client, unreadMessages } = useChatCustomContext();

  // Loading user data from the Redux Store
  const { email, isAdmin } = useSelector((state) => state.user.data || {});

  // GET USER ID
  const myId = email?.replace(/\./g, "_");
  //======================================================================================>>  END
  //////////////////////////////////////////////////////////////////////////////////////////
  //======================================================================================>> START
  // Connection settings

  const filter = { type: "messaging", members: { $in: [myId] } };
  const sort = [{ last_message_at: -1 }];
  //======================================================================================>>  END
  //////////////////////////////////////////////////////////////////////////////////////////
  //======================================================================================>> START
  // Function for channel selection

  const handleSelectChannel = (channel) => {
    setSelectedChannel(channel);
  };
  //======================================================================================>>  END
  //////////////////////////////////////////////////////////////////////////////////////////
  //======================================================================================>> START
  // Get the number of channels after page rendering

  const [channelCount, setChannelCount] = useState(0);

  useEffect(() => {
    const getChannelCount = async () => {
      try {
        const channels = await client.queryChannels(filter, sort);
        setChannelCount(channels.length);
      } catch (error) {
        console.error("Error fetching channel count:", error);
      }
    };

    if (client) {
      getChannelCount();
    }
  }, [client, filter, sort]);
  //======================================================================================>>  END
  //////////////////////////////////////////////////////////////////////////////////////////
  //======================================================================================>> START
  // Scroll down to the last message

  const scrollToLatestMessage = () => {
    const chatContainer = chatContainerRef.current;
    const scrollHeight = chatContainer.scrollHeight;
    const height = chatContainer.clientHeight;
    const maxScrollTop = scrollHeight - height;
    chatContainer.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };
  //======================================================================================>>  END
  //////////////////////////////////////////////////////////////////////////////////////////
  //======================================================================================>> START
  // Loading messages when selecting a channel

  const loadChannelMessages = async () => {
    if (selectedChannel) {
      try {
        scrollToLatestMessage();
        const selectChannel = await selectedChannel.query();
        setChannelMessages(selectChannel);
        scrollToLatestMessage();
      } catch (error) {
        console.error("Error loading channel messages:", error);
      }
    }
  };

  useEffect(() => {
    // When selecting a channel, download the message
    if (selectedChannel) {
      loadChannelMessages();
    }
  }, [selectedChannel]);
  //======================================================================================>>  END
  //////////////////////////////////////////////////////////////////////////////////////////
  //======================================================================================>> START
  // Function that sends a message to the chat

  const [selectedPhotos, setSelectedPhotos] = useState();

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsLoadingSpinner(true);
      const formData = new FormData();
      formData.append("imageFile", file);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/files/chatSendMessage`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSelectedPhotos(
        `https://test-one-metric.s3.eu-central-1.amazonaws.com/image/` +
          response.data.imageName
      );
      setIsLoadingSpinner(false);
    }
  };

  const sendMessage = async () => {
    const input = document.querySelector("#messageInput");
    if (input.value.trim() !== "" || selectedPhotos) {
      try {
        const text = input.value;
        const photos = selectedPhotos;

        const messageData = {
          text: input.value.trim(),
          attachments: [],
        };

        // Attach a photo to the message, if available
        if (photos) {
          messageData.attachments.push({
            type: "image",
            image_url: photos,
          });
        }

        await selectedChannel.sendMessage(messageData);

        setSelectedEmojis(null);

        await loadChannelMessages();

        scrollToLatestMessage();

        setInputValue("");
        setSelectedEmojis([]);
        setShowEmojiPicker(false);

        setSelectedPhotos(null);
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  //======================================================================================>>  END
  //////////////////////////////////////////////////////////////////////////////////////////
  //======================================================================================>> START
  // Function that scrolls down the notification list when we receive a new message

  const observeNewMessages = () => {
    const chatContainer = chatContainerRef.current;
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          // If you added a new item (message), scroll to the bottom
          if (mutation.addedNodes.length > 0) {
            scrollToLatestMessage(); // Instead of scrollToBottom
          }
        }
      }
    });
    observer.observe(chatContainer, { childList: true });
  };
  //======================================================================================>>  END
  //////////////////////////////////////////////////////////////////////////////////////////
  //======================================================================================>> START
  useEffect(() => {
    if (selectedChannel) {
      observeNewMessages();
      selectedChannel.markRead();
    }
  }, [selectedChannel]);
  //////////////////////////////////////////////////////////////////////////////////////////
  //======================================================================================>> START
  // Event handler for an inlet that monitors the "Enter" key

  const handleInputKeyPress = async (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };
  //======================================================================================>>  END
  //////////////////////////////////////////////////////////////////////////////////////////
  //======================================================================================>> START
  // Function to filter available channels by search text

  const filterChannels = () => {
    return Object.values(client?.activeChannels).filter((channel) =>
      channel.state.messages.some((message) =>
        message.text.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  // Event handler for changing the search text
  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);

    // Show search results if the text is not empty
    setShowResults(text.trim() !== "");
  };
  //======================================================================================>>  END
  //////////////////////////////////////////////////////////////////////////////////////////
  //======================================================================================>>  START
  // Optimized function to limit text length and add "..."
  // Function to limit the length of the text and display either text or an image
  // Function helps to determine the date

  function renderTextOrImage(message) {
    const maxLength = 35;

    if (message.text && message.text.length > 0) {
      return (
        <div
          style={{
            display: "flex",
            width: 260,
            justifyContent: "space-between",
          }}
        >
          <div>
            {message.text.length <= maxLength
              ? message.text
              : `${message.text.substring(0, maxLength)}...`}
          </div>
          <div
            style={{
              position: "sticky",
              marginBottom: 20,
              fontSize: 8,
              paddingRight: 10,
            }}
          >
            {formatDateTime(message.created_at?.toLocaleString())}
          </div>
        </div>
      );
    } else if (message.attachments && message.attachments.length > 0) {
      return (
        <div
          style={{
            display: "flex",
            width: 260,
            justifyContent: "space-between",
          }}
        >
          <img
            style={{
              width: 25,
              height: 25,
              position: "relative",
              bottom: 7,
            }}
            src={message.attachments[0].image_url}
            alt="attachment"
          />
          <div
            style={{
              position: "sticky",
              marginBottom: 20,
              fontSize: 8,
              paddingRight: 10,
            }}
          >
            {formatDateTime(message.created_at?.toLocaleString())}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  // Temporal function that works with date (time)
  function formatDateTime(dateTimeString) {
    const createdDate = new Date(dateTimeString);
    const today = new Date();

    if (
      createdDate.getDate() === today.getDate() &&
      createdDate.getMonth() === today.getMonth() &&
      createdDate.getFullYear() === today.getFullYear()
    ) {
      // Today's date coincides with the date of creation
      // Show hours and minutes
      const hours = createdDate.getHours();
      const minutes = createdDate.getMinutes();
      const amOrPm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // Formatting hours into a 12-hour format
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const timeString = `${formattedHours}:${formattedMinutes} ${amOrPm}`;

      return timeString;
    } else {
      // Today's date does not match the date of creation
      // Show the date without time
      return createdDate.toLocaleDateString();
    }
  }

  // Function for working with the date. Temporarily disabled due to an unknown error
  // function formatTimeOrDateAgo(created_at) {
  //   const dateTime = DateTime.fromFormat(created_at, "M/d/yyyy, h:mm:ss a");
  //   const now = DateTime.now();

  //   if (dateTime.hasSame(now, "day")) {
  //     // Today, show the time in the appropriate user format
  //     const formattedTime = dateTime.toLocaleString(DateTime.TIME_SIMPLE);
  //     return formattedTime;
  //   } else {
  //     // Otherwise, display the date without the year
  //     const formattedDate = dateTime.toFormat("d LLLL");
  //     return formattedDate;
  //   }
  // }
  //======================================================================================>>  END
  //////////////////////////////////////////////////////////////////////////////////////////
  //======================================================================================>>  START
  // Determining if this is a mobile version of the chat
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMobileView, setIsMobileView] = useState(true);

  useEffect(() => {
    // The function to be called when resizing the window
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add a resize event handler when mounting a component
    window.addEventListener("resize", handleResize);

    // Delete the event handler when dismantling a component (note: this is important to avoid memory leaks)
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // MCP - Main Chat Page
  return (
    <div
      className={`${
        isChatPage
          ? "chat-drop-down-container__MCP"
          : "chat-drop-down-container"
      }`}
    >
      {isLoadingSpinner && <Loader />}
      {/* Disable the button because it is rendered from the <Chat/> component */}
      {!isChatPage && (
        <div className="close-button-container">
          <button
            onClick={() => handleChatDroppDown()}
            className="close-button"
          >
            <img
              className="close-icon"
              src={ChatDropDownImages.close116}
              alt="closesmall"
            />
          </button>
        </div>
      )}
      {/* MAIN BODY */}
      <div
        className={`${
          isChatPage ? "content-container__MCP" : "content-container"
        }`}
      >
        {/* Checking if the "client" has been downloaded. To avoid an error */}
        {client && (
          <Chat client={client}>
            {/* Check if this component is rendered in <Chat/> */}
            {isChatPage ? (
              // Check if it is a mobile device
              screenWidth > 800 ? (
                <div style={{ display: "flex" }}>
                  <ChannelList
                    handleSearchInputChange={handleSearchInputChange}
                    unreadMessages={unreadMessages}
                    showResults={showResults}
                    handleSelectChannel={handleSelectChannel}
                    filterChannels={filterChannels}
                    setShowResults={setShowResults}
                    setSearchText={setSearchText}
                    client={client}
                    isChatPage={isChatPage}
                    searchText={searchText}
                    renderTextOrImage={renderTextOrImage}
                  />
                  <div
                    style={{
                      width: "100%",
                      borderLeft: "1px solid black",
                      marginLeft: 10,
                    }}
                  >
                    {selectedChannel && (
                      <div style={{ width: "100%" }}>
                        {/* Top container */}
                        <div
                          className="channel-header-container"
                          style={{ height: 50 }}
                        >
                          <button
                            className="back-button"
                            onClick={() => {
                              handleSelectChannel(null);
                              setSearchText("");
                              setShowResults(false);
                            }}
                          >
                            <div className="back-button-content">
                              <div className="back-button-icon">
                                <img
                                  style={{ width: 12, height: 12 }}
                                  src={ChatDropDownImages.greyBackArrow}
                                  alt="greyBackArrow"
                                />
                                <div className="back-button-text">Back</div>
                              </div>
                              <div className="channel-name-text">
                                {selectedChannel.data.name}
                              </div>
                            </div>
                          </button>
                        </div>

                        {/* List of messages and button to send a message */}
                        <div
                          className={`${
                            isChatPage ? "message-list__MCP" : "message-list"
                          }`}
                        >
                          <MessageList
                            isMobileView={isMobileView}
                            chatContainerRef={chatContainerRef}
                            selectedChannel={selectedChannel}
                            isChatPage={isChatPage}
                            myId={myId}
                          />
                          <MessageInput
                            isChatPage={isChatPage}
                            isMobileView={isMobileView}
                            handleInputKeyPress={handleInputKeyPress}
                            setShowEmojiPicker={setShowEmojiPicker}
                            setSelectedEmojis={setSelectedEmojis}
                            showEmojiPicker={showEmojiPicker}
                            selectedEmojis={selectedEmojis}
                            setInputValue={setInputValue}
                            inputValue={inputValue}
                            selectedPhotos={selectedPhotos}
                            setSelectedPhotos={setSelectedPhotos}
                            handleFileSelect={handleFileSelect}
                            screenWidth={screenWidth}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  {/* <<============ Switching to a channel when selecting from a list ============>> */}
                  {selectedChannel === null ? (
                    <ChannelList
                      isMobileView={isMobileView}
                      unreadMessages={unreadMessages}
                      showResults={showResults}
                      handleSelectChannel={handleSelectChannel}
                      filterChannels={filterChannels}
                      setShowResults={setShowResults}
                      setSearchText={setSearchText}
                      client={client}
                      isChatPage={isChatPage}
                      searchText={searchText}
                      handleSearchInputChange={handleSearchInputChange}
                      renderTextOrImage={renderTextOrImage}
                    />
                  ) : null}
                  {/* <<============ Display the selected channel ============>> */}
                  {selectedChannel && (
                    <>
                      {/* Top container */}
                      <div className="channel-header-container">
                        <button
                          className="back-button"
                          onClick={() => {
                            handleSelectChannel(null);
                            setSearchText("");
                            setShowResults(false);
                          }}
                        >
                          <div className="back-button-content">
                            <div className="back-button-icon">
                              <img
                                style={{ width: 12, height: 12 }}
                                src={ChatDropDownImages.greyBackArrow}
                                alt="greyBackArrow"
                              />
                              <div className="back-button-text">Back</div>
                            </div>
                            <div className="channel-name-text">
                              {selectedChannel.data.name}
                            </div>
                          </div>
                        </button>
                      </div>

                      {/* Search bar by message or username*/}
                      {selectedChannel && (
                        <div
                          className="search-container"
                          // style={{ marginTop: 10 }}
                        >
                          <div
                            className={`${
                              isChatPage
                                ? "inner-container__MCP"
                                : "inner-container"
                            }`}
                          >
                            <img
                              className="search-image"
                              src={ChatDropDownImages.greyLoop}
                              alt="greyBackArrow"
                            />
                            <input
                              className="search-input"
                              placeholder="Search messages"
                              id="searchInput"
                              value={searchText}
                              onChange={handleSearchInputChange}
                            />
                          </div>
                        </div>
                      )}

                      {/* Search results in the form of a drop-down menu */}
                      {showResults && (
                        <ShowResults
                          newHeight={true}
                          isMobileView={isMobileView}
                          handleSelectChannel={handleSelectChannel}
                          filterChannels={filterChannels}
                          setShowResults={setShowResults}
                          setSearchText={setSearchText}
                          showResults={showResults}
                        />
                      )}

                      {/* List of messages and button to send a message */}
                      <div
                        className={`${
                          isChatPage
                            ? "message-list__MCP-mobile"
                            : "message-list"
                        }`}
                      >
                        <MessageList
                          isMobileView={isMobileView}
                          chatContainerRef={chatContainerRef}
                          selectedChannel={selectedChannel}
                          isChatPage={isChatPage}
                          myId={myId}
                        />
                        <MessageInput
                          isChatPage={isChatPage}
                          isMobileView={isMobileView}
                          screenWidth={screenWidth}
                          handleInputKeyPress={handleInputKeyPress}
                          setShowEmojiPicker={setShowEmojiPicker}
                          setSelectedEmojis={setSelectedEmojis}
                          showEmojiPicker={showEmojiPicker}
                          selectedEmojis={selectedEmojis}
                          setInputValue={setInputValue}
                          inputValue={inputValue}
                          selectedPhotos={selectedPhotos}
                          setSelectedPhotos={setSelectedPhotos}
                          handleFileSelect={handleFileSelect}
                        />
                      </div>
                    </>
                  )}
                </>
              )
            ) : (
              <>
                {/* <<============ Switching to a channel when selecting from a list ============>> */}
                {selectedChannel === null ? (
                  <ChannelList
                    unreadMessages={unreadMessages}
                    showResults={showResults}
                    handleSelectChannel={handleSelectChannel}
                    filterChannels={filterChannels}
                    setShowResults={setShowResults}
                    setSearchText={setSearchText}
                    client={client}
                    isChatPage={isChatPage}
                    searchText={searchText}
                    handleSearchInputChange={handleSearchInputChange}
                    renderTextOrImage={renderTextOrImage}
                  />
                ) : null}
                {/* <<============ Display the selected channel ============>> */}
                {selectedChannel && (
                  <>
                    {/* Top container */}
                    <div className="channel-header-container">
                      <button
                        className="back-button"
                        onClick={() => {
                          handleSelectChannel(null);
                          setSearchText("");
                          setShowResults(false);
                        }}
                      >
                        <div className="back-button-content">
                          <div className="back-button-icon">
                            <img
                              style={{ width: 12, height: 12 }}
                              src={ChatDropDownImages.greyBackArrow}
                              alt="greyBackArrow"
                            />
                            <div className="back-button-text">Back</div>
                          </div>
                          <div className="channel-name-text">
                            {selectedChannel.data.name}
                          </div>
                        </div>
                      </button>
                    </div>

                    {/* Search bar by message or username*/}
                    {selectedChannel && (
                      <div
                        className="search-container"
                        // style={{ marginTop: 10 }}
                      >
                        <div
                          className={`${
                            isChatPage
                              ? "inner-container__MCP"
                              : "inner-container"
                          }`}
                        >
                          <img
                            className="search-image"
                            src={ChatDropDownImages.greyLoop}
                            alt="greyBackArrow"
                          />
                          <input
                            className="search-input"
                            placeholder="Search messages"
                            id="searchInput"
                            value={searchText}
                            onChange={handleSearchInputChange}
                          />
                        </div>
                      </div>
                    )}

                    {/* Search results in the form of a drop-down menu */}
                    {showResults && (
                      <ShowResults
                        newHeight={true}
                        handleSelectChannel={handleSelectChannel}
                        filterChannels={filterChannels}
                        setShowResults={setShowResults}
                        setSearchText={setSearchText}
                      />
                    )}

                    {/* List of messages and button to send a message */}
                    <div
                      className={`${
                        isChatPage ? "message-list__MCP" : "message-list"
                      }`}
                    >
                      <MessageList
                        isMobileView={isMobileView}
                        chatContainerRef={chatContainerRef}
                        selectedChannel={selectedChannel}
                        isChatPage={isChatPage}
                        myId={myId}
                      />
                      <MessageInput
                        isChatPage={isChatPage}
                        screenWidth={screenWidth}
                        handleInputKeyPress={handleInputKeyPress}
                        setShowEmojiPicker={setShowEmojiPicker}
                        setSelectedEmojis={setSelectedEmojis}
                        showEmojiPicker={showEmojiPicker}
                        selectedEmojis={selectedEmojis}
                        setInputValue={setInputValue}
                        inputValue={inputValue}
                        selectedPhotos={selectedPhotos}
                        setSelectedPhotos={setSelectedPhotos}
                        handleFileSelect={handleFileSelect}
                        isMobileView={isMobileView}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </Chat>
        )}
      </div>
    </div>
  );
};

export default ChatMainFile;
