import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { fetchUser } from "../store/thunk/fetchUser";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { setUser } from "../store/slices/userSlice";
import { gapi } from "gapi-script";
import { Link, useLocation } from "react-router-dom";
import projectImages from "../style/projectImages";
import Navbar from "../components/Navbar";
import axios from "axios";
import "../App.css";
import Loader from "../components/Loader";
import ReactToastify from "../hooks/useReactToastify";

const redirectUri = `${process.env.REACT_APP_REDIRECT_URL}`;

// To solve the problem : "Google SSO login error: 'popup_closed_by_user'"
gapi.load("client:auth2", () => {
  gapi.client.init({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    scope: "profile email",
    plugin_name: "chat",
  });
});

const LoginScreen = () => {
  const dispatch = useDispatch();
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionExpired = searchParams.get("session") === "expired";
  const linkIsNotValid = searchParams.get("link") === "notValid";

  // Authorization by Google
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => getUserProfile(tokenResponse.access_token),
  });

  const getUserProfile = async (accessToken) => {
    try {
      setIsLoadingSpinner(true);
      const profileResponse = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const { email, name, picture } = profileResponse.data;
      const userFetchResult = await dispatch(
        fetchUser({ email, name, picture })
      ); // Dispatch the fetchUsers thunk
      if (userFetchResult?.payload?.chatToken) {
        window.location.reload(); // Reload the window
      } else {
        ReactToastify("warn", "Error during authorization");
      }
    } catch (error) {
      setIsLoadingSpinner(false);
      console.error("Error retrieving user profile data", error);
    }
  };

  // Authorization by LinkedIn
  const handleSuccessByLinkedin = async (response) => {
    try {
      setIsLoadingSpinner(true);
      // Sending the LinkedIn access token to your server
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/auth/linkedin`,
        {
          access_token: response,
        }
      );
      // Save user data to local storage
      localStorage.setItem("userData", JSON.stringify(res.data.user));
      localStorage.setItem("userToken", JSON.stringify(res.data.token));

      await dispatch(setUser(res.data.user));
      window.location.reload(); // Reload the window
    } catch (error) {
      setIsLoadingSpinner(false);
      console.error("Error logging in with LinkedIn:", error);
      // TO DO
      // Add error handling when logging in with LinkedIn
    }
  };

  const handleFailureByLinkedin = (error) => {
    console.error("LinkedIn login failed:", error);
    // TO DO
    // Add error handling for LinkedIn login failure
  };

  return (
    <div
      className="bg-cover min-h-screen w-full main__bg"
      style={{
        background: `url(${projectImages.loginScreenBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {isLoadingSpinner && <Loader />}
      <Navbar />
      <div
        style={{
          zoom: "89.5%",
          // background: `url(${projectImages.loginScreenBg})`,
          height: "calc(111vh - 72px)",
        }}
        className="login__wrapper"
      >
        <div className="sign__box">
          <div className="sign__head">
            <p>Sign On</p>
          </div>
          <div className="sign__main">
            <span>
              <img src={projectImages.profileIcon} alt="profileicon" />
            </span>
            <div className="sign__buttons">
              <LinkedIn
                clientId="77y9rfaosiaz01"
                onFailure={handleFailureByLinkedin}
                onSuccess={handleSuccessByLinkedin}
                redirectUri={redirectUri}
                scope="r_liteprofile r_emailaddress"
              >
                {({ linkedInLogin }) => (
                  <button
                    className="bg-black text-white border-none px-10 py-2 cursor-pointer h-12 font-semibold text-lg flex items-center mb-4 rounded-lg justify-center"
                    onClick={linkedInLogin}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <div
                        className="login__screen-button"
                        style={{ color: "white" }}
                      >
                        Sign On with LinkedIn
                      </div>
                      <img
                        src={projectImages.arrow}
                        className="e43g2ew"
                        alt="Sign On with LinkedIn"
                        style={{
                          position: "absolute",
                        }}
                      />
                    </div>
                  </button>
                )}
              </LinkedIn>

              <button
                className="bg-black text-white border-none px-10 py-2 cursor-pointer h-12 font-semibold text-lg flex items-center mb-4 rounded-lg justify-center"
                onClick={() => login()}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <div
                    className="login__screen-button"
                    style={{ color: "white" }}
                  >
                    Sign On with Google
                  </div>
                  <img
                    src={projectImages.arrow}
                    alt="Sign On with LinkedIn"
                    className="e43g2ew2"
                    style={{
                      position: "absolute",
                    }}
                  />
                </div>
              </button>
            </div>
            {sessionExpired && (
              <div className="mb-4 p-4 max-w-md text-center bg-red-100 text-red-700 border border-red-200 rounded">
                Your session has expired. Please log in again.
              </div>
            )}
            {linkIsNotValid && (
              <div className="mb-4 p-4 max-w-md text-center bg-red-100 text-red-700 border border-red-200 rounded">
                Warning: This link is no longer valid.
              </div>
            )}
            <ul>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="spacer">•</li>
              <li>
                <Link to="/terms">Terms of Service</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
