import React from "react";
import ChatDropDownImages from "./styles/Images";
import ShowResults from "./ShowResults";

// Props with ChatMainFile
const ChannelList = ({
  unreadMessages,
  showResults,
  handleSelectChannel,
  filterChannels,
  setShowResults,
  setSearchText,
  client,
  isChatPage,
  searchText,
  handleSearchInputChange,
  renderTextOrImage,
  isMobileView,
}) => {
  return (
    <div>
      <div
        style={{
          fontWeight: 700,
          paddingLeft: 10,
          paddingTop: 10,
        }}
      >
        Conversations
      </div>
      <div style={{ paddingLeft: 11, fontSize: 12 }}>
        {unreadMessages} unread messages
      </div>
      <div
        style={{
          width: isChatPage ? "98%" : "94%",
          display: "flex",
          height: 25,
          alignItems: "center",
          backgroundColor: "#5b539d1f",
          borderRadius: 9,
          margin: 10,
        }}
      >
        <img
          style={{
            width: 12,
            height: 12,
            marginRight: 16,
            marginLeft: 15,
          }}
          src={ChatDropDownImages.greyLoop}
          alt="greyBackArrow"
        />
        <input
          placeholder="Search messages"
          style={{
            width: isChatPage ? "98%" : 200,
            fontSize: 13,
            marginRight: 10,
            height: 27,
            backgroundColor: "#0000ff00",
          }}
          id="searchInput"
          value={searchText}
          onChange={handleSearchInputChange}
        />
      </div>
      {showResults && (
        <ShowResults
          handleSelectChannel={handleSelectChannel}
          filterChannels={filterChannels}
          setShowResults={setShowResults}
          setSearchText={setSearchText}
          isChatPage={isChatPage}
          isMobileView={isMobileView}
        />
      )}
      <ul
        style={{
          height: isChatPage ? (isMobileView ? "71.5vh" : "77.5vh") : "391px",
          overflowY: "auto",
          padding: isMobileView ? 10 : null,
        }}
      >
        {client?.activeChannels ? (
          <>
            {Object.values(client?.activeChannels).map((channel) => (
              <li key={channel.id} onClick={() => handleSelectChannel(channel)}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    height: 60,
                    cursor: "pointer",
                    borderTop: "1px solid #95959570",
                    backgroundColor:
                      channel.state.unreadCount > 0
                        ? "rgb(128 128 128 / 17%)"
                        : "white",
                  }}
                >
                  <div
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: 50,
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 50,
                      }}
                      src={channel.data.image}
                      alt={channel.data.name}
                    />
                  </div>
                  <div style={{ fontSize: 12, marginTop: 5 }}>
                    <div style={{ height: 15 }}>
                      {channel.data.name}
                      {channel.state.unreadCount > 0 && (
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 50,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "white",
                            position: "relative",
                            left: -22,
                            fontWeight: 600,
                            bottom: 18,
                            boxShadow: "rgb(66, 68, 90) 1px 2px 9px -1px",
                          }}
                        >
                          {channel.state.unreadCount}
                        </div>
                      )}
                    </div>
                    <div style={{ fontSize: 10, marginTop: 11 }}>
                      {renderTextOrImage(
                        channel.state.messageSets[0].messages[
                          channel.state.messageSets[0].messages.length - 1
                        ]
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </>
        ) : null}
      </ul>
    </div>
  );
};

export default ChannelList;
