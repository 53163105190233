/**
 * Hook: useSelectedValues
 *
 * Helps to manage selected values for filtering the "STARTUPS" list.
 *
 * Parameters:
 *   - initialValues: an array of initial selected values
 *
 * Returns:
 *   - selectedValues: the current array of selected values
 *   - handleValueToggle: a function to toggle the selection of a value
 *
 * Behavior:
 *   - Initializes the state with the provided initial values.
 *   - Provides a function to toggle the selection of a value in the state array.
 *     - If the value is already selected, it is removed from the array.
 *     - If the value is not selected, it is added to the array.
 */

import { useState } from "react";

export const useSelectedValues = (initialValues) => {
  const [selectedValues, setSelectedValues] = useState(initialValues);

  const handleValueToggle = (value) => {
    setSelectedValues((prevValues) => {
      if (prevValues.includes(value)) {
        // Delete a tag if it has already been selected
        return prevValues.filter((prevValue) => prevValue !== value);
      } else {
        // Add a tag if it has not already been selected
        return [...prevValues, value];
      }
    });
  };

  return [selectedValues, handleValueToggle];
};
